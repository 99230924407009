import React from "react";

export const VacancyDescriprion = () => {
  return (
    <div className="vacancy-description">
      <p>
        Dobbi is een snelgroeiende startup binnen de was- en stomerijmarkt. Op
        dit moment zijn wij actief in de Randstad maar binnen een jaar is onze
        service beschikbaar in heel Nederland, en dat moet iedereen weten!
        Daarom zoeken wij iemand die samen met ons dobbi beroemd gaat maken. Wij
        willen overal zichtbaar zijn en daar ga jij een cruciale rol in spelen.
      </p>
      <p>
        <b style={{ display: "block" }}>Wat ga je doen?</b>
        Non stop creatieve ideeën spuien. Gifjes, video’s, blogs, foto’s,
        poster, artikelen of advertenties; niks is te gek voor jouw creatieve
        geest. Je bent assertief, pro-actief, creatief en je werkt zelfstandig
        net zo goed als in een team. Je monitort trends en daar speel je met
        goede content op in.
      </p>
      <p>
        Jij hebt visueel en tekstueel antwoord op al onze marketing ideeën. Je
        kickt op hoge conversie cijfers en je weet dat ze altijd hoger kunnen.
        Je gaat samen met ons, en samen met de beste marketingbureaus van
        Nederland verder bouwen aan het succes van dobbi! Gek zijn op
        vrijdagmiddagborrels en teamuitjes is trouwens wel een must.
      </p>
      <p />
      <p>
        <b style={{ display: "block" }}>Over dobbi:</b> Dobbi levert schone
        kleding aan huis, wanneer jij het wil en binnenkort in heel Nederland.
        Met PostNL en Persil als stuwende kracht is dobbi het alternatief voor
        het bezoek aan de stomerij, de wasmachine en de strijkplank! Onze
        service is nu beschikbaar in de Randstad maar binnen een jaar in heel
        Nederland. Ons servicegebied breidt zich dus letterlijk dagelijks uit!
      </p>
      <p>
        <b style={{ display: "block" }}> Wat bieden wij?</b>
        Een baan waarin je echt het verschil kan maken, waarin je veel ruimte
        krijgt en je werk overal terugziet, bij een snelgroeiend bedrijf met een
        stel super gemotiveerde collega’s. En ons kantoor staat op de gaafste
        locatie van Nederland; het MediaPark in Hilversum (25 min van Amsterdam,
        Utrecht & station naast de deur). Een laptop krijg je van de zaak. Een
        borrel (of een sapje) op vrijdagmiddag ook. En ja, alsof dat nog niet
        genoeg is; je krijgt ook nog eens een marktconform salaris.
      </p>
      <p>
        <b style={{ display: "block" }}>Jij hebt:</b>
        <ul>
          <li>Een afgeronde relevante (HBO) opleiding.</li>
          <li>
            Ruime ervaring in o.a. Adobe Illustrator, Photoshop, Indesign etc.
            en een portfolio die dit laat zien
          </li>
          <li>Een vlotte pen in zowel Nederlands als Engels.</li>
          <li>Een voorliefde voor Social Media</li>
          <li>Ervaring binnen een online/B2C georiënteerd bedrijf (pré)</li>
          <li>
            De volgende eigenschappen: creatief, innovatief, teamplayer en de
            WOW factor
          </li>
        </ul>
      </p>
      <p>
        <b style={{ display: "block" }}>Functie-eisen</b>
      </p>
      <p>
        <b>Wat wij bieden:</b>
        <ul>
          <li>
            De beste Content Marketeer van Nederland die dobbi wereld beroemd
            gaat maken
          </li>
          <li>Passie, creativiteit, ambitie en humor</li>
          <li>Beheersing van Nederlands en Engels in woord en geschrift</li>
          <li>
            Zeer vaardig in het gebruik Adobe Illustrator, Indesign, Photoshop,
            etc
          </li>
          <li>Op korte termijn beschikbaar</li>
        </ul>
      </p>
      <p>
        <b style={{ display: "block" }}>
          Hoe weten wij dat we jou moeten hebben?
        </b>
        <ul>
          <li>
            Schrijf een unieke sollicitatiemail, deel je portfolio of geef deze
            vacature opnieuw vorm, makkie toch? Mail hem voor 1 augustus naar{" "}
            <a href="mailto:maurits@dobbi.com">maurits@dobbi.com</a>
          </li>
        </ul>
      </p>
      <p>
        <b style={{ display: "block" }}>Bedrijfsprofiel</b>
      </p>
      <p>
        <b style={{ display: "block" }}>Over dobbi: </b>
        Dobbi levert schone kleding aan huis, wanneer jij het wil en binnenkort
        in heel Nederland. Met PostNL en Persil als stuwende kracht is dobbi het
        alternatief voor het bezoek aan de stomerij, de wasmachine en de
        strijkplank! Onze service is nu beschikbaar in de Randstad maar binnen
        een jaar in heel Nederland. Ons servicegebied breidt zich dus letterlijk
        dagelijks uit!
      </p>
    </div>
  );
};
