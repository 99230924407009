import React from 'react';
import { connect } from 'react-redux';
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import {urlQuery, userDestroySession, userIs} from '../../../utils/helpers'
import {
  setOrderScreen,
  setOrderStep,
  setPlaceOrderData,
  setNotificationData, resetStorage,
} from '../../../redux/actions';
import classNames from 'classnames';

import ServiceBullet from '../../../images/order/order-service-bullets.svg';
import ServiceBulletActive from '../../../images/order/order-service-bullets-active.svg';
import { ButtonWithArrow, OrderBackButton } from './ButtonWithArrow';
import moment from 'moment-timezone'

import translation from '../../../translations/AsideOrderScreen'
import Api from "../../../utils/api/Api";

const AsideOrderScreen = props => {
  const lang = localStorage.getItem("lang");
  const {
    setOrderScreen,
    setNotificationData,
    orderData,
    currentOrderStep,
    setOrderStep,
    selectedPickupDate,
    selectedPickupTime,
    selectedDeliveryDate,
    selectedDeliveryTime,
    placeOrderData,
    userLocation,
    userData,
    checkedLastScreen,
    hasBag
  } = props;
  const { DC, WF, SS, LS, IO } = orderData;

  async function placedOrder(data) {
    data.user.hasBag  = null === hasBag ? false : hasBag;
    
    try {
      await Api.placeOrder(data);

      props.history.push(urlQuery({ page: 'order-placed' }));
      props.resetStorage();

      await userDestroySession()

      setOrderScreen(4);
    } catch (e) {
      const message = e.response.data.title;
      const data = { status: true, message };
      setNotificationData(data);

      setTimeout(() => {
        const data = { status: false, message: '' };
        setNotificationData(data);
      }, 5000);
    }
  }

  const setPlaceOrder = async () => {

    const encodeDates = (date, time) => moment.tz(`${date}T${time}:00`, 'Europe/Amsterdam').format();

    let selectedServices = []
    let placeData = placeOrderData
    Object.keys(orderData).filter(key => { 
      return orderData[key] && selectedServices.push(key);
    })

    const userPayload = { ...userData }
    const {contactEmail, contactPhone, contactName} = userPayload

    const data = {
      user: userPayload,
      order: {
        ...placeData,
        pickupTime: encodeDates(selectedPickupDate.date, selectedPickupTime),
        pickupLocation: { ...userLocation },
        cleaningServices: selectedServices,
        data: {
          contact: {
            name: contactName,
            email: contactEmail,
            phone: contactPhone,
          }
        },
        b2bType: 're-cover',
      },
    };
    await placedOrder(data);
  };

  const getNextButtonState = () => {
    const { phone, name, fullName } = userData;

    switch (currentOrderStep) {
      case 1:
        return !!DC || !!WF || !!SS || !!LS || !!IO;
      case 2:
        return (
          !!!selectedPickupDate.default
        );
      case 3: {
        return (phone && name && checkedLastScreen) || fullName;
      }
      default:
        return false;
    }
  };

  const handleChangeStep = direction => {
    const { contactName, contactEmail, contactPhone } = userData;
    const backDirection = direction === 'back';

    switch (currentOrderStep) {
      case 1:
        return backDirection
          ? setOrderScreen(2)
          : (DC || WF || SS || LS || IO) && setOrderStep(2);
      case 2:
        return backDirection
          ? setOrderStep(1)
          : !!!selectedPickupDate.default && setOrderStep(3);
      case 3:
        return backDirection
          ? setOrderStep(2)
          : ((contactName && contactEmail && contactPhone && checkedLastScreen)) &&
          setPlaceOrder();
      default:
        break;
    }
  };

  const buttonTextSteps = {
    1: translation.next_time[lang],
    2: translation.next_overview[lang],
    3: translation.confirmOrder[lang],
  };
  const buttonText = buttonTextSteps[currentOrderStep];

  const WrapperClassName = classNames({
    'current-step-wrapper': true,
    'second-step': currentOrderStep === 2,
    'third-step': currentOrderStep === 3,
  });

  const ButtonClassName = classNames({
    'next-order-button': true,
    checked: getNextButtonState(),
  });

  return (
    <div className="first-screen-wrapper second-order-screen">
      <div className="left-order-block">{props.children}</div>
      <div className="right-order-block">
        <div className={WrapperClassName}>
          <CurrentServiceSelect
            active={currentOrderStep === 1}
            title="Service"
            descr={translation.selectServiceDescr[lang]}
          />
          <CurrentServiceSelect
            active={currentOrderStep === 2}
            title={translation.time[lang]}
            descr={translation.timeDescr[lang]}
          />
          <CurrentServiceSelect
            active={currentOrderStep === 3}
            title={translation.overview[lang]}
            descr={translation.overviewDesc[lang]}
          />
        </div>
        <div className="select-step-buttons">
          { 1 !== currentOrderStep &&
            <OrderBackButton
              id={'step-' + (currentOrderStep + 2) + '-back-to-step-' + (currentOrderStep + 1)}
              className="back-order-button"
              onClick={() => handleChangeStep('back')}
            />
          }

          <ButtonWithArrow
            id={3 === currentOrderStep ? 'step-5-purchase-button' : 'step-' + (currentOrderStep + 2) + '-next-to-step-' + (currentOrderStep + 3)}
            className={ButtonClassName}
            onClick={() => handleChangeStep()}
          >
            {buttonText}
          </ButtonWithArrow>
        </div>
      </div>
    </div>
  );
};

const CurrentServiceSelect = props => {
  const { active, title, descr } = props;
  return (
    <div className={`current-service-item ${active ? 'active' : ''}`}>
      <div className="bullet-block">
        <img src={active ? ServiceBulletActive : ServiceBullet} alt="" />
      </div>
      <div className="bullet-text-block">
        <span>{title}</span><br />
        <span>{descr}</span>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  currentOrderScreen,
  orderData,
  currentOrderStep,
  selectedPickupDate,
  selectedDeliveryDate,
  selectedPickupTime,
  selectedDeliveryTime,
  placeOrderData,
  userLocation,
  userData,
  apiToken,
  checkedLastScreen,
  hasBag
}) => ({
  currentOrderScreen,
  orderData,
  currentOrderStep,
  selectedPickupDate,
  selectedDeliveryDate,
  selectedPickupTime,
  selectedDeliveryTime,
  placeOrderData,
  userLocation,
  userData,
  token: apiToken,
  checkedLastScreen,
  hasBag
});
const mapDispatchToProps = dispatch => {
  return {
    setOrderScreen: data => dispatch(setOrderScreen(data)),
    setPlaceOrderData: data => dispatch(setPlaceOrderData(data)),
    setNotificationData: data => dispatch(setNotificationData(data)),
    setOrderStep: data => dispatch(setOrderStep(data)),
    resetStorage: () => dispatch(resetStorage())
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AsideOrderScreen);

