import React from 'react';

import info from '../../images/order/info-blue-icon.svg';
import qr from '../../modals/images/qr.png';

import translation from '../../translations/FourthOrderScreen'
import { AppLinks } from '../../components/AppLinks'

const FourthOrderScreen = props => {
  const lang = localStorage.getItem('lang');
  try { window.dataLayer.push({ event: 'order-complete' }) } catch (err) { console.log("Error", err) }
  return (
    <div className="fourth-screen-wrapper">
      <div className="congratulation-block">
        <h2>{translation.title[lang]}</h2>
        <p>{translation.yourOrderSent[lang]}</p>
        <a href={`https://dobbi.com/${lang}/faq`} className="fourth-screen-faq-button">
          <img src={info} alt="" />
          <span>{translation.faq[lang]}</span>
        </a>
      </div>
      <div className="download-app-block">
        <h2>{translation.downloadApp[lang]}</h2>
        <p>{translation.downloadAppDesc[lang]}</p>
        <div className="img-wrapper">
          <img src={qr} className="qr-order" alt="" />
          <AppLinks />
        </div>
      </div>
    </div>
  );
};

export default FourthOrderScreen;
