import React from 'react';
import { connect } from 'react-redux';
import {
  addOrderData,
  setPlaceOrderData,
  setHasBag,
  setFormattedAddress,
  setUserLocation,
  resetSelectedDelivery,
  resetSelectedPickup,
  resetAvailablePickupDate,
  resetAvailableDeliveryDate
} from '../../../redux/actions'
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { urlQuery } from '../../../utils/helpers'

import translation from '../../../translations/FirstStep'
import mailImg from '../../../images/mail_letter.jpg'

class FirstStep extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount = async () => {
    this.selectService('IO')

    this.props.history.push(urlQuery({ page: 'select-service' }));
    try { window.dataLayer.push({ event: 'service-select' }) } catch (err) { console.log("Error", err) }
  };

  selectService = (val) => {
    const { orderData, addOrderData } = this.props;
    const data = { ...orderData, ...{[`${val}`]: true} };

    return addOrderData(data);
  }

  render() {
    return (
      <div className="first-option-block">
        <HasBagBlock
          {...this.props}
        />
      </div>
    )
  };
}

const HasBagBlock = props => {
  const { setHasBag, hasBag, resetSelectedPickup, resetSelectedDelivery, resetAvailablePickupDate, resetAvailableDeliveryDate } = props;
  const lang = localStorage.getItem("lang")

  const choiceHasBag = (hasBag) => {
    console.log('choiceHasBag');
    setHasBag(hasBag)
    resetSelectedPickup()
    resetSelectedDelivery()
    resetAvailablePickupDate()
    resetAvailableDeliveryDate()
  }

  return (
    <div className="has-bag-block">
      <div className="has-bag-header">
        <p>{translation.hasBagTitleCompany[lang]}</p>
      </div>
      <div className="has-bag-info">
        <div className="has-bag-select-container">
          <div
            id="step-3-services-checkbox-has-bag"
            className="has-bag-select"
            onClick={() => choiceHasBag(true)}
          >
          <span className={`${hasBag && 'hidden'}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"/></svg>
          </span>

            <span className={`selected ${!hasBag && 'hidden'}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"/></svg>
          </span>
            <span>{ translation.hasBagYesCompany[lang] }</span>
          </div>

          <div className="has-bag-select-notice">
            <span>{ translation.hasBagNoNoteCompany[lang] }</span>
          </div>
        </div>

        <div className="has-bag-select-container">
          <div
            id="step-3-services-checkbox-has-bag"
            className="has-bag-select"
            onClick={() => choiceHasBag(false)}
          >
          <span className={`${!hasBag && 'hidden'}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"/></svg>
          </span>

            <span className={`selected ${hasBag && 'hidden'}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"/></svg>
          </span>
            <span>{ translation.hasBagNoCompany[lang] }</span>
          </div>

          <ul className="has-bag-select-notice">
            <li>{ translation.hasBagFirstPoint[lang] }</li>
            <li>{ translation.hasBagSecondPoint[lang] }</li>
          </ul>
        </div>

        <div className="has-bag-image">
          <img src={ mailImg } alt="mail_letter" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  currentOrderScreen,
  orderData,
  hasBag,
  currentOrderStep,
  userData,
  formattedAddress,
  userLocation,
  webView
}) => ({
  currentOrderScreen,
  currentOrderStep,
  orderData,
  hasBag,
  userData,
  formattedAddress,
  userLocation,
  webView
});

const mapDispatchToProps = dispatch => {
  return {
    addOrderData: data => dispatch(addOrderData(data)),
    setPlaceOrderData: data => dispatch(setPlaceOrderData(data)),
    setHasBag: data => dispatch(setHasBag(data)),
    setFormattedAddress: data => dispatch(setFormattedAddress(data)),
    setUserLocation: data => dispatch(setUserLocation(data)),
    resetSelectedDelivery: data => dispatch(resetSelectedDelivery(data)),
    resetSelectedPickup: data => dispatch(resetSelectedPickup(data)),
    resetAvailablePickupDate: data => dispatch(resetAvailablePickupDate(data)),
    resetAvailableDeliveryDate: data => dispatch(resetAvailableDeliveryDate(data)),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FirstStep);
