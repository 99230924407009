export const translation = {
  terms_00: {
    en:
      "We are looking for you! As of this moment we have the following positions:",
    nl: "Wil jij ons team versterken? Wij hebben de volgende vacatures:"
  },
  terms_01: {
    en: "Contact us:",
    nl: "Direct contact:"
  },
  terms_02: {
    en: "or",
    nl: "of"
  },
  tab_01: {
    en: "Marketing",
    nl: "Positions"
  },
  tab_02: {
    en: "Stagiaires",
    nl: "Interns"
  },
  firstTab_01: {
    nl: "De leukste CONTENT MARKETEER van Nederland (M/V)",
    en: "The best CONTENT MARKETEER of The Netherlands (M/V)"
  },
  firstTabText_01: {
    en:
      "1)	You place an order with our app (Android or iPhone)<br /> 2)	The next working day you will receive the starter kit with your personal laundrybag (First order only)<br /> 3)	Our PostNL driver picks up your order<br /> 4)	We process your order on the next working day. You will receive an overview of your items and pricing by email.<br /> 5)	Complete the payment of your order by using the payment link. (iDeal, credit card en PayPal).<br /> 6)	Our PostNL driver delivers your order",
    nl:
      "1)	Je plaatst een order via onze app<br /> 2) Je ontvangt de volgende werkdag het startpakket met je eigen waszak (Alleen bij je eerste order)<br /> 3)	Onze PostNL-chauffeur haalt de order op <br /> 4)	De volgende werkdag ontvang je een overzicht van jouw artikelen en prijzen.<br /> 5)	Je voltooid de betaling via een betaallink (iDeal, credit card en PayPal).<br /> 6)	Onze PostNL-chauffeur bezorgt jouw bestelling"
  },
  firstTab_02: {
    nl: "De leukste DESIGNER van Nederland (M/V) ",
    en: "The best DESIGNER of The Netherlands (M/V) "
  },
  firstTabText_02: {
    en:
      "Choose Dry Cleaning&Ironed for items such as suits, dresses, shirts or any other clothes that you would like to have dry cleaned and/or ironed. We will charge you per item. Choose Wash&Fold for clothing such as T-shirts, socks, undergarments and any other clothing that does not need to be ironed. We will wash the clothes and deliver all items folded. There is a 6 kg minimum for this service. For every extra kg you will be charged EUR 4.25.",
    nl:
      "Kies Stomen&Strijken voor bijvoorbeeld pakken, jurken, overhemden of andere kleding die je gestoomd en/of gestreken wil hebben. Wij rekenen een prijs per artikel. Deze kan je op onze prijslijst vinden.  Kies Wassen&Vouwen voor kleding zoals T-shirts, sokken, ondergoed en overige kleding die niet gestreken hoeft te worden. Wij wassen je kleding en leveren het gevouwen terug. Wij hanteren een minimum van 6 kg voor deze service. Voor elke extra kilo betaal je EUR 4.25 extra."
  },
  firstTab_03: {
    en: "The Best ONLINE MARKETEER of the Netherlands (M/V)",
    nl: "De leukste ONLINE MARKETEER van Nederland (M/V) "
  },
  firstTabText_03: {
    en:
      "We want to make it as easy as possible for you to place your order so you only have to select the type of service. We will sort all your items and send you a detailed overview of your items and cost. Please know: For the Wash&Fold service you will only receive the total amount of kilograms.",
    nl:
      "Wij willen het je zo makkelijk mogelijk maken. Daarom sorteert onze stomerij je kleding en ontvang je een duidelijk overzicht van artikelen en kosten. Let wel: Kies je voor Wassen&Vouwen dan ontvang je alleen een overzicht van het aantal kilogram."
  },
  firstTab_04: {
    en: "The Best GROWTH HACKER of the Netherlands (M/V)",
    nl: "De leukste GROWTH HACKER van Nederland (M/V)"
  },
  firstTabText_04: {
    en:
      "No, you don't. Before your first pick-up you will receive a personalised laundrybag for your clothes. You can place  all the items in this bag unsorted. What would help is to put the Wash&Fold items on the bottom and the Dry Cleaning&Ironed items on top.",
    nl:
      "Nee, dat hoeft niet. Je ontvangt van ons voor je eerste pick up een gepersonaliseerde waszak. Kleding kan je hier ongesorteerd in doen. Het is voor ons wel makkelijk als je de was onderin doet en eventueel kleding die gestoomd moet worden bovenop."
  },
  firstTab_05: {
    en: "My order doesn't fit in one laundrybag?",
    nl: "Niet alles past in 1 waszak?"
  },
  firstTabText_05: {
    en:
      "Our laundrybag fits about 12kg of clothes. Do you have more items that need to be cleand then please contact our customer service.",
    nl:
      "In onze waszak past ca. 12 kilo aan kleding. Heb je meer kleding die gereinigd moet worden, neem dan contact op met onze klantenservice."
  },
  firstTab_06: {
    en: "I lost my dobbi laundrybag",
    nl: "Ik ben mijn dobbi waszak kwijt?"
  },
  firstTabText_06: {
    en:
      "Our PostNL driver can only collect orders that are in a dobbi laundry bag. If you have lost the bag, please contact our customer service.",
    nl:
      "Orders die niet in een dobbi waszak zitten kunnen niet door de PostNL chauffeur worden meegenomen. Ben je je waszak kwijt, neem dan contact op met onze klantenservice."
  },
  firstTab_07: {
    en: "Can I keep the laundrybag?",
    nl: "Mag ik de waszak houden?"
  },
  firstTabText_07: {
    en:
      "Yes of course! The bag is all yours. Make sure to save the bag so you can use it for your next order. Our PostNL drivers can only collect orders that are in a dobbi laundrybag.",
    nl:
      "Jazeker, de waszak is helemaal voor jou! Bewaar deze goed zodat je hem bij een volgende order weer kan gebruiken. De PostNL chauffeurs kunnen namelijk alleen was ophalen die in een dobbi waszak zit."
  },
  firstTab_08: {
    en: "I didn't receive a laundrybag?",
    nl: "Ik heb geen waszak ontvangen?"
  },
  firstTabText_08: {
    en:
      "Please contact our customer service if you didn't receive a laundry bag. We will send you one as soon as possible.",
    nl:
      "Heb je geen waszak ontvangen, neem dan direct contact op met onze klantenservice. Wij sturen je dan zo snel mogelijk een nieuwe zak."
  },
  firstTab_09: {
    en: "In what kind of bag should I pack my clothes?",
    nl: "Hoe lever ik mijn kleding aan?"
  },
  firstTabText_09: {
    en:
      "After placing your order you will receive a personalised dobbi laundrybag from us. Collect all your clothes and place them in the laundrybag. If the bag is too small for all of your items please contact our customer service.",
    nl:
      "Voor je eerste order ontvang je van ons een eigen gepersonaliseerde waszak. Stop je kleding in deze zak en sluit deze af met het trekkoord. Past niet al je kleding in een zak, neem dan contact op met onze klantenservice."
  },
  firstTab_10: {
    en: "I can't place an order?",
    nl: "Ik kan geen bestelling plaatsen?"
  },
  firstTabText_10: {
    en:
      "If you can't place an order than please contact our customer serive so we can place the order for you.",
    nl:
      "Dat is vervelend. Het beste kan je even contact op met onze klantenservice zodat wij de order voor je kunnen plaatsen!"
  },
  firstTab_11: {
    en: "When is you service available at my address?",
    nl: "Wanneer is jullie service op mijn adres beschikbaar?"
  },
  firstTabText_11: {
    en:
      "If you would like us to service your area then please let us know! We are adding new zip codes every week. If we receive more request from one area we could expend our service sooner.",
    nl:
      "Komen wij nog niet bij jou in de straat en zou je dat wel graag willen, laat het ons dan weten! Wij voegen wekelijks nieuwe postcodes aan ons servicegebied toe. Bij veel aanvragen uit een bepaalde buurt kunnen hier eerder starten."
  },
  secondTab_01: {
    en: "We are looking for you! As of this moment we have the following positions:",
    nl: "De leukste STAGIAIRE van Nederland (M/V) "
  },
  secondTabText_01: {
    en:
      "No you can't. The laundry bag that you receive from us has a barcode that is linked to your account. Our PostNL drivers can only collect orders that are  in a dobbi laundrybag.",
    nl:
      "Nee dat kan niet. De waszak die je van ons ontvangt heeft een barcode die is gekoppeld aan jouw gegevens. Het is dus niet mogelijk om je kleding in een andere tas aan de PostNL chauffeur aan te bieden."
  },
  secondTab_02: {
    en:
      "Can I change my pick-up or collection time after I have placed my order?",
    nl: "Kan ik mijn order tussentijds wijzigen?"
  },
  secondTabText_02: {
    en:
      "On workdays you can change this up to 8 hours before the scheduled pick-up or delivery. In this case, please contact our customer service.",
    nl:
      "Op werkdagen kan je tot 8 uur voor je ophaal of bezorgmoment de order wijzigen. Neem in dit geval contact op met onze klantenservice."
  },
  secondTab_03: {
    en: "I missed my pick-up or delivery?",
    nl: "Ik was niet thuis, wat nu?"
  },
  secondTabText_03: {
    en:
      "Did you miss our PostNL driver?  Just contact our customer service and we will help you schedule a new day and time.",
    nl:
      "Heb je onze chauffeur gemist, neem dan contact op met onze klantenservice. Wij kunnen dan een nieuwe ophaaltijd inboeken."
  },
  secondTab_04: {
    en: "I placed an order by mistake.",
    nl: "Ik heb te snel of een verkeerde order geplaatst!"
  },
  secondTabText_04: {
    en:
      "No problem, but please let us know as soon as possible! We can cancel your order or change your order so it suits you. Please contact our customer service.",
    nl:
      "Geen punt maar laat het ons wel even weten! Wij kunnen dan namelijk je order annuleren of aanpassen. Neem in dit geval contact met ons op."
  },
  secondTab_05: {
    en: "Can I choose a specic time within my chosen time window?",
    nl:
      "Kan ik binnen het door mij gekozen tijdsventer een specifieke tijd aangeven?"
  },
  secondTabText_05: {
    en:
      "This is not possible. Our routes are constantly being optimised and therefore it is not possible to ask for a specific time within the 2 hour time window.",
    nl:
      "Nee helaas, onze routes worden continue geoptimaliseerd. Hierdoor is het niet mogelijk om binnen het tijdsvenster een specifieke voorkeur aan te geven."
  },
  secondTab_06: {
    en: "How fast will my order be ready",
    nl: "Hoe snel is mijn order klaar?"
  },
  secondTabText_06: {
    en:
      "We offer a 48 hour service (2 working days, weekend not included). For example, if we pick-up your order on Monday we can deliver it back on Wednesday. If we pick-up your order on Friday we can deliver it back on Tuesday. Of course you can also choose a later date for your delivery.",
    nl:
      "Wij hanteren een retourtijd van 48 uur (twee werkdagen, waarbij het weekend niet meetelt). Als wij je order bijvoorbeeld op maandag ophaken kan deze vanaf woensdag retour. Orders die bijvoorbeeld op vrijdag worden opgehaald kunnen vanaf dinsdag worden bezorgd. Je kan natuurlijk de bezorging ook later inplannen."
  },
  secondTab_07: {
    en: "Who picks up and delivers my order?",
    nl: "Hebben jullie eigen busjes?"
  },
  secondTabText_07: {
    en:
      "All orders will be picked-up and delivered by our logistics partner PostNL.",
    nl:
      "Ja en nee. PostNL is mede eigenaar van dobbi dus je zou kunnen zeggen van wel."
  },
  thirdTab_01: {
    en: "How can I pay?",
    nl: "Hoe kan ik betalen?"
  },
  thirdTabText_01: {
    en:
      "You don't have to pay beforehand. You will receive an overview of your items and costs after we have collected and scanned your order at our facility. You can then pay with iDeal, Credit Card and Paypal.",
    nl:
      "Bij ons hoef je niet vooraf te betalen. Je ontvangt een overzicht van de kosten nadat je order is opgehaald en bij onze stomerij is ingescanned. Je kan vervolgens kiezen voor betaling via iDeal, Credit Card en PayPal."
  },
  thirdTab_02: {
    en: "Is there a minimal order value?",
    nl: "Wat zijn de minimale kosten?"
  },
  thirdTabText_02: {
    en:
      "We offer a free pick-up&delivery for all orders above EUR 30. Orders under EUR 30,- will be charged a EUR 9,95 logstics fee.  There is also  a minimum of 6kg for the Wash&Fold service.  For every extra kg you will be charged EUR 4.25.",
    nl:
      "Bij orders onder de EUR 30,- rekenen wij EUR 9,95 transportkosten. Bij orders boven de EUR 30,- rekenen wij geen transportkosten. Bij de was & vouw service hanteren wij een minimum van 6 kg. Boven de 6 kg betaal je per extra kilo."
  },
  fourthTab_01: {
    en: "What is the difference between Dry Cleaning&Ironed and Wash&Fold?",
    nl: "Wat is het verschil tussen Stomen&Strijken en Wassen&Vouwen?"
  },
  fourthTabText_01: {
    en:
      "Choose Dry Cleaning&Ironed for items such as suits, dresses, shirts or any other clothes that you would like to have dry cleaned and/or ironed. We will charge you per item. Choose Wash&Fold for clothing such as T-shirts, socks, undergarments and any other clothing that don't need to be ironed. We will wash the clothes and deliver all items folded. There is a 6 kg minimum for this service. For every extra kg you will be charged EUR 4.25.",
    nl:
      "Kies Stomen&Strijken voor bijvoorbeeld pakken, jurken, overhemden of andere kleding die je gestoomd en/of gestreken wil hebben. Wij rekenen een prijs per artikel. Deze kan je op onze prijslijst vinden.  Kies Wassen&Vouwen voor kleding zoals T-shirts, sokken, ondergoed en overige kleding die niet gestreken hoeft te worden. Wij wassen je kleding en leveren het gevouwen terug. Wij hanteren een minimum van 6 kg voor deze service. Voor elke extra kilo betaal je EUR 4.25 extra."
  },
  fourthTab_02: {
    en: "I can't find my item on the pricelist",
    nl: "Mijn artikel staat niet op jullie prijslijst?"
  },
  fourthTabText_02: {
    en:
      "You can contact our customer service if the item is not on the priceslist.",
    nl:
      "Staat het type kleding niet op onze prijslijst, neem dan contact met ons op!"
  },
  fourthTab_03: {
    en: "Who cleans my clothes",
    nl: "Wie reinigt mijn kleding?"
  },
  fourthTabText_03: {
    en:
      "We only work with the very best cleaners of the Neterlands. All of our cleaningpartners are members of the branche association NETEX and have been certified with a Eco- and Quality Quarentee certificate.",
    nl:
      "Wij werken alleen met de top reinigers van Nederland. Al onze reinigingspartners zijn aangesloten bij de branchevereniging NETEX en in het bezit van een milieu en kwaliteits- garantie certificaat!"
  },
  fourthTab_04: {
    en: "Can you remove all stains?",
    nl: "Kunnen jullie alle vlekken verwijderen?"
  },
  fourthTabText_04: {
    en:
      "Our expert cleaners only use the highest quality equipment and stain removal products. Although we cannot promise all stains will be removed, we do promise we will always  do our best. Please let us know what type of stain you have in the order notes for the best result.",
    nl:
      'Daar kunnen wij eerlijk in zijn, nee. Sommige vlekken zijn simpelweg niet te verwijderen. Wij kunnen dan ook geen "schoongarantie" geven. Wel doen wij ons best alle vlekken zo goed mogelijk te behandelen. Hiervoor werken wij alleen maar met de beste stomerijen van Nederland.'
  },
  fourthTab_05: {
    en: "My clothes have been damaged, what should I do?",
    nl: "Mijn kleding is beschadigd, wat moet ik nu doen?"
  },
  fourthTabText_05: {
    en:
      "If you feel your items have been damaged, please contact our customer care. We'll resolve each case on an individual level.",
    nl:
      "Dat is vervelend! In het geval van beschadiging of vermissing, neem dan direct contact met ons op zodat wij dit direct met je kunnen oplossen!"
  },
  fourthTab_06: {
    en: "Can you clean all different types of textile?",
    nl: "Kunnen jullie alle soorten textiel reinigen?"
  },
  fourthTabText_06: {
    en:
      "Our expert cleaners only use the highest quality equipment and can clean almost all kinds of textiles.  If we feel that an item can not be cleaned without risk we will contact you immediately. At this time we do not offer leather, suede and carpet cleaning. If you have a question about a specific item or type of fabric, please contact our customer service.",
    nl:
      "In feite kunnen wij alle soorten artikelen en stoffen reinigen. Indien wij dit niet kunnen dan zullen we contact met je opnemen. Wij kunnen echter geen leer en sude reinigen en ook het reinigen van vloerkleden is nu helaas nog niet mogelijk! Heb je een vraag over een specifiek artikel of een bijzondere stof, neem dan contact met ons op!"
  },
  fourthTab_07: {
    en: "Do you also clean leather, suede and carpets?",
    nl: "Reinigen jullie ook leer, suède en tapijten?"
  },
  fourthTabText_07: {
    en: "At this time we do not offer a cleaning service for these items.",
    nl:
      "Nee helaas, dit zijn artikelen die een bijzondere behandeling nodig hebben. Op dit moment bieden wij het reinigen van deze artikelen nog niet aan."
  },
  fourthTab_08: {
    en: "Is your cleaningproces environmental friendly?",
    nl: "Reinigen jullie milieuvriendelijk?"
  },
  fourthTabText_08: {
    en:
      "All of our NETEX certified cleaningpartners are held to the highest standards in our industry and are in possesion of an Eco- and Quality Guarentee certificate. Research prooves that proffesional textile cleaning is four times more eco friendly than doing your laundry at home.",
    nl:
      "Al onze reinigingspartners zijn in het bezit van een Milieu certificaat van de branchevereniging NETEX. Daarnaast is professionele textielreiniging vier keer meer duurzaam dan het zelf thuis wassen en drogen!"
  }
};
