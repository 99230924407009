export default {
  feePayLaterTitle: {
    'en': 'Pay later',
    'nl': 'Betaal later',
  },
  feePayLaterText: {
    'en': 'We will send you a payment link by sms after receiving your landry',
    'nl': 'Wij sturen u per sms een betaallink zodra we uw wasgoed ontvangen hebben',
  },
  feeMinOrderTitle: {
    'en': 'Minimum order',
    'nl': 'Minimale orderwaarde'
  },
  feeMinOrderText: {
    'en': 'The minimum order value is',
    'nl': 'De minimale orderwaarde is'
  },
  feeService: {
    'en': 'Service fee',
    'nl': 'Service fee'
  },
  feeServiceStandardTitle: {
    'en': 'Standard €2,95',
    'nl': 'Standaard €2,95'
  },
  feeServiceStandardText: {
    'en': 'Service fee for orders under €50',
    'nl': 'Service fee voor orders onder de €50'
  },
  feeServiceNoChargeTitle: {
    'en': 'No charge',
    'nl': 'Gratis'
  },
  feeServiceNoChargeText: {
    'en': 'Free delivery for all orders above €50',
    'nl': 'Gratis bezorging voor orders boven de €50'
  },
}