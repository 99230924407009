import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Header from '../../../components/Header';

import heroImg from '../../../images/home-banner.jpg';
import heroImg2x from '../../../images/home-banner@2x.jpg';

export const OrderWrapper = props => {
  const { currentOrderScreen } = props;

  const className = classNames({
    'order-block': true,
    'fourth-order-block': currentOrderScreen === 4,
  });

  return (
    <div className="order-page-wrapper">
      <Header
        wv={props.wv}
        heroImg={heroImg}
        heroImg2x={heroImg2x}
        whiteHeader
      />
      <main className="main-order-block">
        <section className={className}>{props.children}</section>
      </main>
    </div>
  );
};

const mapStateToProps = ({ currentOrderScreen }) => ({ currentOrderScreen });

export default connect(mapStateToProps)(OrderWrapper);
