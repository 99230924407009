import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loader from 'react-loader-spinner'
import AuthWrapper from "./components/AuthWrapper";
import AuthHeader from "./components/AuthHeader";
import translation from '../../translations/Auth'
import HomeBanner from "../Order/components/HomeBanner";
import { setNotificationData } from "../../redux/actions";
import RecoverEmail from "./components/RecoverEmail";
import { RecoverEmailSent } from "./components/RecoverEmailSent";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "../../css/pages/user/Recover.css";
import Notification from "../Order/components/Notification";
import Api from "../../utils/api/Api";

export class Recover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      state: 'checking',
      cooldown: 0
    };

    this.lang = localStorage.getItem("lang")
  }

  async componentWillMount() {
    await this.fetchCooldown();
  }

  setCooldown = (cooldown) => {
    this.setState({
      cooldown: cooldown,
      state: 0 === cooldown ? 'email' : 'emailSent'
    });
  };

  fetchCooldown = async () => {
    const email = this.getEmail();

    if (email) {
      Api.checkRecoverEmail(email)
        .then(({ data }) => {
          this.setCooldown(data.cooldown);
        })
        .catch((err) => {
          this.setCooldown(0);
        })
      ;
    } else {
      this.setCooldown(0);
    }
  };

  getEmail = () => {
    return localStorage.getItem('recoverEmail');
  };

  render() {
    let content;
    const { notification } = this.props;

    if ('email' === this.state.state) {
      content = <RecoverEmail setCooldown={ this.setCooldown }/>;
    }

    if ('emailSent' === this.state.state) {
      content = <RecoverEmailSent cooldown={this.state.cooldown} setCooldown={ this.setCooldown }/>;
    }

    if ('checking' === this.state.state) {
      content =
        <Loader
          className="page-loader"
          type="Grid"
          color="#5bffc6"
          height={100}
          width={100}
        />
      ;
    }

    return (
      <AuthWrapper {...this.props}>
        { notification && notification.status && <Notification /> }
        <AuthHeader title={translation.title[this.lang]}/>
        <main className="order-block-body">
          <div className="first-screen-inputs recover">
            <div className="inputs-wrapper">
              <div style={{width: '80%'}}>
                  { content }
              </div>
            </div>
            <HomeBanner version={1}/>
          </div>
        </main>
      </AuthWrapper>
    )
  }
}

const mapStateToProps = ({ notification, webView }) => ({
  notification,
  webView
});

const mapDispatchToProps = dispatch => {
  return {
    setNotificationData: data => dispatch(setNotificationData(data)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Recover);
