import React from "react";
import {getMobileAppLink} from "../../../utils/helpers";

import './HomeBanner.scss'

import translation from '../../../translations/FirstOrderScreen';

import image1 from '../../../images/home/dobbi-order-module.jpg';
import image2 from '../../../images/map-square.png';
import Grid from '@material-ui/core/Grid'
import { Hidden } from '@material-ui/core'

export default class HomeBanner extends React.Component {
  static defaultProps = {
    version: 1
  };

  getImageSrc = () => {
    return 1 === this.props.version ? image1 : image2;
  };

  renderV1 = () => {
    return (
      <div className="home-banner inputs-wrapper">
        <img src={this.getImageSrc()} alt="" className="map-block" />
      </div>
    );
  };

  renderV2 = () => {
    const
      lang    = localStorage.getItem("lang"),
      appLink = getMobileAppLink(),
      appText = null == appLink ? '' : translation.bannerTextApp(appLink)[lang]
    ;

    return(
      <Grid className="home-banner home-banner-grid inputs-wrapper" container spacing={3} justify="center" alignItems="center">
        {/* <Grid sm={12}>
          <p dangerouslySetInnerHTML={{__html: translation.bannerText(appText)[lang]}} />
        </Grid> */}
        <Hidden smDown>
          <Grid md={12}>
            <img src={this.getImageSrc()} alt="" className="map-block" />
          </Grid>
        </Hidden>
      </Grid>
    );
  };

  updateHtmlClass = () => {
    const documentRoot = window.document.querySelector('html');

    documentRoot.classList.remove('home-banner-version-1');
    documentRoot.classList.remove('home-banner-version-2');

    window.document.querySelector('html').classList.add('home-banner-version-' + this.props.version);
  };

  render() {
    this.updateHtmlClass();

    if (2 === this.props.version) {
      return this.renderV1();
    }

    return this.renderV2();
  }
}