import React from "react";
import {compose} from "redux";

import translation from '../translations/Countdown'
import "../css/components/Countdown.css";

export class Time extends React.Component {
  constructor(props) {
    super(props);

    let seconds = undefined === props.seconds ? 0 : props.seconds;

    this.state = {
      seconds: seconds,
      hideEmpty: undefined === this.props.hideEmpty ? false : this.props.hideEmpty
    };

    this.lang = localStorage.getItem("lang");
    this.numerous = require('numerous');
  }

  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    return {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
  }

  setSeconds = (seconds) => {
    this.setState({
      seconds: seconds
    });
  };

  canShowMinutes = (seconds, time) => {
    if (this.props.hideEmpty && 0 === time.m) {
      return false;
    }

    return 60 < seconds;
  };

  canShowHours = (seconds, time) => {
    if (this.props.hideEmpty && 0 === time.h) {
      return false;
    }

    return 3600 < seconds;
  };

  canShowSeconds = (seconds) => {
    return !(this.props.hideEmpty && 0 === seconds);
  };

  render() {
    let
      hours,
      minutes,
      seconds,
      time = this.secondsToTime(this.state.seconds)
    ;

    if (this.canShowMinutes(this.state.seconds, time)) {
      minutes =
        <span className="cd-minutes">
          <span className="cd-value">{ time.m }</span>
          <span className="cd-title">
            {
              this.numerous.pluralize(this.lang, time.m, {
                one: translation.minute[this.lang],
                other: translation.minutes[this.lang]
              })
            }
          </span>
        </span>
      ;
    }

    if (this.canShowHours(this.state.seconds, time)) {
      hours =
        <span className="cd-hours">
          <span className="cd-value">{ time.h }</span>
          <span className="cd-title">
            {
              this.numerous.pluralize(this.lang, time.h, {
                one: translation.hour[this.lang],
                other: translation.hours[this.lang]
              })
            }
          </span>
        </span>
      ;
    }

    if (this.canShowSeconds(time.s)) {
      seconds =
        <span className="cd-seconds">
          <span className="cd-value">{ time.s }</span>
          <span className="cd-title">
            {
              this.numerous.pluralize(this.lang, time.s, {
                one: translation.second[this.lang],
                other: translation.seconds[this.lang]
              })
            }
          </span>
        </span>
      ;
    }

    return(
      <div className={ this.props.className }>
        { hours }
        { minutes }
        { seconds }
      </div>
    );
  }
}

export default compose() (Time);