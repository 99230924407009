import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"
import Select from 'react-select'
import Notification from './components/Notification.jsx'
import HomeBanner from "./components/HomeBanner"

import {
  setUserLocation,
  addUserData,
  setOrderScreen,
  setFormattedAddress,
  setNotificationData,
  setUserData,
  resetSelectedDelivery,
  resetSelectedPickup,
  resetAvailableDeliveryDate,
  resetAvailablePickupDate,
  setSubmitting
} from '../../redux/actions'

import translation from '../../translations/FirstOrderScreen'
import Number from '../../components/numpad/Number'
import Api from '../../utils/api/Api'
import { userCreateGuestSession } from '../../utils/helpers'
import { Button } from '../../components/refectored/Button'
import { formatDst } from '../../mixin/date'
import Grid from '@material-ui/core/Grid'
import WindowSelector from './components/WindowsSelector/WindowSelector'
import moment from 'moment-timezone'

const FirstOrderScreen = props => {
  const { notification } = props;
  // const
  return (
    <main className="order-block-body order-block-body-grid">
      {notification.status && <Notification />}
      <CheckLocationInputs {...props} />
    </main>
  )
}

const ShowServicePoint = ({ point }) => {
  return (
    <div className="service-point-info service-point-show">
      <div className="service-point-company">{ point.company }</div>
      <div className="service-point-address">{ point.addressLine }</div>
    </div>
  )
}

const ShowError = ({error}) => {
  return (
    <div className="service-point-info service-point-error">
      { error }
    </div>
  )
}

const ShowOrder = ({order}) => {
  const lang = localStorage.getItem('lang')

  return (
    <>
      <div className="service-point-info order-pickup-date">
        <div className="block-title">{ translation.pickupDate[lang] }</div>
        <div className="pickup-date">{ formatDst(order.pickupTime, 'dddd DD MMMM') }</div>
      </div>
      <div className="service-point-info order-pickup-time">
        <div className="block-title">{translation.pickupTime[lang]}</div>
        <div>{translation.between[lang]} {`${formatDst(order.frames.pickup.from, 'HH:mm')} - ${formatDst(order.frames.pickup.to, 'HH:mm')}`}</div>
      </div>
      <div className="service-point-info order-contact" dangerouslySetInnerHTML={{__html: translation.orderContact[lang]}} />
    </>
  )
}

const CheckLocationInputs = ({resetSelectedPickup, resetAvailablePickupDate, resetAvailableDeliveryDate, resetSelectedDelivery, setSubmitting}) => {
  const lang = localStorage.getItem('lang')
  const [services, setServices] = useState([])
  const [state, setState] = useState({
    showNumpad: true,
    servicePoint: null,
    code: null,
    error: null,
    variation: 1,
    loading: false,
    order: null,
    submitting: false,
    pickupModal: false,
    deliveryModal: false,
    services: []
  })

  const onChange = (value) => {
    if (4 === value.length) {
      updateState({showNumpad: false})
      loadServicePoint(value)
      loadServices()
    }
  }

  const loadServicePoint = (value) => {
    resetSelectedPickup()
    resetAvailablePickupDate()
    resetAvailableDeliveryDate()
    resetSelectedDelivery()

    updateState({loading: true})

    Api.fetchServicePointB2b(value).then(({ data }) => {
      updateState({
        servicePoint: data,
        showNumpad: false,
        error: null,
        code: value,
        loading: false,
      })
    }).catch(async () => {
      updateState({
        servicePoint: null,
        showNumpad: false,
        error: translation.locationNotFound[lang],
        code: null,
        loading: false,
      })
    })
  }

  const loadServices = () => {
    Api.getServices().then(({ data }) => {
      const servicesArr = data.data.map(item => ({ value: item.droplocker, label: item.title }))
      setServices(servicesArr)
    })
  }

  const clear = (e) => {
    e.preventDefault()

    updateState({
      showNumpad: true,
      servicePoint: null,
      error: null,
      code: null,
      variation: state.variation + 1,
      loading: false,
      submitting: false,
      order: null,
      pickupDate: null,
      pickupTime: null,
      deliveryDate: null,
      deliveryTime: null,
    })
  }

  const updateState = (newState) => {
    const oldState = {...state}

    setState({...oldState, ...newState})
  }

  const encodeDates = (date, time) => moment.tz(`${date}T${time}:00`, 'Europe/Amsterdam').format();

  const placeOrder = async () => {
    updateState({submitting: true})
    setSubmitting(true)

    await userCreateGuestSession()

    Api.placeNoTerminalManualOrder(state.code, encodeDates(state.pickupDate, state.pickupTime), encodeDates(state.deliveryDate, state.deliveryTime), state.services).then(({ data }) => {
      setSubmitting(false)
      updateState({
        showNumpad: false,
        error: null,
        order: data,
        submitting: false,
      })
    }).catch(async (err) => {
      setSubmitting(false)
      console.log(err);
      updateState({
        servicePoint: null,
        showNumpad: false,
        error: translation.orderPlacingError[lang],
        submitting: false,
      })
    })
  }

  const onPickupSelected = (date, time) => {
    updateState({
      pickupDate: date,
      pickupTime: time,
    })
  }

  const onDeliverySelected = (date, time) => {
    updateState({
      deliveryDate: date,
      deliveryTime: time,
    })
  }

  const changeService = (options) => {
    updateState({
      services: options.map(item => item.value)
    })
  }

  return (
    <form key={state.variation} className="first-screen-inputs" onSubmit={e => {e.preventDefault();}}>
      <Grid container spacing={3} justify="center" alignItems="flex-start">
        <Grid item md={7} sm={12} className="inputs-wrapper">
          <Grid container spacing={3} justify="flex-start" alignItems="center">
            <Grid item xs={12}>
              <h1 className="inputs-wrapper-title">{state.order ? translation.orderPlaced[lang] : translation.fillLocationCode[lang]}</h1>
            </Grid>
          </Grid>
          <Number
            onChange={onChange}
            label={translation.locationCode[lang]}
            placeholder={'Click to enter the code'}
            decimal={false}
            negative={false}
            position="top"
            sync={true}
            inline={true}
            show={state.showNumpad && !state.loading}
            loading={state.loading}
            leadingZeros={true}
          />
          { (state.error && !state.loading) &&
          <Grid container spacing={3} justify="flex-start" alignItems="center">
            <Grid item xs={12}>
              <ShowError error={state.error} />
            </Grid>
          </Grid>
          }

          { (state.servicePoint && !state.loading) &&
          <Grid container spacing={3} justify="flex-start" alignItems="center">
            <Grid item xs={12}>
              <ShowServicePoint point={state.servicePoint} />
            </Grid>
            <Grid item xs={12}>
              {!state.order && !state.error &&
              <WindowSelector
                code={state.code}
                onPickupSelected={onPickupSelected}
                onDeliverySelected={onDeliverySelected}
              />
              }
            </Grid>
          </Grid>
          }

          {state.order &&
            <Grid container spacing={3} justify="flex-start" alignItems="center">
              <Grid item xs={12}>
                <ShowOrder order={state.order} />
              </Grid>
            </Grid>
          }

          <div style={{ margin: '10px 0 10px 0'}}>
            {state.servicePoint &&
              <Select
                isMulti
                options={services}
                onChange={changeService}
              />
            }
          </div>

          {(!state.showNumpad && !state.loading && !state.submitting) &&
            <Grid container spacing={3} justify="flex-start" alignItems="center">
              <Grid item xs={12}>
                <a href="#" className="service-point-clear service-point-info" onClick={clear}>{state.code ? translation.orEnterNewCode[lang] : translation.enterNewCode[lang]}</a>
              </Grid>
            </Grid>
          }

          { (state.code && !state.loading && !state.order && state.pickupDate && state.pickupTime && state.deliveryTime && state.deliveryDate && !state.error) &&
          <div className="service-point-info service-point-actions">
            <Button
              color="blue"
              onClick={ () => placeOrder() }
              submitting={state.submitting ? 1 : 0}
              className="checked"
            >{ translation.placeOrder[lang] }
            </Button>
          </div>
          }
        </Grid>
        <Grid item md={5} sm={12}>
          <HomeBanner version={ 1 } />
        </Grid>
      </Grid>
    </form>
  )
}

const mapStateToProps = ({ webView, userLocation, userData, notification }) => ({
  wv: webView,
  userLocation,
  userData,
  notification
});

const mapDispatchToProps = dispatch => {
  return {
    setOrderScreen: data => dispatch(setOrderScreen(data)),
    setFormattedAddress: data => dispatch(setFormattedAddress(data)),
    setUserLocation: data => dispatch(setUserLocation(data)),
    addUserData: data => dispatch(addUserData(data)),
    setNotificationData: data => dispatch(setNotificationData(data)),
    setUserData: data => dispatch(setUserData(data)),
    resetSelectedDelivery: data => dispatch(resetSelectedDelivery(data)),
    resetSelectedPickup: data => dispatch(resetSelectedPickup(data)),
    resetAvailableDeliveryDate: data => dispatch(resetAvailableDeliveryDate(data)),
    resetAvailablePickupDate: data => dispatch(resetAvailablePickupDate(data)),
    setSubmitting: data => dispatch(setSubmitting(data)),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FirstOrderScreen);
