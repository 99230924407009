import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import uuid from 'uuid';
import find from 'lodash.find';
import {
  setSelectedPickupData,
  setAvaiblePickupData,
  setAvaibleDeliveryData,
  setSelectedPickupTime,
  setSelectedDeliveryTime,
  deliveryDataLoading, resetAvailableDeliveryDate, resetSelectedDelivery
} from '../../../../redux/actions';
import Api from "../../../../utils/api/Api";

class PickupOrderHours extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleItemClick = this.handleItemClick.bind();
  }

  filerCurrentDate = () => {
    const {
      avaliablePickupDate,
      avaliableDeliveryDate,
      pickup,
      selectedPickupDate,
      selectedDeliveryDate,
      selectedPickupTime
    } = this.props;

    const avaliableHours = pickup ? avaliablePickupDate : avaliableDeliveryDate;
    const selectedData = pickup ? selectedPickupDate : selectedDeliveryDate;

    let currentHours = find(avaliableHours, o => o.date === selectedData);
    currentHours = currentHours ? currentHours.hours : [];

    currentHours.forEach(function(part, index) {
      this[index]['selected'] = selectedPickupTime === part['from'];
    }, currentHours);

    return currentHours;
  };

  handleItemClick = async(e) => {
    const {
      selectedPickupDate,
      setAvaibleDeliveryData,
      setSelectedPickupTime,
      setSelectedDeliveryTime,
      pickup,
      deliveryDataLoading,
      resetAvailableDeliveryDate,
      resetSelectedDelivery,
    } = this.props;

    const target = e.target.closest('.hours-item');
    const targetFrom = target.dataset.from;
    const pickupItems = document.querySelectorAll('.pickup-items');

    Array.prototype.forEach.call(pickupItems, node => {
      node.classList.remove('selected');
    });
    target.classList.add('selected');

    const selectedData = selectedPickupDate;
    // const date = encodeURIComponent(`${selectedData}T${targetFrom}:00`);

    setSelectedDeliveryTime('00:00');

    this.props.onPickupSelected(null, null)

    if (pickup) {
      deliveryDataLoading(true);
      resetAvailableDeliveryDate();
      resetSelectedDelivery();
    }

    pickup &&
    Api.getServicePointDeliveryWindows(this.props.code, 'delivery', selectedData)
        .then(response => response.data)
        .then((delivery) => {
          setAvaibleDeliveryData(delivery);
          deliveryDataLoading(false);
        })
        .then(() => {
          setSelectedPickupTime(targetFrom)
          this.props.onPickupSelected(selectedData, targetFrom)
        })
        .catch(error => console.log(error));
  };

  render() {
    const { pickup, delivery, selected } = this.props;
    const avaliableHours = this.filerCurrentDate();

    return (
      <ul
        id="step-4-pickup-list-time"
        className="hours-block"
      >
        {avaliableHours.map(item => {
          const className = classNames({
            'hours-item': true,
            'pickup-items': pickup,
            'delivery-items': delivery,
            selected: (selected || item.selected) && !this.props.submitting,
            avaliable: item.available,
          });

          return (
            <li
              key={uuid()}
              className={className}
              onClick={!this.props.submitting && item.available && this.handleItemClick}
              data-from={item.from}
            >
              <span className="time-from">{item.from}</span>
              <span>{item.to}</span>
            </li>
          );
        })}
      </ul>
    );
  }
}
const mapStateToProps = ({
  avaliablePickupDate,
  selectedPickupDate,
  apiToken,
  userLocation,
  hasBag,
  selectedPickupTime,
  submitting
}) => ({
  selectedPickupDate: selectedPickupDate.date,
  token: apiToken,
  avaliablePickupDate,
  userLocation,
  hasBag,
  selectedPickupTime,
  submitting
});

const mapDispatchToProps = dispatch => {
  return {
    setSelectedDeliveryTime: date => dispatch(setSelectedDeliveryTime(date)),
    setSelectedPickupTime: date => dispatch(setSelectedPickupTime(date)),
    setSelectedPickupData: date => dispatch(setSelectedPickupData(date)),
    setAvaiblePickupData: data => dispatch(setAvaiblePickupData(data)),
    setAvaibleDeliveryData: data => dispatch(setAvaibleDeliveryData(data)),
    deliveryDataLoading: data => dispatch(deliveryDataLoading(data)),
    resetAvailableDeliveryDate: data => dispatch(resetAvailableDeliveryDate(data)),
    resetSelectedDelivery: data => dispatch(resetSelectedDelivery(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickupOrderHours);
