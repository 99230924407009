import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { setOrderScreen } from "../../redux/actions";
import { addUserData, setUserData, setNotificationData } from "../../redux/actions";

import {urlQuery, userCreateGuestSession, validateEmail} from '../../utils/helpers'

import translation from '../../translations/SecondOrderScreen'

import "../../css/pages/Order/Order.css";
import { Button } from "../../components/Button";
import Notification from "./components/Notification";
import Api from "../../utils/api/Api";

class SecondOrderScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validEmail: false,
      email: '',
      password: '',
      validPassword: false,
      checkboxChecked: false
    };

    // this.notification = props.notification;
    this.button = React.createRef();
  }

  setEmail = (e) => {
    let email = e.target.value;

    this.setState({
      validEmail: validateEmail(email),
      email: email
    });
  };

  setPassword = (e) => {
    let password = e.target.value;

    this.setState({
      validPassword: 8 < password.length,
      password: password
    });
  };

  handleCheck = () => {
    this.setState({ checkboxChecked: this.checkboxInput.checked });
  };

  isValid = () => {
    return this.state.validEmail && this.state.checkboxChecked && this.state.validPassword;
  };

  createAccount = async (e) => {
    const lang = localStorage.getItem("lang");

    Api.checkAccountExist(this.state.email)
      .then(async({ data }) => {
        if (true === data.exists) {
          const message = { status: true, message: translation.accountExists[lang] };

          this.props.setNotificationData(message);
          this.button.current.enable();

          setTimeout(() => {
            const data = { status: false, message: '' };
            this.props.setNotificationData(data)
          }, 5000);
        } else {
          const { userData } = this.props;
          const email = this.state.email;
          const plainPassword = this.state.password;
          const language = localStorage.getItem("lang");
          const newUserData = { ...userData, email, language, plainPassword };

          this.props.addUserData(newUserData);
          this.props.setOrderScreen(3);

          await userCreateGuestSession();
        }
      })
      .catch((err) => {
        this.props.setNotificationData({ status: true, message: translation.accountExists[lang] });
        this.button.current.enable();

        setTimeout(() => {
          this.props.setNotificationData({ status: false, message: '' })
        }, 5000);
      })
    ;
  };

  componentDidMount() {
    this.props.history.push(urlQuery({ page: 'email-input' }));
    try { window.dataLayer.push({ event: 'in-service-area' }) } catch (err) { console.log("Error", err) }
  }

  render() {
    const lang = localStorage.getItem("lang");
    const { notification } = this.props;

    return (
      <div className="first-screen-wrapper">
        { notification.status && <Notification />}
        <form id="order-user-create-account" className="first-screen-block" onSubmit={e => {e.preventDefault();}}>
          <h1>{translation.withinReach[lang]}</h1>
          <p>{translation.fillEmail[lang]}</p>
          <input
            id="step-2-user-input-email"
            onChange={ this.setEmail }
            type="text"
            placeholder={ translation.emailPlaceHolder[lang] }
            required
          />
          <input
            id="step-2-user-input-password"
            type="password"
            onChange={ this.setPassword }
            placeholder={translation.passwordPlaceHolder[lang]}
            required
          />
          <div className="first-screen-checkboox">
            <input
              onChange={this.handleCheck}
              ref={ref => (this.checkboxInput = ref)}
              type="checkbox"
              id="step-2-user-checkbox-terms"
              required
            />
            <label htmlFor="step-2-user-checkbox-terms">
              {translation.iAccept[lang]}&nbsp;
              <Link to={`/${lang}/terms`} target="_blank">
                {translation.termsAndConditions[lang]}
              </Link>
              &nbsp;{translation.andThe[lang]}&nbsp;
              <Link to={`/${lang}/cookie`} target="_blank">
                {translation.privacyStatement[lang]}
              </Link>
            </label>
          </div>
          <Button
            id="step-2-user-button"
            color="green"
            disabled={ !this.isValid() }
            onClick={ this.createAccount }
            ref={ this.button }
          >
            {translation.next[lang]}
          </Button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({
  currentOrderScreen,
  userData,
  userLocation,
  placeOrderData,
  notification
}) => ({
  currentOrderScreen,
  userData,
  userLocation,
  placeOrderData,
  notification
});

const mapDispatchToProps = dispatch => {
  return {
    setOrderScreen: data => dispatch(setOrderScreen(data)),
    addUserData: data => dispatch(addUserData(data)),
    setUserData: data => dispatch(setUserData(data)),
    setNotificationData: data => dispatch(setNotificationData(data))
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SecondOrderScreen);
