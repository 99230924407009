import numpad from './themes/numpad';

export default name => {
  const themes = { numpad };
  return themes[name] || numpad;
}


export const numpadStyles = {
  keys: {
    marginTop: 20,
    background: numpad.header.secondaryColor,
    borderRadius: 4,
    padding: '10px 5px',
  },
  keyButton: {
    fontSize: '1.3em !important',
    padding: '0 !important',
    borderRadius: '4px  !important',
    width: '100% !important',
    height: '45px !important',
    margin: '6px auto !important',
    minWidth: 'unset !important',
  },
  keyWrapper: {
    padding: '0 5px !important',
  },
  input: {
    maxWidth: 'none !important',
    textAlign: 'center',
    fontSize: 35,
  },
  backspace: {
    margin: '0 !important',
    height: '40px !important',
    padding: '13px 10px !important',
    minWidth: 'unset !important',
    borderRadius: '4px !important',
  },
  buttonWrapper: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    width: '100%'
  }
}