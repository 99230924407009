import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { langHelper } from "../../utils/helpers";

import heroImg from "../../images/pricelist-banner.jpg";
import heroImg2x from "../../images/pricelist-banner@2x.jpg";
import "../../css/pages/stripped_pages/Confirm.css";
const translate = {
  pf_01: {
    en: "Payment not successful",
    nl: "Betaling niet geslaagd"
  },
  pf_02: {
    en: "Please try again with the link in your sms. Or call us at ",
    nl: "Probeer het opnieuw via de link uit de sms. Of bel ons op "
  },
  pf_03:{
    en:"for support",
    nl:"voor hulp"
  }
}
const { pf_01, pf_02, pf_03 } = translate;


const PayFail = props => {
  const lang = langHelper(props);

  return (
    <div className="confirm-page-wrapper">
      {props.wv && (
        <Header wv={props.wv} heroImg={heroImg} heroImg2x={heroImg2x} />
      )}
      <main className="confirm-main">
        <h1>{pf_01[lang]}</h1>
        <p>
          {pf_02[lang]}&nbsp;
          <a href="tal:+31850187540">+31 85 01 875 40</a> {pf_03[lang]}
        </p>
      </main>
      <Footer wv={props.wv} />
    </div>
  );
};

const mapStateToProps = ({webView}) => ({wv: webView})

export default compose(
  withRouter,
  connect(mapStateToProps)
)(PayFail)
