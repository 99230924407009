import axios from "axios"

export const postNL = (postalCode, houseNumber, addition) => {
	return axios({
		url: "https://api.postnl.nl/address/national/v1/validate",
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"apikey": "aR6z0SAMQPhaKa4DYE3GfUaC70fFxl1z",
		},
		data: {
			PostalCode: postalCode,
			HouseNumber: houseNumber,
			Addition: addition,
		},
	})
};
