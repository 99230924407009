import React from "react";
import Slider from "react-slick";

import arrow_divider from "../images/arrow-divider.svg";
import basket from "../images/basket-icon.svg";
import clothes from "../images/clothes-icon.svg";
import delivery from "../images/delivery-icon.svg";
import magic from "../images/magic-icon.svg";
import "../css/components/HowItWorks.css";

const translation = {
  order_00: {
    en: "How it works",
    nl: "Hoe het werkt"
  },
  order_01: {
    en: "Order",
    nl: "Order"
  },
  orderText_01: {
    en: "Place your order in just a few swipes. You will receive a free starter kit before your first pick up.",
    nl: "Plaats je order binnen een paar klikken. Je ontvangt een gratis waszak voordat we de order ophalen"
  },
  order_02: {
    en: "Pick up",
    nl: "Ophalen"
  },
  orderText_02: {
    en: "Our PostNL driver will pick up your order in the selected 2 hour time window.",
    nl: "Onze PostNL chauffeur haalt je order op in het gekozen 2 uur tijdsvenster"
  },
  order_03: {
    en: "Magic",
    nl: "Betoverend schoon"
  },
  orderText_03: {
    en: "Our expert cleaners will do their magic at their state of the art facility.",
    nl: "Onze was & stoom experts reinigen je kleding met de grootst mogelijke zorg. "
  },
  order_04: {
    en: "Deliver",
    nl: "Bezorgen"
  },
  orderText_04: {
    en: "We deliver your order when it suits you within 48 hours.",
    nl: "Wij bezorgen de order binnen 48 uur en wanneer het jou uitkomt."
  },
}

const { order_00, order_01, order_02, order_03, order_04, orderText_01, orderText_02, orderText_03, orderText_04 } = translation;

class SimpleSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const lang = localStorage.getItem("lang");
    return (
      <Slider {...settings}>
        <div className="slider_item">
          <div>
            <div className="image-wrapper">
              <img src={basket} alt="" />
            </div>
            <div className="title-arrow_wrapper">
              <h3>{order_01[lang]}</h3>
              <img className="arrow_divider" src={arrow_divider} alt="" />
            </div>
            <p>
              {orderText_01[lang]}
            </p>
          </div>
        </div>
        <div className="slider_item">
          <div>
            <div className="image-wrapper">
              <img src={delivery} alt="" />
            </div>
            <div className="title-arrow_wrapper">
              <h3>{order_02[lang]}</h3>
              <img className="arrow_divider" src={arrow_divider} alt="" />
            </div>
            <p>
              {orderText_02[lang]}
            </p>
          </div>
        </div>
        <div className="slider_item">
          <div>
            <div className="image-wrapper">
              <img src={magic} alt="" />
            </div>
            <div className="title-arrow_wrapper">
              <h3>{order_03[lang]}</h3>
              <img className="arrow_divider" src={arrow_divider} alt="" />
            </div>
            <p>
              {orderText_03[lang]}
            </p>
          </div>
        </div>
        <div className="slider_item">
          <div>
            <div className="image-wrapper">
              <img src={clothes} alt="" />
            </div>
            <div className="title-arrow_wrapper">
              <h3>{order_04[lang]}</h3>
              <img className="arrow_divider" src={arrow_divider} alt="" />
            </div>
            <p>{orderText_04[lang]}</p>
          </div>
        </div>
      </Slider>
    );
  }
}

const HowItWorks = props => {
  const lang = localStorage.getItem("lang");

  return (
    <section className="how-it-works" id="how-to">
      <h1>{order_00[lang]}</h1>
      <div className="timeline-progress">
        <div>
          <div className="image-wrapper">
            <img src={basket} alt="" />
          </div>
          <div className="title-arrow_wrapper">
            <h3>{order_01[lang]}</h3>
            <img className="arrow_divider" src={arrow_divider} alt="" />
          </div>
          <p>
            {orderText_01[lang]}
          </p>
        </div>
        <div>
          <div className="image-wrapper">
            <img src={delivery} alt="" />
          </div>
          <div className="title-arrow_wrapper">
            <h3>{order_02[lang]}</h3>
            <img className="arrow_divider" src={arrow_divider} alt="" />
          </div>
          <p>
            {orderText_02[lang]}
          </p>
        </div>
        <div>
          <div className="image-wrapper">
            <img src={magic} alt="" />
          </div>
          <div className="title-arrow_wrapper">
            <h3>{order_03[lang]}</h3>
            <img className="arrow_divider" src={arrow_divider} alt="" />
          </div>
          <p>
            {orderText_03[lang]}
          </p>
        </div>
        <div>
          <div className="image-wrapper">
            <img src={clothes} alt="" />
          </div>
          <div className="title-arrow_wrapper">
            <h3>{order_04[lang]}</h3>
            <img className="arrow_divider" src={arrow_divider} alt="" />
          </div>
          <p>{orderText_04[lang]}</p>
        </div>
      </div>
      <SimpleSlider />
    </section>
  );
};

export default HowItWorks;
