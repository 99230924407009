import React from "react";

import "../css/components/Button.css";

import white from "../images/order/white-arrow.svg";
import blue  from "../images/order/blue-arrow.svg";
import {compose} from "redux";

export class Button extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enabled: true
    };

    this.classes = ['button_basic'];
    this.arrow = undefined;

    if (undefined !== props.color) {
      this.classes.push('button-' + props.color)
    }

    if (undefined !== props.arrow) {
      this.classes.push('button_with-arrow');
      this.arrow = 'white' === props.arrow ? white : blue;
    }
  }

  onClick = () => {
    if (this.state.enabled) {
      this.disable();
    }

    if (this.props.onClick) {
      this.props.onClick()
    }
  };

  enable = () => {
    this.setState({
      enabled: true
    })
  };

  disable = () => {
    this.setState({
      enabled: false
    })
  };

  isDisabled = () => {
    if (true === this.props.disabled) {
      return true;
    }

    return !this.state.enabled;
  };

  render() {
    return (
      <button
        {...this.props}
        className={this.classes.join(' ') + ' ' + this.props.className}
        disabled={ this.isDisabled() }
        onClick={this.onClick}
      >
        <div className="title">{this.props.children}</div>
        {undefined !== this.arrow &&
        <img src={this.arrow} alt=""/>
        }
      </button>
    )
  }
}

export default compose() (Button);