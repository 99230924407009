import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { langHelper } from "../../utils/helpers";

import heroImg from "../../images/pricelist-banner.jpg";
import heroImg2x from "../../images/pricelist-banner@2x.jpg";
import "../../css/pages/stripped_pages/Confirm.css";

const translate = {
  vf_01: {
    en: "Something went wrong",
    nl: "Er gaat iets mis"
  },
  vf_02: {
    en: "Unfortunately this link was not valid. Call us at ",
    nl: "Helaas was deze link niet geldig. Bel ons op "
  },
  vf_03:{
    en:"for support",
    nl:"voor hulp"
  }
}
const { vf_01, vf_02, vf_03 } = translate;

const ValidFail = props => {
  const lang = langHelper(props);

  return (
    <div className="confirm-page-wrapper">
      {props.wv && (
        <Header wv={props.wv} heroImg={heroImg} heroImg2x={heroImg2x} />
      )}
      <main className="confirm-main">
        <h1>{vf_01[lang]}</h1>
        <p>
          {vf_02[lang]}
          <a href="tal:+31850187540">+31 85 01 875 40</a> {vf_03[lang]}
        </p>
      </main>
      <Footer wv={props.wv} />
    </div>
  );
};

const mapStateToProps = ({ webView }) => ({ wv: webView });

export default compose(
  withRouter,
  connect(mapStateToProps)
)(ValidFail);
