import React from "react";

import { TabsBlock } from "./Tabs";
import { MobileTabs } from "./MobileTabs";
import Bubbles from "../images/faq-bubbles.svg";

import "../css/components/FaqBlock.css";
import { translation } from '../utils/dataFiles/faqLoco';

const { terms_00, terms_01, terms_02 } = translation;

const ContactInfo = props => {
  const lang = localStorage.getItem("lang");

  return (
    <section className="faq-block__wrapper">
      <div className="faq-block">
        <div className="faq-header_wrapper">
          <h1>{terms_00[lang]}</h1>
          <p>
            {terms_01[lang]} <br />
            <a href="mailto:info@dobbi.com">info@dobbi.com</a> <br />
            {terms_02[lang]} <a href="tel:+31850187540">+31 85 01 875 40</a>
          </p>
        </div>
        <TabsBlock />
        <MobileTabs />
        <img src={Bubbles} alt="" />
      </div>
    </section>
  );
};

export default ContactInfo;
