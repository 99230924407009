import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import "../css/components/AccordionFooter.css";
import facebook from '../images/socials/fb.svg'
import linkedin from '../images/socials/ln.svg'
import instagram from '../images/socials/ig.svg'

const translation = {
  footerText_01: {
    en: "Skip your laundry",
    nl: "Skip your laundry"
  },
  footerText_02: {
    en: "Picked up, cleaned & delivered when it suits you Powered by Persil",
    nl: "Opgehaald, gereinigd & teruggebracht wanneer het jou uitkomt Powered by Persil"
  },
  footerText_03: {
    en: "Location",
    nl: "Locatie"
  },
  footerText_04: {
    en: "Joop van den Endeplein 1",
    nl: "Joop van den Endeplein 1"
  },
  footerText_05: {
    en: "1217 WJ Hilversum",
    nl: "1217 WJ Hilversum"
  },
  footerText_06: {
    en: "The Netherlands",
    nl: "Nederland"
  },
  footerText_07: {
    en: "Customer care",
    nl: "Klantenservice"
  },
  footerText_08: {
    en: "Phone: +31 85 01 875 40",
    nl: "Telefoon: +31 85 01 875 40"
  },
  footerText_09: {
    en: "Email: info@dobbi.com",
    nl: "Email: info@dobbi.com"
  },
  footerText_10: {
    en: "Monday to Friday:",
    nl: "Maandag tot vrijdag:"
  },
  footerText_11: {
    en: "08:30 – 21:00",
    nl: "08:30 – 21:00"
  },
  footerText_12: {
    en: "Saturday:",
    nl: "Zaterdag"
  },
  footerText_13: {
    en: "09:00 – 17:00",
    nl: "09:00 – 17:00"
  },
  footerText_14: {
    en: "Clean Clothes B.V. – dobbi",
    nl: "Clean Clothes B.V. – dobbi"
  },
  footerText_15: {
    en: "IBAN: NL44ABNA0814405754",
    nl: "IBAN: NL44ABNA0814405754"
  },
  footerText_16: {
    en: "KvK: 70098778",
    nl: "KvK: 70098778"
  },
  footerText_17: {
    en: "BTW: 858140901B01",
    nl: "BTW: 858140901B01"
  },
  footerText_18: {
    en: "Home",
    nl: "Home"
  },
  footerText_19: {
    en: "Pricelist",
    nl: "Prijslijst"
  },
  footerText_20: {
    en: "Explore dobbi",
    nl: "Ontdek dobbi"
  },
  footerText_21: {
    en: "FAQ",
    nl: "FAQ"
  },
  footerText_22: {
    en: "Contact",
    nl: "Contact"
  },
  footerText_23: {
    en: "Terms & conditions",
    nl: "Algemene voorwaarden"
  },
  footerText_24: {
    en: "Phone:",
    nl: "Telefoon:"
  }
}

const { footerText_03, footerText_04, footerText_05, footerText_06, footerText_07,
  footerText_10, footerText_11, footerText_12, footerText_13, footerText_14, footerText_24, footerText_15, footerText_16, footerText_17 } = translation;

const AccordionFooter = props => {
  const lang = localStorage.getItem("lang");

  return (<Accordion className="accodrion-block mobile__footer" >
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">{footerText_03[lang]}</h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p>
          {footerText_04[lang]} <br />
          {footerText_05[lang]} <br />
          {footerText_06[lang]} <br />
          {footerText_14[lang]}<br /><br />
          {footerText_15[lang]} <br />
          {footerText_16[lang]} <br />
          {footerText_17[lang]}
        </p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">{footerText_07[lang]}</h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p>
          {footerText_24[lang]} <a href="tel:+31850187540">+31 85 01 875 40</a> <br />
          Email: <a href="mailto:info@dobbi.com">info@dobbi.com</a>
          <br />
          <br />
          {footerText_10[lang]}: <br />
          {footerText_11[lang]} <br />
          {footerText_12[lang]} <br />
          {footerText_13[lang]}
          <br /> <br />
          {footerText_14[lang]} <br />
          <div className="footer-socials">
            <a href="https://www.facebook.com/dobbiNL/" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="" /></a>
            <a href="https://www.linkedin.com/company/dobbi-nl/" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="" /></a>
            <a href="https://www.instagram.com/dobbi_nl/?hl=nl" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="" /></a>
          </div>
        </p>
      </AccordionItemPanel>
    </AccordionItem>
  </Accordion >
  );
}

export default AccordionFooter;
