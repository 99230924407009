import React from 'react';
import location from '../../../images/order/location.svg';

import translation from '../../../translations/OrderBlockHeader'
import {Link} from "react-router-dom";
import bullet from '../../../images/about-imgs/bullet.svg';

const AuthHeader = props => {
  const lang = localStorage.getItem("lang");

  return (
    <header className="order-header">
      <div className="logo-block">
        <img src={ location } alt="" />
      </div>
      <div className="text-block with-breadcrumbs">
        <div className="breadcrumbs-container ">
          <Link id="order-address-selection-link" to={{
            pathname: '/' + lang + '/',
            search: '?page=address-selection'
          }}>{ translation.title[lang] }</Link>
          <div className="separator">
            <img src={ bullet } alt="" width="14" height="14"/>
          </div>
          <p>{ props.title }</p>
        </div>
        <p className="bold-text">
        </p>
      </div>
    </header>
  );
};

export default AuthHeader;