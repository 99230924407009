import React from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { pricesEn, pricesNl } from "../utils/dataFiles/pricesLoco";

import dry from "../images/dry-cleaning.svg";
import wash from "../images/wash-cloth.svg";
import "../css/components/PriceBlock.css";

const translation = {
  price_01: {
    en: "Dry Cleaning & Ironed ",
    nl: "Stomen & Strijken"
  },
  priceText_00: {
    en: "Pricelist dry cleaning",
    nl: "Prijslijst Stomen & Strijken"
  },
  priceText_001: {
    en: "Pricelist wash & fold",
    nl: "Prijslijst Wassen & Vouwen"
  },
  priceText_01: {
    en: "Clothing is cleaned, ironed and hung. Priced per item.",
    nl: "Kleding reinigen, strijken en op hanger geleverd. Prijs per item."
  },
  price_02: {
    en: "Wash & Fold",
    nl: "Wassen & Vouwen"
  },
  priceText_02: {
    en: "Clothing is washed, dried and folded (no ironing). Priced per kg. 0-6 kg €27,95. Per extra kilo €4,75",
    nl: "Kleding wassen, drogen en vouwen (niet gestreken). Prijs per kg. 0-6 kg €27,95. Per extra kilo €4,75"
  },
  secondTab_01: {
    en: "Wash, Tumble Dry & Fold 0-6 kg",
    nl: "Wassen, drogen & vouwen 0-6 kg"
  },
  secondTab_02: {
    en: "Per extra kg",
    nl: "Per extra kg"
  },
}


const { price_01, price_02, priceText_01, priceText_02, secondTab_01, secondTab_02, priceText_00, priceText_001 } = translation;

const PriceBlock = props => {
  const lang = localStorage.getItem("lang");
  const prices = lang === 'nl' ? pricesNl : pricesEn;
  return (
    <Tabs>
      <section className="price-block">
        <TabList>
          <div className="tabs-header">
            <Tab>
              <div className="tabs" data-tab-id="tab-1">
                <img src={dry} alt="" />
                <h2>{price_01[lang]}</h2>
                <p>
                  {priceText_01[lang]}
                </p>
              </div>
            </Tab>
            <Tab>
              <div className="tabs" data-tab-id="tab-2">
                <img src={wash} alt="" />
                <h2>{price_02[lang]}</h2>
                <p>
                  {priceText_02[lang]}
                </p>
              </div>
            </Tab>
          </div>
        </TabList>

        <div className="tabs-body">
          <TabPanel>
            <div className="tab-tablet_header">
              <h2>{priceText_00[lang]}</h2>
              <p>
                {priceText_01[lang]}
              </p>
            </div>
            <div className="tab-inner" id="tab-1">
              <div className="tab-inner_col">
                <PriceItem name={prices[0].name} price={prices[0].price} />
                <PriceItem name={prices[1].name} price={prices[1].price} />
                <PriceItem name={prices[2].name} price={prices[2].price} />
                <PriceItem name={prices[3].name} price={prices[3].price} />
                <PriceItem name={prices[4].name} price={prices[4].price} />
                <PriceItem name={prices[5].name} price={prices[5].price} />
                <PriceItem name={prices[6].name} price={prices[6].price} />
                <PriceItem name={prices[7].name} price={prices[7].price} />
              </div>
              <div className="tab-inner_col">
                <PriceItem name={prices[8].name} price={prices[8].price} />
                <PriceItem name={prices[9].name} price={prices[9].price} />
                <PriceItem name={prices[10].name} price={prices[10].price} />
                <PriceItem name={prices[11].name} price={prices[11].price} />
                <PriceItem name={prices[12].name} price={prices[12].price} />
                <PriceItem name={prices[13].name} price={prices[13].price} />
                <PriceItem name={prices[14].name} price={prices[14].price} />
                <PriceItem name={prices[15].name} price={prices[15].price} />
              </div>
              <div className="tab-inner_col">
                <PriceItem name={prices[16].name} price={prices[16].price} />
                <PriceItem name={prices[17].name} price={prices[17].price} />
                <PriceItem name={prices[18].name} price={prices[18].price} />
                <PriceItem name={prices[19].name} price={prices[19].price} />
                <PriceItem name={prices[20].name} price={prices[20].price} />
                <PriceItem name={prices[21].name} price={prices[21].price} />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="tab-tablet_header">
              <h2>{priceText_001[lang]}</h2>
              <p>
                {priceText_02[lang]}
              </p>
            </div>
            <div className="tab-inner" id="tab-2">
              <div className="tab-inner_col ironed_tab">
                <PriceItem
                  name={secondTab_01[lang]}
                  price="€ 24.95"
                />
                <PriceItem name={secondTab_02[lang]} price="€ 4.25" />
              </div>
            </div>
          </TabPanel>
        </div>
      </section>
    </Tabs>
  );
};

const PriceItem = props => {
  return (
    <p>
      <span>{props.name}</span>
      <span>{props.price}</span>
    </p>
  );
};

export default PriceBlock;
