import React, { Component } from 'react'
import Modal from 'react-modal';
import { customStyles } from '../pages/Home'
import { modalHelper } from "../utils/helpers";

import '../css/modals/Modal.css'
import Api from "../utils/api/Api";

const translations = {
  wrong01: {
    en: "Aww, shucks! We can’t come to your door just yet",
    nl: "Hè, sorry! Jouw deur is helaas nog buiten bereik"
  },
  wrong02: {
    en: "Leave your email address and we’ll let you know when dobbi is available for you.",
    nl: "Laat je e-mailadres achter, dan krijg je een mailtje zodra dobbi voor jou beschikbaar is."
  },
  wrong03: {
    en: "I agree to receive an email when dobbi is available in my zip code area",
    nl: "Ik ontvang graag een e-mail wanneer dobbi beschikbaar is in mijn postcode gebied"
  },
  wrong04: {
    en: "Submit",
    nl: "Inschrijven"
  },
};

const { wrong01, wrong02, wrong03, wrong04 } = translations;

export class ModalWrongZip extends Component {
  state = {
    isOpen: false,
    checked: false,
    validEmail: false
  }

  closeModal = (e) => this.setState({ isOpen: false }, () => this.props.closeModal())

  handleToggleCheck = () => this.setState({ checked: !this.state.checked });

  handleSubscribe = (e) => {
    let
      houseAddition = this.props.houseAddition && 0 !== this.props.houseAddition.length ? this.props.houseAddition : null,
      houseNumber   = this.props.houseNumber && 0 !== this.props.houseNumber.length ? this.props.houseNumber : null
    ;

    let data = {
      'postalCode': this.props.zip,
      'email': document.getElementsByName("email")[0].value,
      'houseAddition': houseAddition,
      'houseNumber': houseNumber,
      'reason': this.props.reason
    };

    const condition = this.state.validEmail && this.state.checked;

    condition && Api.createServiceAreaRequest(data)
      .then(response => {
        this.setState({
          isOpen: false,
          validEmail: false,
          checked: false
        }, () => modalHelper())
        this.props.handleOpenThankModal();
      })
  }

  validateEmail = () => {
    let email = document.getElementsByName("email")[0].value;
    // eslint-disable-next-line
    this.setState({ validEmail: !!email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) });
  }

  componentWillReceiveProps(nextProps) {
    nextProps.isOpen && this.setState({ isOpen: true }, () => modalHelper())
  }

  render() {
    const buttonClassName = (this.state.checked && this.state.validEmail) ? 'checked' : '';
    const lang = this.props.lang;
    const el = document.querySelector("#root");
    return (
      this.state.isOpen && <div className="modal-wrapper">
        <Modal
          isOpen={this.state.isOpen}
          onRequestClose={this.closeModal}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          appElement={el}
          style={customStyles}
        >
          <button onClick={this.closeModal} className="close-button">&#10005;</button>
          <div className="modal-wrapper">
            <div className="modal-inner modal-full">
              <h2>{wrong01[lang]}</h2>
              <p>{wrong02[lang]}</p>
              <input
                id="window-out-of-service-input-email"
                type="email"
                className="email-input"
                required
                onInput={this.validateEmail}
                name="email"
                placeholder="your email address"
                autoComplete="off"
              />
              <div className="checkbox-input">
                <input
                  id="window-out-of-service-checkbox"
                  type="checkbox"
                />
                <label htmlFor="window-out-of-service-checkbox" onClick={this.handleToggleCheck}>{wrong03[lang]}</label>
              </div>
              <button
                id="window-out-of-service-button"
                className={`check-form-submit ${buttonClassName}`}
                onClick={this.handleSubscribe}
              >
                {wrong04[lang]}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
