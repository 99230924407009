import React from 'react';
import classNames from 'classnames';

import Header from '../../../components/Header';

import heroImg from '../../../images/home-banner.jpg';
import heroImg2x from '../../../images/home-banner@2x.jpg';
import {compose} from "redux";
import {connect} from "react-redux";

const AuthWrapper = props => {

  const className = classNames({
    'order-block': true,
  });

  return (
    <div className="order-page-wrapper recover-page-wrapper">
      <Header
        wv={props.wv}
        heroImg={heroImg}
        heroImg2x={heroImg2x}
        whiteHeader
      />
      <main className="main-order-block user-recover">
        <section className={className}>{props.children}</section>
      </main>
    </div>
  );
};

const mapStateToProps = ({ webView }) => ({
  wv: webView,
});

export default compose(
  connect(
    mapStateToProps
  )
)(AuthWrapper);
