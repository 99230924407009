import { createMuiTheme } from '@material-ui/core'

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xss: 0,
      xs: 320,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1200,
    },
  },
})