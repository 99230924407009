import React from 'react'
import Modal from 'react-modal';
import { customStyles } from '../pages/Home'
import '../css/modals/Modal.css'
import qr from './images/qr.png';
import { AppLinks } from '../components/AppLinks'

const translations = {
  correct01: {
    en: "Yes! Your location is within our reach",
    nl: "Jippie! dobbi is bij jouw in de buurt actief"
  },
  correct02: {
    en: "Download our app now and receive your own laundry or suit bag with your first order",
    nl: "Download de app en plaats je eerste order."
  },
  correct03: {
    en: "Phone at hand?",
    nl: "Telefoon bij de hand?"
  },
  correct04: {
    en: "Scan & download dobbi",
    nl: "Scan & download dobbi"
  },
}

const { correct01, correct02, correct03, correct04 } = translations;

export const ModalCorrect = props => {
  const el = document.querySelector("#root");
  const lang = props.lang;

  return (
    props.isOpen && <div className="modal-wrapper">
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        appElement={el}
        style={customStyles}
      >
        <button onClick={props.closeModal} className="close-button">&#10005;</button>
        <div className="modal-wrapper">
          <div className="modal-inner">
            <div >
              <h2>{correct01[lang]}</h2>
              <p>{correct02[lang]}</p>
              <AppLinks />
            </div>
            <div className="devider" />
            <div className="modal-inner-second">
              <div className="qr-wrapper">
                <img className="modal-qr" src={qr} alt="" />
              </div>
              <div className="modal-bottom-text">
                <p className="modal-text-bold">{correct03[lang]}</p>
                <p className="modal-text">{correct04[lang]}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
