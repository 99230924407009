import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import Header from "../components/Header";

import Logo from "../images/logo-green.svg";
import ErrorImg from "../images/404.svg";
import "../css/pages/Error404.css";


const translations = {
  title: {
    en: "Looks like you’re lost",
    nl: "Hier lijkt niks te vinden"
  },
  descr: {
    en: "The page you are looking for not avaible!",
    nl: "De pagina die jij zoekt bestaat niet!"
  },
}
const { title, descr } = translations;

const Error404 = props => {
  const lang = localStorage.getItem('lang')

  return (
    <div className="order-page-wrapper error-page-wrapper">
      <Header wv={props.wv} logo={Logo} whiteHeader />
      <main className="main-order-block">
        <section className="order-block">
          <div className="error-block">
            <div className="error-logo">
              <img src={ErrorImg} alt="" />
            </div>

            <div className="error-text">
              <h1>{title[lang]}</h1>
              <p>{descr[lang]}</p>
            </div>
          </div>
        </section>
      </main>
      {/*<Footer wv={props.wv} />*/}
    </div>
  );
};

const mapStateToProps = ({ webView }) => ({ wv: webView });

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Error404);
