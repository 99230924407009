import React from "react";
import { NavLink as Link } from "react-router-dom";

import { Logo } from "./Logo";
import NavLinks from "./NavLinks";
import van from "../images/van.svg";
import "../css/components/Footer.css";
import AccordionFooter from "./AccordionFooter";

import payment from "../images/payment.png";
import payment2x from "../images/payment@2x.png";
import secondPayment from "../images/home/second-payment.png";
import secondPayment2x from "../images/home/second-payment@2x.png";
import facebook from "../images/socials/fb.svg";
import linkedin from "../images/socials/ln.svg";
import instagram from "../images/socials/ig.svg";

const translation = {
  footerText_01: {
    en: "Skip your laundry",
    nl: "Skip your laundry"
  },
  footerText_02: {
    en: "Picked up, cleaned & delivered when it suits you Powered by Persil",
    nl:
      "Opgehaald, gereinigd & teruggebracht wanneer het jou uitkomt Powered by Persil"
  },
  footerText_03: {
    en: "Location",
    nl: "Locatie"
  },
  footerText_04: {
    en: "Joop van den Endeplein 1",
    nl: "Joop van den Endeplein 1"
  },
  footerText_05: {
    en: "1217 WJ Hilversum",
    nl: "1217 WJ Hilversum"
  },
  footerText_06: {
    en: "The Netherlands",
    nl: "Nederland"
  },
  footerText_07: {
    en: "Customer care",
    nl: "Klantenservice"
  },
  footerText_08: {
    en: "Phone: +31 85 01 875 40",
    nl: "Telefoon: +31 85 01 875 40"
  },
  footerText_09: {
    en: "Email: info@dobbi.com",
    nl: "Email: info@dobbi.com"
  },
  footerText_10: {
    en: "Monday to Friday:",
    nl: "Maandag tot vrijdag:"
  },
  footerText_11: {
    en: "08:30 – 21:00",
    nl: "08:30 – 21:00"
  },
  footerText_12: {
    en: "Saturday:",
    nl: "Zaterdag"
  },
  footerText_13: {
    en: "09:00 – 17:00",
    nl: "09:00 – 17:00"
  },
  footerText_14: {
    en: "Clean Clothes B.V. – dobbi",
    nl: "Clean Clothes B.V. – dobbi"
  },
  footerText_15: {
    en: "IBAN: NL44ABNA0814405754",
    nl: "IBAN: NL44ABNA0814405754"
  },
  footerText_16: {
    en: "KvK: 70098778",
    nl: "KvK: 70098778"
  },
  footerText_17: {
    en: "BTW: 858140901B01",
    nl: "BTW: 858140901B01"
  },
  footerText_18: {
    en: "Home",
    nl: "Home"
  },
  footerText_19: {
    en: "Pricelist",
    nl: "Prijslijst"
  },
  footerText_20: {
    en: "Explore dobbi",
    nl: "Ontdek dobbi"
  },
  footerText_21: {
    en: "FAQ",
    nl: "FAQ"
  },
  footerText_22: {
    en: "Contact",
    nl: "Contact"
  },
  footerText_23: {
    en: "Terms & conditions",
    nl: "Algemene voorwaarden"
  },
  footerText_24: {
    en: "Phone:",
    nl: "Telefoon:"
  },
  footerText_25: {
    en: "Jobs",
    nl: "Vacatures"
  }
};

const {
  footerText_01,
  footerText_02,
  footerText_03,
  footerText_04,
  footerText_05,
  footerText_06,
  footerText_07,
  footerText_08,
  footerText_09,
  footerText_10,
  footerText_11,
  footerText_12,
  footerText_13,
  footerText_14,
  footerText_15,
  footerText_16,
  footerText_17,
  footerText_23,
  footerText_25
} = translation;

const Footer = props => {
  const lang = localStorage.getItem("lang");
  const cookieLink = lang === "nl" ? "/nl/cookie" : "/en/cookie";
  const termsLink = lang === "nl" ? "/nl/terms" : "/en/terms";
  const jobsLink = lang === "nl" ? "/nl/jobs" : "/en/jobs";

  return (
    <div className="footer-wrapper">
      <div className="footer-curves">
        <div className="bubbles" />
      </div>
      <footer>
        <div className="logo-container">
          <Logo />
          <img src={van} alt="" className="dobbi-van" width="70" height="38" />
        </div>
        <section className="footer-text">
          <div className="footer-block footer-payment">
            <p className="footer-desc">
              {footerText_01[lang]} <br />
              {footerText_02[lang]}
            </p>
            <img
              className="payment-icons"
              src={payment}
              srcSet={`${payment} 1x, ${payment2x} 2x`}
              alt=""
            />
            <img
              style={{ marginTop: 30 }}
              className="payment-icons"
              src={secondPayment}
              srcSet={`${secondPayment} 1x, ${secondPayment2x} 2x`}
              alt=""
            />
          </div>
          <DesktopFooter />
          <AccordionFooter translation={translation} />
          <div className="footer-block footer-block_numbers">
            <span>{footerText_07[lang]}</span> <br />
            {footerText_08[lang]} <br />
            {footerText_09[lang]} <br />
            <br />
            {footerText_10[lang]} <br />
            {footerText_11[lang]} <br />
            {footerText_12[lang]} <br />
            {footerText_13[lang]}
            <div className="footer-socials">
              <a
                href="https://www.facebook.com/dobbiNL/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/dobbi-nl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="" />
              </a>
              <a
                href="https://www.instagram.com/dobbi_nl/?hl=nl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="" />
              </a>
            </div>
          </div>
          {props.wv && (
            <div className="footer-block footer-block__nav">
              <NavLinks>
                <Link to={termsLink}>{footerText_23[lang]}</Link>
                <Link to={cookieLink}>Cookie & Privacy statement</Link>
                <Link to={jobsLink}>{footerText_25[lang]}</Link>
              </NavLinks>
            </div>
          )}
        </section>
      </footer>
    </div>
  );
};

const DesktopFooter = props => {
  const lang = localStorage.getItem("lang");

  return (
    <div className="desktop__foter">
      <div className="footer-block">
        <span>{footerText_03[lang]}</span> <br />
        {footerText_04[lang]} <br />
        {footerText_05[lang]} <br />
        {footerText_06[lang]} <br />
        {footerText_14[lang]}
      </div>

      <div className="footer-block">
        {footerText_15[lang]} <br />
        {footerText_16[lang]} <br />
        {footerText_17[lang]}
      </div>
    </div>
  );
};

export default Footer;
