import pathToRegexp from 'path-to-regexp';

export const ROUTE_NAME = {
  AUTH_TOKEN: 'auth.token',
  AUTH_REFRESH_TOKEN: 'auth.refresh.token',

  RECOVER_ACCOUNT_EXISTS: 'recover.account.exists',
};

// Register routes
export const apiRoutes = {
  AUTH_TOKEN: '/tokens',
  AUTH_REFRESH_TOKEN: '/tokens/refresh',
  USER_EXISTS: '/users/exists',
  ME: '/me',
  ME_LOCATIONS: '/me/locations',
  PUBLIC_COMPANIES: '/companies/public',
  COMPANY_TOKEN: '/companies/:id/token',
  RECOVER: '/recover',
  RECOVER_EMAIL: '/recover/email',
  RECOVER_CHECK_TOKEN: '/recover/check/token',
  RECOVER_CHECK_EMAIL: '/recover/check/email',
  SERVICE_POINT_PICKUP_WINDOWS: '/times/b2b/available/:code/:type',
  SERVICE_POINT_DELIVERY_WINDOWS: '/times/b2b/available/:code/:type/:pickup',
  TIME_WINDOW_PICKUP: '/times/pickups?postalCode=:postalCode&city=:city&thoroughfare=:thoroughfare&subThoroughfare=:subThoroughfare&isNew=:isNew',
  TIME_WINDOW_DELIVERY: '/times/deliveries?postalCode=:postalCode&city=:city&thoroughfare=:thoroughfare&subThoroughfare=:subThoroughfare&isNew=:isNew',
  SERVICE_AREA_CHECK: '/service-areas/?postalCode=:postalCode',
  SERVICE_POINT_VIEW: '/service-points/by-code/:code',
  SERVICE_POINT_B2B_VIEW: '/service-points/b2b/by-code/:code',
  PLACE_NO_TERMINAL_ORDER: '/orders/retail/no-terminal',
  PLACE_COLLECTION_ORDER: '/order-collections',
  PLACE_NO_TERMINAL_MANUAL_ORDER: '/orders/b2b',
  SERVICE_AREA_REQUEST: '/service-areas/request',
  ORDERS: '/orders',
  CLEANING_SERVICES: '/cleaning-services',
  CLEANING_SERVICES_CONFIG: '/config',
  SHIPMENT_LABELS_BLS: '/shipment-labels/bls/:code',
  SERVICES: '/services'
};

export const getBaseUrl = () => {
  const PRODUCTION = "order.dobbi.com";
  const STAGING = "order-staging.dobbi.com";
  const LOCAL = 'order.dobbi.local';

  switch (window.location.hostname) {
    case PRODUCTION:
      return process.env.REACT_APP_API_URL;
    case STAGING:
      return process.env.REACT_APP_DEV_API_URL;
    case LOCAL:
      return process.env.REACT_APP_LOCAL_API_URL;
    default:
      return process.env.REACT_APP_DEV_API_URL;
  }
};

export const getApiRoute = (name, params = {}) => pathToRegexp.compile(apiRoutes[name])(params);
