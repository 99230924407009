import React from "react";

import bag from "../images/home-bag.gif";
import bag2x from "../images/home-bag@2x.gif";
import dobbi_bubles from "../images/dobbi_bubles.svg";

import logo1 from '../images/about-imgs/logo1.png'
import logo1x2 from '../images/about-imgs/logo1@2x.png'
import logo2 from '../images/about-imgs/logo2.png'
import logo2x2 from '../images/about-imgs/logo2@2x.png'
import logo3 from '../images/about-imgs/logo3.png'
import logo3x2 from '../images/about-imgs/logo3@2x.png'
import logo4 from '../images/about-imgs/logo4.png'
import logo4x2 from '../images/about-imgs/logo4@2x.png'

import "../css/components/AboutDobbi.css";

const translation = {
  aboutText_01: { en: "Why you’ll love dobbi", nl: "Hierom kies je voor dobbi" },
  aboutText_02: {
    en: <span><b>Skip your laundry day</b><br />
      We do the washing folding & ironing for you</span>,
    nl: <span>
      <b>Skip je wasdag</b> <br />
      Wij wassen, vouwen en strijken voor jou
    </span>
  },
  aboutText_03: {
    en: <span>
      <b>Skip the trip to the dry cleaner</b><br />
      We pickup, clean & deliver within 48 hours
    </span>,
    nl: <span>
      <b>Skip je trip naar de stomerij</b><br />
      Wij halen je kleding op en bezorgen het binnen 48 uur schoon terug
    </span>
  },
  aboutText_04: {
    en: <span>
      <b>Skip opening hours</b><br />
      We pickup at your door within a 2 hour timeslot between 8 AM and 8 PM
    </span>,
    nl: <span>
      <b>Skip openingstijden</b><br />
      Wij komen bij je aan de deur in een 2 uurs tijdvak van 8:00 uur tot 20:00 uur
    </span>
  },
  aboutText_05: {
    en: <span>
      <b>Skip your stains & creases</b><br />
      We work with the best and certified dry cleaners
    </span>,
    nl:
      <span>
        <b>Skip kreukels & vlekken</b><br />
        Wij werken met de beste textielreinigers van Nederland
      </span>
  },
}

const { aboutText_01, aboutText_02, aboutText_03, aboutText_04, aboutText_05 } = translation

const AboutDobbi = props => {
  const lang = localStorage.getItem("lang");
  return (
    <section className="about-dobbi">
      <div className="about-dobbi_content">
        <div className="about-text">
          <h1>{aboutText_01[lang]}</h1>
          <p>{aboutText_02[lang]}</p>
          <p>{aboutText_03[lang]}</p>
          <p>{aboutText_04[lang]}</p>
          <p>{aboutText_05[lang]}</p>
          <div className="about-logos-wrapper">
            <div className="about-logos">
              <div className="about-item"><img src={logo1} srcSet={`${logo1} 1x, ${logo1x2} 2x`} alt="" /></div>
              <div className="about-item"><img src={logo2} srcSet={`${logo2} 1x, ${logo2x2} 2x`} alt="" /></div>
              <div className="about-item"><img src={logo3} srcSet={`${logo3} 1x, ${logo3x2} 2x`} alt="" /></div>
              <div className="about-item"><img src={logo4} srcSet={`${logo4} 1x, ${logo4x2} 2x`} alt="" /></div>
            </div>
          </div>

        </div>
        <img
          src={bag}
          srcSet={`${bag} 1x, ${bag2x} 2x`}
          alt=""
          className="feature-image"
        />
      </div>
      <img className="dobbi_bubbles" src={dobbi_bubles} alt="" />
    </section>
  );
};

export default AboutDobbi;
