import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import DropdownList from "./DropdownList";
import PickupOrderHours from "./PickupOrderHours";
import DeliveryOrderHours from "./DeliveryOrderHours";
import SelectDateModal from "./SelectDateModal";
import MobileSelectButton from "./MobileSelectButton";
import { setAvaiblePickupData, setApiToken, pickupDataLoading } from "../../../../redux/actions";
import translation from '../../../../translations/SecondsStep'
import Api from "../../../../utils/api/Api";


class WindowSelector extends React.Component {
  state = {
    pickupModal: false,
    deliveryModal: false
  };

  async componentDidMount() {
    const { setAvaiblePickupData, pickupDataLoading, avaliablePickupDate, code } = this.props;

    try { window.dataLayer.push({ event: 'select-time' }) } catch (err) { console.log("Error", err) }

    if (avaliablePickupDate[0].default) {
      try {
        pickupDataLoading(true);
        const pickupResponse = await Api.getServicePointPickupWindows(code, 'pickup');
        const pickup = pickupResponse.data;
        setAvaiblePickupData(pickup);
        pickupDataLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
  }

  handlePickupModalToggle = () => {
    this.setState({ pickupModal: !this.state.pickupModal });
  }

  handleDeliveryModalToggle = () => {
    const { selectedPickupDate } = this.props;
    if (selectedPickupDate.default) return;
    this.setState({ deliveryModal: !this.state.deliveryModal });
  };

  render() {
    const lang = localStorage.getItem("lang");
    const { pickupModal, deliveryModal } = this.state;
    return (
      <div className="second-option-block">
        <h2>{translation.time[lang]}</h2>
        <div className="second-option-desktop">
          <div className="dropdown-blocs">
            <DropdownList
              pickup
              code={this.props.code}
              onPickupSelected={this.props.onPickupSelected}
              onDeliverySelected={this.props.onDeliverySelected}
            />
            <DropdownList
              delivery
              code={this.props.code}
              onPickupSelected={this.props.onPickupSelected}
              onDeliverySelected={this.props.onDeliverySelected}
            />
          </div>
          <div className="avaliable-times">
            <PickupOrderHours
              pickup
              code={this.props.code}
              onPickupSelected={this.props.onPickupSelected}
              onDeliverySelected={this.props.onDeliverySelected}
            />
            <DeliveryOrderHours
              delivery
              code={this.props.code}
              onPickupSelected={this.props.onPickupSelected}
              onDeliverySelected={this.props.onDeliverySelected}
            />
          </div>
        </div>
        <div className="second-option-mobile">
          <MobileSelectButton
            pickup
            title={translation.pickUp[lang]}
            onClick={this.handlePickupModalToggle}
            code={this.props.code}
          />
          <MobileSelectButton
            delivery
            title={translation.delivery[lang]}
            onClick={this.handleDeliveryModalToggle}
            code={this.props.code}
          />
          <SelectDateModal
            pickup
            isOpened={pickupModal}
            handleToggleModal={this.handlePickupModalToggle}
            code={this.props.code}
            onPickupSelected={this.props.onPickupSelected}
            onDeliverySelected={this.props.onDeliverySelected}
          />
          <SelectDateModal
            delivery
            isOpened={deliveryModal}
            handleToggleModal={this.handleDeliveryModalToggle}
            code={this.props.code}
            onPickupSelected={this.props.onPickupSelected}
            onDeliverySelected={this.props.onDeliverySelected}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  selectedPickupDate,
  selectedDeliveryDate,
  avaliableDeliveryDate,
  avaliablePickupDate,
  userLocation,
  hasBag,
  apiToken,
  pickupDataLoading
}) => ({
  selectedPickupDate,
  selectedDeliveryDate,
  avaliableDeliveryDate,
  avaliablePickupDate,
  userLocation,
  hasBag,
  apiToken,
  pickupDataLoading
});

const mapDispatchToProps = dispatch => {
  return {
    setAvaiblePickupData: data => dispatch(setAvaiblePickupData(data)),
    setApiToken: data => dispatch(setApiToken(data)),
    pickupDataLoading: data => dispatch(pickupDataLoading(data))
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(WindowSelector);
