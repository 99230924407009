import React, { useState, useEffect } from "react";

import translation from "../../../../translations/FirstOrderScreen"
import Select from 'react-select'
import Loader from 'react-loader-spinner'
import Api from '../../../../utils/api/Api'

import "./CompanySelect.scss";
import { userCreateSession, userLoadData } from '../../../../utils/helpers'

export const CompanySelect = ({onLogin}) => {
  const lang = localStorage.getItem("lang")
  const [options, setOptions] = useState(null)

  useEffect(() => {
    Api.listCompanies()
      .then(({ data }) => {
        setOptions(data.data.map(company => ({ value: company.id, label: company.firstName })))
      })
    ;
  }, [onLogin])

  const login = (option) => {
    Api.loginAsCompany(option.value)
      .then(async ({ data }) => {
        let token = data.token;

        await userCreateSession(token, data.refresh);
        await userLoadData(
          () => {
            localStorage.setItem('entryPoint', 're-use')
            onLogin(token);
          },
          () => {
            if (this.button.current) {
              this.button.current.enable();
            }
          },
          true
        );
      }).catch(() => {
      this.props.setNotificationData({ status: true, message: translation.error[this.lang] });
      this.button.current.enable();

      setTimeout(() => {
        this.props.setNotificationData({ status: false, message: '' })
      }, 5000);
    })
  }

  return(
    <div>
      <div>
        <h1>{translation.companySelect[lang]}</h1>
        { options &&
          <Select
            placeholder={translation.companySelectPlaceholder[lang]}
            options={options}
            onChange={login}
          />
        }
        { !options &&
          <Loader
            className="select-loader"
            type="Grid"
            color="#5bffc6"
            height={100}
            width={100}
            required
          />
        }
      </div>
    </div>
  )
}