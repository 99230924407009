import React from "react";

import { Login } from "../../../../components/auth/Login";
import translation from "../../../../translations/FirstOrderScreen";
import {
  addUserData
} from "../../../../redux/actions";
import { compose } from "redux";
import { connect } from "react-redux";

class UserLogin extends React.Component {
  constructor(props) {
    super(props);

    this.lang = localStorage.getItem("lang")
  }

  render() {
    return (
      <div>
        <div style={{ minHeight: 90 }}>
          <h1>{translation.existingAccount[this.lang]}</h1>
          <p>{translation.existingAccountDesc[this.lang]}</p>
        </div>
        <Login {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = ({ userLocation, userData }) => ({
  userLocation,
  userData,
});

const mapDispatchToProps = dispatch => {
  return {
    addUserData: data => dispatch(addUserData(data)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserLogin);