import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import translation from '../../../translations/Auth'
import { Button } from "../../../components/Button";
import { userCreateSession, userLoadData } from "../../../utils/helpers";
import Api from "../../../utils/api/Api";

export class RecoverPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      valid: false
    };

    this.button = React.createRef();
    this.notification = props.notification;
    this.lang = localStorage.getItem("lang")
  }

  setPassword = (e) => {
    let password = e.target.value;

    this.setState({
      password: password,
      valid: 4 <= password.length
    })
  };

  sendPassword = async () => {
    Api.sendRecoverPassword(this.state.password, this.props.token)
      .then(async ({ data }) => {
        let token = data.token;

        await userCreateSession(token, data.refresh);
        await userLoadData(
          () => {
            this.props.history.push('/' + this.lang + '/?page=address-selection' );
          },
          () => {
            this.button.current.enable();
          }
        );
      })
      .catch((err) => {
        this.button.current.enable();
      })
    ;
  };

  render() {
    return(
      <div className="user-recover-password">
        <div style={{minHeight: 90}}>
          <h1>{translation.passwordBlockTitle[this.lang]}</h1>
          <p>{translation.passwordBlockText[this.lang]}</p>
        </div>
        <div>
          <input
            id="recover-input-password"
            type="password"
            name="password"
            placeholder={ translation.passwordPlaceholder[this.lang] }
            value={ this.state.password }
            onChange={ this.setPassword }
            required
          />
          <Button
            id="recover-button-set-email"
            color="green"
            className={ this.state.valid ? 'checked' : '' }
            onClick={ this.sendPassword }
            disabled={ !this.state.valid }
            ref={this.button}
          >
            {translation.passwordBlockButton[this.lang]}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ notification, webView }) => ({
  notification,
  webView
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(RecoverPassword);
