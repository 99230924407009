import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import Header from "../components/Header";
import Footer from "../components/Footer";

import PriceBlock from "../components/PriceBlock";
import DownloadApp from "../components/DownloadApp";

import heroImg from "../images/home-banner.jpg";
import heroImg2x from "../images/home-banner@2x.jpg";

import "../css/pages/Pricelist.css";

const Pricelist = props => {
  return (
    <div className="pricelist-page-wrapper">
      <Header wv={props.wv} heroImg={heroImg} heroImg2x={heroImg2x} />
      <main>
        <PriceBlock />
        {props.wv && <DownloadApp />}
      </main>
      <Footer wv={props.wv} />
    </div>
  );
};

const mapStateToProps = ({ webView }) => ({ wv: webView });

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Pricelist);
