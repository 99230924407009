import React from "react";

import logoWhite from "../images/dobbi-logo.svg";
import logoGreen from "../images/logo-green.svg";

const homeLink = { en: 'https://dobbi.com/en/', nl: 'https://dobbi.com/nl/' }

export const Logo = props => {
  const lang = localStorage.getItem("lang");
  const logo = props.whiteHeader ? logoGreen : logoWhite
  return !props.wv ? (
    // eslint-disable-next-line
    <a className="logo-container">
      <img src={props.logo || logo} alt="" className="dobbi-logo" />
    </a>
  ) : (
      <a className="logo-container" href={homeLink[lang]}>
        <img src={props.logo || logo} alt="" className="dobbi-logo" />
      </a>
    );
};
