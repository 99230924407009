export default {
  title: {
    'en': 'dobbi',
    'nl': 'dobbi',
  },
  text: {
    'en': 'Install the app for the best user experience',
    'nl': 'Installeer de app voor de beste gebruikerservaring'
  },
  button: {
    'en': 'INSTALL',
    'nl': 'INSTALLEER'
  }
}