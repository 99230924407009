import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import OrderBlockHeader from './components/OrderBlockHeader';
import OrderWrapper from './components/OrderWrapper';
import FirstOrderScreen from './FirstOrderScreen';
import ThirdOrderScreen from './ThirdOrderScreen';
import FourthOrderScreen from './FourthOrderScreen';
import './Order.scss'

const ReUseOrder = props => {
  const orderScreens = () => {
    return {
      1: <FirstOrderScreen />,
      3: <ThirdOrderScreen />,
      4: <FourthOrderScreen />,
    };
  };

  const orderScreensList = orderScreens();
  const { currentOrderScreen } = props;
  const conditionToHide = currentOrderScreen === 4;

  return (
    <OrderWrapper {...props}>
      {!conditionToHide && (
        <OrderBlockHeader checkout={currentOrderScreen !== 1} />
      )}
      {orderScreensList[currentOrderScreen]}
    </OrderWrapper>
  );
};

const mapStateToProps = ({ webView, currentOrderScreen }) => ({
  wv: webView,
  currentOrderScreen,
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(ReUseOrder);
