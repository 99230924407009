import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import "../css/components/Accordion.css";
import { translation } from "../utils/dataFiles/jobs-loco";
import { VacancyDescriprion } from "../utils/dataFiles/vacancy";

const { firstTab_01, firstTab_02, firstTab_03, firstTab_04 } = translation;
const { secondTab_01 } = translation;

export const AccordionBlockFirst = props => {
  const lang = localStorage.getItem("lang");

  return (
    <Accordion className="accodrion-block">
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{firstTab_01[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <VacancyDescriprion />
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{firstTab_02[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>

        <AccordionItemPanel>
          <VacancyDescriprion />
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{firstTab_03[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>

        <AccordionItemPanel>
          <VacancyDescriprion />
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{firstTab_04[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>

        <AccordionItemPanel>
          <VacancyDescriprion />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export const AccordionBlockSecond = props => {
  const lang = localStorage.getItem("lang");

  return (
    <Accordion className="accodrion-block">
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{secondTab_01[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <VacancyDescriprion />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};
