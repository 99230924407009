import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { langHelper } from "../../utils/helpers";

import heroImg from "../../images/pricelist-banner.jpg";
import heroImg2x from "../../images/pricelist-banner@2x.jpg";
import "../../css/pages/stripped_pages/Confirm.css";

const translate = {
  welcome_01: {
    en: "Welcome to Dobbi",
    nl: "Welkom bij dobbi"
  },
  welcome_02: {
    en: "Your phone number is successfully verified and you'll receive your starterskit soon!",
    nl: "Je telefoonnummer is gevalideerd je ontvangt je starterkit snel!"
  }
}
const { welcome_01, welcome_02 } = translate;

const Welcome = props => {
  const lang = langHelper(props);

  return (
    <div className="confirm-page-wrapper">
      {props.wv && (
        <Header wv={props.wv} heroImg={heroImg} heroImg2x={heroImg2x} />
      )}
      <main className="confirm-main">
        <h1>{welcome_01[lang]}</h1>
        <p>
          {welcome_02[lang]}
        </p>
      </main>
      <Footer wv={props.wv} />
    </div>
  );
};

const mapStateToProps = ({ webView }) => ({ wv: webView });

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Welcome);
