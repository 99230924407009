import { createStore } from "redux";
import { appReducer } from "./reducers";

export const store = createStore(
  appReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// store.subscribe(async () => {
//   let reduxSession = store.getState().session;
//   const savedSession = storageGetUserSession();
//
//   if (JSON.stringify(reduxSession) !== JSON.stringify(storageGetUserSession(savedSession))) {
//     console.log('New session:');
//     console.log(reduxSession);
//
//     await storageUpdateUserSession(reduxSession);
//   }
// });
