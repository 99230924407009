import React from "react";

import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import {
  AccordionBlockFirst,
  AccordionBlockSecond,
  AccordionBlockThird,
  AccordionBlockFourth
} from "./Accordion";

import { translation } from '../utils/dataFiles/faqLoco';

import "react-web-tabs/dist/react-web-tabs.css";
import "../css/components/Tabs.css";


const { tab_01, tab_02, tab_03, tab_04 } = translation;

export const TabsBlock = props => {
  const lang = localStorage.getItem("lang");

  return (
    <Tabs
      className="faq-tabs__block tabs-section"
      defaultTab="vertical-tab-one"
      vertical
    >
      <TabList>
        <Tab className="custom-tab custom-tab-one" tabFor="vertical-tab-one">
          {tab_01[lang]}
        </Tab>
        <Tab className="custom-tab custom-tab-two" tabFor="vertical-tab-two">
          {tab_02[lang]}
        </Tab>
        <Tab
          className="custom-tab custom-tab-three"
          tabFor="vertical-tab-three"
        >
          {tab_03[lang]}
        </Tab>
        <Tab className="custom-tab custom-tab-four" tabFor="vertical-tab-four">
          {tab_04[lang]}
        </Tab>
      </TabList>
      <TabPanel className="tabs-inner" tabId="vertical-tab-one">
        <AccordionBlockFirst />
      </TabPanel>
      <TabPanel className="tabs-inner" tabId="vertical-tab-two">
        <AccordionBlockSecond />
      </TabPanel>
      <TabPanel className="tabs-inner" tabId="vertical-tab-three">
        <AccordionBlockThird />
      </TabPanel>
      <TabPanel className="tabs-inner" tabId="vertical-tab-four">
        <AccordionBlockFourth />
      </TabPanel>
    </Tabs>
    );
};
