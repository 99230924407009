import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
import {
  AccordionBlockFirst,
  AccordionBlockSecond,
  AccordionBlockThird,
  AccordionBlockFourth
} from "./Accordion";
import { translation } from '../utils/dataFiles/faqLoco';
import "react-accessible-accordion/dist/fancy-example.css";
import "../css/components/MobileTabs.css";

const { tab_01, tab_02, tab_03, tab_04 } = translation;

export const MobileTabs = props => {
  const lang = localStorage.getItem("lang");
  return (
    <Accordion className="faq-mobile-tabs__block accodrion-block">
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{tab_01[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <AccordionBlockFirst />
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{tab_02[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <AccordionBlockSecond />
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{tab_03[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <AccordionBlockThird />
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{tab_04[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <AccordionBlockFourth />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}
