import React, { Component } from 'react'
import Modal from 'react-modal';
import '../css/modals/Modal.css'

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 770,
    borderRadius: "10px",
    boxShadow: "0 5px 42px 0 rgba(187,201,192,0.3)",
    overflowX: "scroll"
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,.3)',
    zIndex: 999
  }
};

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const cookiePopupCheck = () => {
  if (localStorage.getItem("cookiesSeen") === null) {
    return false;
  } else if (localStorage.getItem("cookiesSeen") === 'true') {
    return true;
  }
}

export class CoockieModal extends Component {
  state = {
    isOpen: true,
    extended: false
  }

  handleCheckbox = (e) => {
    const label = document.querySelector('.label-checkbox');
    label.closest('.checkBox').classList.toggle('active');
  }


  handleAccept = (e) => {
    this.setState({ isOpen: false })
    localStorage.setItem('cookiesSeen', true);
    setCookie("cookieConsent", true, 365)
    e && e.preventDefault();
  }

  closeModal = () => this.setState({ isOpen: false })

  componentWillReceiveProps(nextProps) {
    nextProps.isOpen && this.setState({ isOpen: true })
  }

  render() {
    const el = document.querySelector("#root");
    return (
      <Modal
        isOpen={this.state.isOpen}
        onRequestClose={this.closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        appElement={el}
        style={customStyles}
      >
        <button onClick={this.closeModal} className="close-button">&#10005;</button>
        <div className="modal-inner modal-coockie">
          <h2>dobbi cookie settings</h2>
          <p>We place Functional cookies in order for this website to function properly and Analytical cookies with which we can measure the use of the website. These cookies do not use personal data.<br />
            <span onClick={() => this.setState({ extended: !this.state.extended })}>More info and change cookie settings'</span>
          </p>
          {this.state.extended && <div className="extended-block">
            <div className="extend-item">
              <h2>About cookies</h2>
              <p>A cookie is a text file that contains a small amount of data, which is downloaded by the device that you use to access our website and is stored on its hard disk. These cookies have several uses, allowing our website to recognise your device when you visit again, ensuring you receive the correct content and save information regarding preferences or services that you have provided.</p>

            </div>
            <div className="extend-item">
              <h2>Change your preferences</h2>
              <p>Below, you can change the preferences of your browser to limit which cookies should allow access. Please note that, if you adjust the cookie settings, you may not be able to correctly use some parts of deptagency.com correctly.</p>
            </div>
            <div className="extend-item last">
              <div className="checkBoxs">
                <div className="checkBox active">
                  <input type="checkbox" id="checkbox-1" />
                  <label className="label-checkbox" htmlFor="checkbox-1" onClick={this.handleCheckbox}>
                    <span className="circle"></span>
                  </label>
                </div>
              </div>
              <p>Functional: Cookies that make sure the site works properly.</p>
            </div>
          </div>}
          <button className="accept-button checked" onClick={this.handleAccept}>Accept</button>
        </div>
      </Modal>
    )
  }
}
