import React from "react"

import "../../css/components/Button.css"

import white from "../../images/order/white-arrow.svg"
import blue  from "../../images/order/blue-arrow.svg"
import PropTypes from 'prop-types'

export const Button = ({className, color, arrow, submitting, onClick, ...props}) => {
  const classes = () => {
    let classes = ['button_basic'];

    if (color) {
      classes.push('button-' + color)
    }

    if (arrow) {
      classes.push('button_with-arrow');
    }

    return classes
  }

  return (
    <button
      {...props}
      className={classes().join(' ') + ' ' + className}
      disabled={ submitting }
      onClick={onClick}
    >
      <div className="title">{props.children}</div>
      {arrow &&
      <img src={'white' === arrow ? white : blue} alt=""/>
      }
    </button>
  )
}

Button.defaultProps = {
  className: 'button-default',
  arrow: null,
  submitting: false,
  color: null,
  onClick: () => {console.log('Click')}
}

Button.propTypes = {
  color: PropTypes.string,
  arrow: PropTypes.string,
  submitting: PropTypes.number,
  onClick: PropTypes.func,
}