import React from 'react'
import PropTypes from 'prop-types'
import IconBackspace from '@material-ui/icons/BackspaceSharp'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import NButton from './ui'
import useLongPress from '../hooks/useLongPress'
import Loader from 'react-loader-spinner'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import { numpadStyles } from '../styles'

const useStyles = makeStyles(() => numpadStyles)

const DisplayWrapper = ({ value, backspace, longPressBackspace, show, loading, enter }) => {
  const backspaceLongPress = useLongPress(longPressBackspace, 1000);
  const classes = useStyles()

  return (
    <>
      <Grid item xs={!loading && !show ? 12 : enter ? 8 : 10} md={!loading && !show ? 12 : enter ? 9 : 10}>
        <input role="display" value={value} readOnly autoFocus className={classes.input} />
      </Grid>
      <Grid item xs={enter ? 4 : 2} md={enter ? 3 : 2}>
        <div className={classes.buttonWrapper}>
          {show &&
          <NButton {...backspaceLongPress} onClick={backspace} className={classes.backspace}>
            <IconBackspace />
          </NButton>
          }
          {show && enter &&
          <NButton onClick={() => { enter(value) }} className={classes.backspace}>
            <ArrowForwardIcon />
          </NButton>
          }
        </div>
        {loading &&
        <Loader
          className="page-loader"
          type="Grid"
          color="#5bffc6"
          height={40}
          width={40}
        />
        }
      </Grid>
    </>
  )
}

DisplayWrapper.propTypes = {
  value: PropTypes.string.isRequired,
  backspace: PropTypes.func,
  longPressBackspace: PropTypes.func,
  show: PropTypes.bool,
  loading: PropTypes.bool,
};

DisplayWrapper.defaultProps = {
  backspace: () => {},
  longPressBackspace: () => {},
};

export default DisplayWrapper;
