import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import translation from '../../../translations/Auth'
import { Button } from "../../../components/Button";
import { validateEmail } from "../../../utils/helpers";
import { setNotificationData } from "../../../redux/actions";
import Api from "../../../utils/api/Api";

export class RecoverEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      valid: false
    };

    this.button = React.createRef();
    this.notification = props.notification;
    this.lang = localStorage.getItem("lang")
  }

  setEmail = (e) => {
    let email = e.target.value;

    this.setState({
      valid: validateEmail(email),
      email
    })
  };

  sendEmail = async () => {
    Api.sendRecoverEmail(this.state.email,)
      .then(({ data }) => {
        this.button.current.enable();
        this.props.setCooldown(data.cooldown);

        localStorage.setItem('recoverEmail', this.state.email);
      })
      .catch((err) => {
        let error = err.response.data;
        if ('undefined' !== error.type && 'user_recover_cooldown' === error.type && undefined !== error.extra.cooldown) {
          this.props.setCooldown(error.extra.cooldown);

          localStorage.setItem('recoverEmail', this.state.email);

          return false;
        }

        if ('undefined' !== error.title) {
          this.props.setNotificationData({ status: true, message: error.title });
        }

        this.button.current.enable();

        setTimeout(() => {
          const data = { status: false, message: '' };
          setNotificationData(data)
        }, 5000);
      })
    ;
  };

  render() {
    return(
      <div className="user-recover-email">
        <div style={{minHeight: 90}}>
          <h1>{translation.emailBlockTitle[this.lang]}</h1>
          <p>{translation.emailBlockText[this.lang]}</p>
        </div>
        <div>
          <input
            id="recover-input-email"
            type="email"
            name="email"
            placeholder={translation.emailPlaceholder[this.lang]}
            value={ this.state.email }
            onChange={ this.setEmail }
            required
          />
          <Button
            id="recover-button-send-email"
            color="green"
            className={ this.state.valid ? 'checked' : '' }
            onClick={ this.sendEmail }
            disabled={ !this.state.valid }
            ref={this.button}
          >
            {translation.emailBlockButton[this.lang]}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ notification, webView }) => ({
  notification,
  webView
});

const mapDispatchToProps = dispatch => {
  return {
    setNotificationData: data => dispatch(setNotificationData(data)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RecoverEmail);
