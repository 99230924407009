import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import {
  setAvaiblePickupData,
  setAvaibleDeliveryData,
  setSelectedPickupData,
  setSelectedDeliveryData,
  setSelectedDeliveryTime,
} from '../../../redux/actions';
import { getFirstDeliveryDate } from '../../../utils/helpers';

import classNames from 'classnames';
import arrow from '../../../images/order/dropdown-arrow.svg';

import translation from '../../../translations/DropdownList'
import Loader from "react-loader-spinner";

class DropdownList extends React.Component {
  state = {
    opened: false
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (
      this.container &&
      this.state.opened &&
      !this.container.contains(event.target)
    ) {
      this.setState({ opened: false });
    }
  };

  dateFormatter = timestamp => {
    const lang = localStorage.getItem("lang");
    const dateConstructor = new Date(timestamp);
    const options = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
    };
    const formattedData = dateConstructor.toLocaleDateString( lang === 'en' ? 'en-GB' : 'nl-NL' , options);
    return formattedData === 'Invalid Date'
      ? translation.selectDateAndTime[lang]
      : formattedData;
  };

  handleDropdownOpen = () => {
    const {
      delivery,
      pickup,
      avaliablePickupDate,
      avaliableDeliveryDate,
      selectedPickupTime,
      pickupDataLoading,
      deliveryDataLoading
    } = this.props;

    if (pickupDataLoading || deliveryDataLoading) {
      return false;
    }

    const condition = !!selectedPickupTime === '00:00';

    if (pickup && avaliablePickupDate[0].default) return;
    if (delivery && avaliableDeliveryDate[0].default && !condition) return;

    this.setState({ opened: !this.state.opened });
  };

  handleSelectDelivery = e => {
    const { setSelectedDeliveryData, setSelectedDeliveryTime } = this.props;
    const date = e.target.getAttribute('data-time');

    setSelectedDeliveryData({ date });
    setSelectedDeliveryTime('00:00');
  };

  handleSelectDate = e => {
    const {
      selectedDeliveryDate,
      setSelectedPickupData,
      setAvaibleDeliveryData,
      setSelectedDeliveryData,
    } = this.props;

    const date = e.target.getAttribute('data-time');

    if (!selectedDeliveryDate.default) {
      const deliveryData = { default: true, date: '' };
      setSelectedPickupData({ date });
      setSelectedDeliveryData(deliveryData);
    } else {
      const data = { date };
      setSelectedPickupData(data);
      const avaliableDeliveryData = [
        {
          default: true,
          date: getFirstDeliveryDate(2),
          hours: [],
        },
      ];
      setAvaibleDeliveryData(avaliableDeliveryData);
    }
  };

  render() {
    let pointer;
    const lang = localStorage.getItem("lang");
    const {
      pickup,
      avaliablePickupDate,
      avaliableDeliveryDate,
      selectedPickupDate,
      selectedDeliveryDate,
      pickupDataLoading,
      deliveryDataLoading
    } = this.props;

    const classContainer = {
      'dropdown-wrapper': true,
      'dropdown-disabled': true,
      'opened-dropdown': this.state.opened,
    };

    const classDropdown = classNames({
      'dropdown-list': true,
      'opened-list': this.state.opened,
    });

    if ((pickup && pickupDataLoading) || (!pickup && deliveryDataLoading)) {
      pointer =
        <Loader
          className="dropdown-loader"
          type="Oval"
          color="#5bffc6"
          height={25}
          width={25}
        />
      ;
    } else {
      pointer = <img src={arrow} alt="" />;
    }

    if ((pickup && !avaliablePickupDate[0].default) || (!pickup && !avaliableDeliveryDate[0].default)) {
      classContainer['dropdown-disabled'] = false;
    }

    const dropdownTitle = pickup ? translation.pickUpDate[lang] : translation.deliveryDate[lang];
    const dropdownDescr = pickup
      ? this.dateFormatter(selectedPickupDate.date)
      : this.dateFormatter(selectedDeliveryDate.date)
    ;

    return (
      <div
        id={pickup ? 'step-4-pickup-container' : 'step-4-delivery-container'}
        className={classNames(classContainer)}
        onClick={this.handleDropdownOpen}
        ref={ref => (this.container = ref)}
      >
        <span className="dropdown-title">{dropdownTitle}</span>
        <span>{dropdownDescr}</span>
        { pointer }
        <ul
          id={pickup ? 'step-4-pickup-dropdown' : 'step-4-delivery-dropdown'}
          className={classDropdown}
        >
          {pickup
            ? avaliablePickupDate.map(item => {
              return !item.default ? (
                <li
                  id={'step-4-pickup-li-date-' + item.date}
                  onClick={this.handleSelectDate}
                  key={uuid()}
                  data-time={item.date}
                >
                  {this.dateFormatter(item.date)}
                </li>
              ) : null;
            })
            : avaliableDeliveryDate.map(item => {
              return !item.default ? (
                <li
                  id={'step-4-delivery-li-date-' + item.date}
                  onClick={this.handleSelectDelivery}
                  key={uuid()}
                  data-time={item.date}
                >
                  {this.dateFormatter(item.date)}
                </li>
              ) : null;
            })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({
  setSelectedPickupTime,
  selectedDeliveryTime,
  selectedPickupDate,
  selectedDeliveryDate,
  avaliableDeliveryDate,
  avaliablePickupDate,
  pickupDataLoading,
  deliveryDataLoading,
  apiToken,
}) => ({
  setSelectedPickupTime,
  selectedDeliveryTime,
  selectedPickupDate,
  selectedDeliveryDate,
  avaliableDeliveryDate,
  avaliablePickupDate,
  pickupDataLoading,
  deliveryDataLoading,
  apiToken,
});

const mapDispatchToProps = dispatch => {
  return {
    setSelectedDeliveryData: data => dispatch(setSelectedDeliveryData(data)),
    setSelectedDeliveryTime: data => dispatch(setSelectedDeliveryTime(data)),
    setSelectedPickupData: data => dispatch(setSelectedPickupData(data)),
    setAvaiblePickupData: data => dispatch(setAvaiblePickupData(data)),
    setAvaibleDeliveryData: data => dispatch(setAvaibleDeliveryData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownList);
