import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import Header from "../components/Header";
import Footer from "../components/Footer";
import FaqBlock from "../components/FaqBlock";
import DownloadApp from "../components/DownloadApp";

import heroImg from "../images/home-banner.jpg";
import heroImg2x from "../images/home-banner@2x.jpg";

import "../css/pages/Faq.css";

const Faq = props => {
  return (
    <div className="faq-page-wrapper">
      <Header wv={props.wv} heroImg={heroImg} heroImg2x={heroImg2x} />
      <main>
        <FaqBlock />
        {props.wv && <DownloadApp />}
      </main>
      <Footer wv={props.wv} />
    </div>
  );
};

const mapStateToProps = ({webView}) => ({wv: webView})

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Faq)
