// for multiple requests
import { userUpdateSession } from "./helpers";
import { storageGetUserSession } from "../redux/storage";
import Api from "./api/Api";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

const interceptor = (axiosInstance) => async (error) => {
  const _axios = axiosInstance;
  const originalRequest = error.config;

  if (
    401 === error.response.status &&
    'undefined' !== error.response.data &&
    'undefined' !== error.response.data.type &&
    'expired_token' === error.response.data.type &&
    !originalRequest._retry
  ) {

    if (isRefreshing) {
      return new Promise(function(resolve, reject) {
        failedQueue.push({resolve, reject})
      }).then(token => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        return _axios.request(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      })
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const refreshToken = storageGetUserSession().refreshToken;

    return new Promise((resolve, reject) => {
      Api.refreshApiToken(refreshToken)
        .then(async ({data}) => {
          await userUpdateSession(data.token, data.refresh);

          _axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
          originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
          processQueue(null, data.token);
          resolve(_axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .then(() => { isRefreshing = false })
    })
  }

  return Promise.reject(error);
};

export default interceptor