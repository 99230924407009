import React from "react"

import bagIcon from '../../../../images/order/bag.png'
import carIcon from '../../../../images/order/car.png'
import cardIcon from '../../../../images/order/card.png'
import infoIcon from '../../../../images/order/info-blue-icon.svg'

import translation from '../../../../translations/Price'

import '../../../../css/pages/Order/components/price/TotalOverview.css'

export const TotalOverview = ({ cleaningServicesConfig = null }) => {
  const lang = localStorage.getItem("lang")
  let feeMinOrder = null
  let feeService = null
  let feeOrdersUnder = null
  let feeOrdersAbove = null
  if (cleaningServicesConfig) {
    feeMinOrder = cleaningServicesConfig.find(item => item.alias === 'PRICE_MINIMAL_ORDER_VALUE')
    feeService = cleaningServicesConfig.find(item => item.alias === 'PRICE_SERVICE_FEE')
    feeOrdersUnder = cleaningServicesConfig.find(item => item.alias === 'PRICE_SERVICE_FEE_FREE_FOR_ORDERS_UNDER')
    feeOrdersAbove = cleaningServicesConfig.find(item => item.alias === 'PRICE_FREE_DELIVERY_FOR_ORDERS_ABOVE')
  }

  return (
    <div className="overview-total">
      <div className="overview-section overview-pay-later">
        <h3>
          <span className="img-wrapper">
            <img className="overview-pay-later-card-icon" src={cardIcon} alt="" />
          </span>
          <span>{translation.feePayLaterTitle[lang]}</span>
        </h3>
        <span className="description">
          <img className="overview-pay-later-info-icon" src={infoIcon} alt="" />
          {translation.feePayLaterText[lang]}
        </span>
      </div>

      <div className="overview-section overview-min-order">
        <h3>
          <span className="img-wrapper">
            <img src={bagIcon} alt="" />
          </span>
          <span>{translation.feeMinOrderTitle[lang]}</span>
        </h3>
        {feeMinOrder &&
        <span className="description">{translation.feeMinOrderText[lang]} &euro;{feeMinOrder.amount}</span>
        }
      </div>

      <div className="overview-section overview-shipping">
        <h3>
          <span className="img-wrapper">
            <img src={carIcon} alt="" />
          </span>
          <span>{translation.feeService[lang]}</span>
        </h3>
        <div className="overview-box-container">
          { feeService && feeOrdersUnder &&
            <div className="overview-shipping-standard overview-box">
              <h4>{feeService.name} &euro;{feeService.amount}</h4>
              <p>{feeOrdersUnder.name} &euro;{feeOrdersUnder.amount}</p>
            </div>
          }
          { feeOrdersAbove &&
            <div className="overview-shipping-no-charge overview-box">
              <h4>{translation.feeServiceNoChargeTitle[lang]}</h4>
              <p>{feeOrdersAbove.name} &euro;{feeOrdersAbove.amount}</p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}