import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import "../css/components/Accordion.css";
import { translation } from '../utils/dataFiles/faqLoco';

const { firstTab_01, firstTab_02, firstTab_03, firstTab_04, firstTab_05, firstTab_06, firstTab_07, firstTab_08, firstTab_09, firstTab_10, firstTabText_01, firstTabText_02, firstTabText_03, firstTabText_04, firstTabText_05, firstTabText_06, firstTabText_07, firstTabText_08, firstTabText_09, firstTabText_10, firstTab_11, firstTabText_11 } = translation;
const { secondTab_01, secondTab_02, secondTab_03, secondTab_04, secondTab_05, secondTab_06, secondTab_07, secondTabText_01, secondTabText_02, secondTabText_03, secondTabText_04, secondTabText_05, secondTabText_06, secondTabText_07 } = translation;
const { thirdTab_01, thirdTab_02, thirdTabText_01, thirdTabText_02 } = translation;
const { fourthTab_01, fourthTab_02, fourthTab_03, fourthTab_04, fourthTab_05, fourthTab_06, fourthTab_07, fourthTab_08, fourthTabText_01, fourthTabText_02, fourthTabText_03, fourthTabText_04, fourthTabText_05, fourthTabText_06, fourthTabText_07, fourthTabText_08 } = translation;

export const AccordionBlockFirst = props => {
  const lang = localStorage.getItem("lang");

  return(
    <Accordion className="accodrion-block">
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">{firstTab_01[lang]}</h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: firstTabText_01[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {firstTab_02[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: firstTabText_02[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {firstTab_03[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: firstTabText_03[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {firstTab_04[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: firstTabText_04[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">{firstTab_05[lang]}</h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: firstTabText_05[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {firstTab_06[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: firstTabText_06[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {firstTab_07[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: firstTabText_07[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">{firstTab_08[lang]}</h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: firstTabText_08[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {firstTab_09[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: firstTabText_09[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {firstTab_10[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: firstTabText_10[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {firstTab_11[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: firstTabText_11[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
  </Accordion>
  )
}

export const AccordionBlockSecond = props => {
  const lang = localStorage.getItem("lang");

  return(
    <Accordion className="accodrion-block">
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">
              {secondTab_01[lang]}
            </h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p dangerouslySetInnerHTML={{ __html: secondTabText_01[lang] }}></p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">
              {secondTab_02[lang]}
            </h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p dangerouslySetInnerHTML={{ __html: secondTabText_02[lang] }}></p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{secondTab_03[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p dangerouslySetInnerHTML={{ __html: secondTabText_03[lang] }}></p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">
              {secondTab_04[lang]}
            </h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p dangerouslySetInnerHTML={{ __html: secondTabText_04[lang] }}></p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">
              {secondTab_05[lang]}
            </h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p dangerouslySetInnerHTML={{ __html: secondTabText_05[lang] }}></p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{secondTab_06[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p dangerouslySetInnerHTML={{ __html: secondTabText_06[lang] }}></p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{secondTab_07[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p dangerouslySetInnerHTML={{ __html: secondTabText_07[lang] }}></p>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}
export const AccordionBlockThird = props => {
  const lang = localStorage.getItem("lang");

  return(
    <Accordion className="accodrion-block">
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">{thirdTab_01[lang]}</h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: thirdTabText_01[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">{thirdTab_02[lang]}</h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: thirdTabText_02[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
  </Accordion>
  )
}

export const AccordionBlockFourth = props => {
  const lang = localStorage.getItem("lang");
  
  return(
  <Accordion className="accodrion-block">
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {fourthTab_01[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: fourthTabText_01[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {fourthTab_02[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: fourthTabText_02[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">{fourthTab_03[lang]}</h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: fourthTabText_03[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {fourthTab_04[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: fourthTabText_04[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {fourthTab_05[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: fourthTabText_05[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {fourthTab_06[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: fourthTabText_06[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {fourthTab_07[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: fourthTabText_07[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem>
      <AccordionItemHeading>
        <div className="acc-title-wrapper">
          <h3 className="u-position-relative">
            {fourthTab_08[lang]}
          </h3>
          <div className="accordion__arrow" role="presentation" />
        </div>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p dangerouslySetInnerHTML={{ __html: fourthTabText_08[lang] }}></p>
      </AccordionItemPanel>
    </AccordionItem>
  </Accordion>
)
}
