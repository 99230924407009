export default {
    selectServiceDescr: {
        'en': 'Please select the service you prefer',
        'nl': 'Kies je service ',
    },
    time: {
        'en': 'Time',
        'nl': 'Tijd',
    },
    timeDescr: {
        'en': 'Select pickup & Delivery time',
        'nl': 'Kies ophaal- & bezorgmoment',
    },
    overview: {
        'en': 'Overview & Confirmation',
        'nl': 'Overzicht en Bevestigen'
    },
    overviewDesc: {
        'en': 'Fill in your basic info',
        'nl': 'Vul je contactgegevens in'
    },
    next_time: {
        'en': 'Next - Pickup & delivery time',
        'nl': 'Volgende - Ophaal & bezorgmoment',
    },
    next_overview: {
        'en': 'Next - Almost there!',
        'nl': 'Volgende - bijna klaar!',
    },
    confirmOrder: {
        'en': 'Confirm order',
        'nl': 'Bevestig order'
    }
}