import React from 'react';
import { connect } from 'react-redux';

import AsideOrderScreen from './components/AsideOrderScreen';
import FirstStep from './order-steps/FirstStep';
import SecondStep from './order-steps/SecondStep';
import ThirdStep from './order-steps/ThirdStep';

const ThirdOrderScreen = props => {
  const orderSteps = {
    1: <FirstStep />,
    2: <SecondStep />,
    3: <ThirdStep />,
  };
  const step = props.currentOrderStep;
  return <AsideOrderScreen>{orderSteps[step]}</AsideOrderScreen>;
};

const mapStateToProps = ({ currentOrderStep }) => ({ currentOrderStep });

export default connect(mapStateToProps)(ThirdOrderScreen);
