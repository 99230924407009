export default {
  hour: {
    en: 'hour',
    nl: 'uur'
  },
  hours: {
    en: 'hours',
    nl: 'uur'
  },
  minute: {
    en: 'minute',
    nl: 'minuut'
  },
  minutes: {
    en: 'minutes',
    nl: 'minuten'
  },
  second: {
    en: 'second',
    nl: 'seconde'
  },
  seconds: {
    en: 'seconds',
    nl: 'seconden'
  },
}