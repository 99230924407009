export default {
  title: {
    'en': 'Password',
    'nl': 'Wachtwoord'
  },
  emailBlockTitle: {
    'en': 'Forgot password',
    'nl': 'Wachtwoord vergeten'
  },
  emailBlockText: {
    'en': 'Enter your e-mail address, then an e-mail will be sent with which you can set a new password.',
    'nl': 'Vul je e-mailadres in, dan wordt er een e-mail verstuurd waarmee je een nieuw wachtwoord kunt instellen.'
  },
  emailSentBlockTitle: {
    'en': 'E-mail sent',
    'nl': 'E-mail verstuurd'
  },
  emailSentBlockText1: {
    'en': 'An <b>e-mail</b> has been sent with a <b>reset link</b> that allows you to set a new password. <br/><br/>Didn\'t receive a reset link?<br/>' +
       'You can request a new reset link in',
    'nl': 'Er is een <b>e-mail</b> verstuurd met een <b>reset link</b> waarmee je een nieuw wachtwoord kunt instellen.<br/><br/>' +
      'Geen reset link ontvangen? <br/>' +
      'Over'
  },
  emailSentBlockText2: {
    'en': '.',
    'nl': 'kun je een nieuwe reset link aanvragen.'
  },
  emailSentBlockText3: {
    'en': 'An <b>e-mail</b> has been sent with a <b>reset link</b> that allows you to set a new password. <br/>Didn\'t receive a reset link?<br/>',
    'nl': 'Er is een <b>e-mail</b> verstuurd met een <b>reset link</b> waarmee je een nieuw wachtwoord kunt instellen.<br/>' +
      'Geen reset link ontvangen? <br/>'
  },
  emailPlaceholder: {
    'en': 'E-mail',
    'nl': 'E-mailadres'
  },
  emailBlockButton: {
    'en': 'Send',
    'nl': 'Versturen'
  },
  emailSentBlockButton: {
    'en': 'Request new reset link',
    'nl': 'Nieuwe reset link aanvragen'
  },
  passwordBlockTitle: {
    'en': 'Set new password',
    'nl': 'Stel een nieuw wachtwoord in'
  },
  passwordBlockText: {
    'en': 'Safe password contain numbers, letters and capitals.',
    'nl': 'Veilige wachtwoorden bestaan uit letters, hoofdletters en nummers.'
  },
  passwordPlaceholder: {
    'en': 'Type password',
    'nl': 'Nieuw wachtwoord'
  },
  passwordBlockButton: {
    'en': 'Save',
    'nl': 'Opslaan'
  },
  expiredBlockTitle: {
    'en': 'Reset link expired',
    'nl': 'Reset link verlopen'
  },
  expiredBlockText1: {
    'en': 'A reset link is valid for ',
    'nl': 'Een reset link is '
  },
  expiredBlockText2: {
    'en': '. Your reset link has expired. Request a new reset link bellow.',
    'nl': ' geldig. Uw reset link is verlopen. Vraag hieronder een nieuwe reset link aan.'
  },
  expiredBlockButton: {
    'en': 'Request new reset link',
    'nl': 'Nieuwe reset link aanvragen'
  },
}