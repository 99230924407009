export default {
    title: {
        en: `Welcome to dobbi order online.`,
        nl: `Welkom bij dobbi online bestellen.`
    },
    titleReUse: {
        en: `Welcome to dobbi re-use online`,
        nl: `SCHIJVENS RE-USE BY dobbi`
    },
    titleReCover: {
        en: `Welcome to dobbi re-cover online`,
        nl: `SCHIJVENS RE-COVER BY dobbi`
    },
    titleNoTerminal: {
        en: `Welcome to dobbi retail`,
        nl: `WELKOM BIJ DOBBI RETAIL`
    },
    titleCollection: {
        en: `Welcome to dobbi collection`,
        nl: `WELKOM BIJ DOBBI COLLECTION`
    },
    titleB2b: {
        en: `Welcome to dobbi business`,
        nl: `Welkom bij dobbi business`
    },
    findAddress: {
        en: 'Fill in your address',
        nl: 'Voer hier je adres in'
    },
    location: {
        en: 'Your pickup & delivery location',
        nl: 'Ophaal- & bezorgadres'
    },
    locationRecover: {
        en: 'Your pickup location',
        nl: 'Ophaal'
    },
    edit: {
        en: 'Edit',
        nl: 'Wijzig'
    },
    editAddress: {
        en: 'Edit address',
        nl: 'Wijzig adres'
    },
    betaText : {
        'en': 'Dobbi order online',
        'nl': 'Dobbi online bestellen'
    },
    beta : {
        'en': 'Beta',
        'nl': 'Beta'
    }
}
