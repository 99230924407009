import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import Header from "../components/Header";
import Footer from "../components/Footer";
import DownloadApp from "../components/DownloadApp";

import heroImg from "../images/home-banner.jpg";
import heroImg2x from "../images/home-banner@2x.jpg";

import "../css/pages/Cookie.css";

const Cookie = props => {
  return (
    <div className="cookie-page-wrapper">
      {props.wv && (
        <Header wv={props.wv} heroImg={heroImg} heroImg2x={heroImg2x} />
      )}
      <CookieBlock />
      <main>{props.wv && <DownloadApp />}</main>
      <Footer wv={props.wv} />
    </div>
  );
};
const CookieBlock = props => {
  return (
    <div className="cookie_block-wrapper">
      <div className="cookie_block">
        <h1>Cookie & Privacy statement – Clean Clothes B.V.</h1>
        <p>
          <h2>Privacy Statement:</h2>
          <p>
            <p>
              Dit privacy statement is van toepassing op de verwerking van persoonsgegevens van klanten die een order plaatsen via een platform van Clean Clothes B.V.
            </p>
            <p>
              Clean Clothes B.V. is verantwoordelijk voor de verwerking van deze persoonsgegevens en vindt uw privacy belangrijk. Clean Clothes B.V. houdt zich daarom met betrekking tot verwerking van persoonsgegevens aan Algemene Verordening Gegevensbescherming, ofwel de General Data Protection Regulation (GDPR).
            </p>
            <p>
              Clean Clothes B.V. verwerkt persoonsgegevens omdat u gebruik maakt van onze service. Wij gebruiken de volgende gegevens voor de volgende doelen:
            </p>
          </p>
        </p>
        <p>
          <h2>1. Bestelproces</h2>
          <p>
            <p>
              Wij verwerken gegevens die u zelf aan ons verstrekt door ze in te vullen bij het plaatsen van uw order. Deze gegevens zijn nodig voor de uitvoering van uw order, het bevestigen van uw order, de mogelijkheid tot het beoordelen van uw order, het verwerken van uw order, betaling en een eventuele terugbetaling. De rechtsgrond voor deze verwerking is dat de verwerking noodzakelijk is voor de uitvoering van een overeenkomst in de zin van de GDPR. Het gaat om de volgende gegevens:
            </p>
            <ul>
              <li>Naam</li>
              <li>Adresgegevens</li>
              <li>Contactgegevens</li>
              <li>Order gegevens </li>
              <li>Betaalgegevens</li>
              <li>Opmerkingen (voor zover van toepassing)</li>
            </ul>
          </p>
        </p>
        <p>
          <h2>2. Klantenservice</h2>
          <p>
            <p>
              Als u contact opneemt met onze klantenservice, zullen we de door u verstrekte gegevens gebruiken voor het beantwoorden van uw vraag of het oplossen van uw klacht. De rechtsgrond voor deze verwerking is dat de verwerking noodzakelijk is voor de uitvoering van een overeenkomst in de zin van de GDPR. Het gaat om de volgende gegevens:
            </p>
            <ul>
              <li>Naam</li>
              <li>Adresgegevens</li>
              <li>Contactgegevens</li>
              <li>Betaalgegevens</li>
              <li>Opmerkingen</li>
              <li>Overige bijzonderheden</li>
            </ul>
          </p>
        </p>
        <p>
          <h2>3. Klant tevredenheids onderzoeken</h2>
          <p>
            Om te zorgen dat onze dienstverlening aansluit bij uw wensen, kunnen de gegevens die u aan de klantenservice verstrekt, gebruikt worden voor bijvoorbeeld Klant tevredenheids onderzoeken. De rechtsgrond voor deze verwerking is dat de verwerking noodzakelijk is om een gerechtvaardigd belang van Clean Clothes B.V. (klanttevredenheid) te behartigen in de zin van de GDPR. Het gaat om dezelfde gegevens als bij nr. 2.
          </p>
        </p>
        <p>
          <h2>4. Marketing berichten</h2>
          <p>
            Wij verwerken daarnaast gegevens om u (gepersonaliseerde) marketingberichten te sturen. Hieronder scharen wij nieuws, kortingen en nieuwe updates (e-mail) en loyaliteitsprogramma’s, ongeacht de vorm waarin wij deze delen (bijvoorbeeld e-mail of als pushbericht). De rechtsgrond voor deze verwerking is dat u hiervoor toestemming hebt gegeven bij het plaatsen van uw order in de zin van de GDPR. Mocht u de voorkeuren met betrekking tot het ontvangen van deze berichten willen aanpassen dan kan u zich uitschrijven via de link in het betreffende bericht. Het gaat om de volgende gegevens:
          </p>
          <ul>
            <li>Naam</li>
            <li>Contactgegevens</li>
            <li>Adresgegevens</li>
          </ul>
        </p>
        <p>
          <h2>5. Cookies</h2>
          <p>
            Tot slot verwerken wij ook gegevens van u, die u indirect aan ons verstrekt. Clean Clothes B.V. maakt namelijk gebruik van cookies voor functionele, analytische- en marketingdoeleinden. De functionele cookies zijn noodzakelijk voor het gebruik van de website. Het gaat om de volgende gegevens:
          </p>
          <ul>
            <li>Locatiegegevens</li>
            <li>IP-adres of app IDs</li>
            <li>Internetbrowser en apparaat type</li>
            <li>Websitetaal</li>
          </ul>
          <p>
            De gegevens uit de analytische en marketing cookies worden niet gekoppeld aan de overige gegevens die u tijdens het bestelproces verstrekt
          </p>
        </p>
        <p>
          <h2>6. Analyse</h2>
          <p>Wij gebruiken uw gegevens ook om aan onze rapportageverplichtingen richting bijv. investeerders te kunnen voldoen en om onze website en service te kunnen verbeteren. De rechtsgrond voor deze verwerking is dat de verwerking noodzakelijk is om een gerechtvaardigd belang van Clean Clothes B.V. (analyse & rapportage) te behartigen in de zin van de GDPR. Wij zorgen ervoor dat uiteindelijke rapportages geen naar u herleidbare gegevens bevatten.</p>
          <p>
            <i>Hoe lang wij gegevens bewaren</i><br />
            Clean Clothes B.V. zal uw persoonsgegevens niet langer bewaren dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld. Wij zullen uw gegevens langer bewaren wanneer wij hiertoe verplicht zijn op grond van wettelijke verplichtingen. Clean Clothes B.V. verwijdert uw meeste gegevens na 5 jaar na het plaatsen van uw order. Wij hanteren deze termijn voor administratieve doeleinden. Gegevens die voor rapportage, analyse en accounting worden gebruikt, bewaren wij tot 20 jaar na het plaatsten van uw order. Wij kunnen uw gegevens niet verwijderen uit back-ups. Op het moment dat we een back-up terugplaatsen zullen we de data echter op dat moment verwijderen.
          </p>
          <p>
            <i>Delen met reinigingspartners.</i><br />
            Clean Clothes B.V. verstrekt uw gegevens (naam, adresgegevens en [telefoonnummer,] order) aan de reinigingspartner in uw regio, zodat onze partner uw order kan bezorgen.
          </p>
          <p>
            Clean Clothes B.V. verkoopt uw gegevens niet aan derden en zal deze uitsluitend aan derden verstrekken indien dit nodig is voor de uitvoering van onze overeenkomst met u, analyse- en marketingdoeleinden of om te voldoen aan wettelijke verplichtingen.
            Met de volgende partijen worden uw persoonsgegevens mogelijk gedeeld:
            <ul>
              <li>Softwareproviders</li>
              <li>Implementatie partners</li>
              <li>Klant tevredenheids onderzoeks bureaus</li>
            </ul>
          </p>
          <p>
            <i>Inzage, correctie en verwijdering van uw persoonsgegevens</i><br />
            U hebt het recht alle gegevens die wij over u verzamelen in te zien, te laten verwijderen of zo nodig te corrigeren. Als u ervan overtuigd bent dat wij zonder reden uw persoonsgegevens verwerken of dat wij persoonsgegevens verwerken die volgens u niet relevant zijn voor ons selectieproces, kun uw contact met ons opnemen via info@dobbi.com. Clean Clothes B.V. zal zo snel mogelijk, maar uiterlijk binnen vier weken, op uw verzoek reageren.
          </p>
          <p>
            <i>Beveiliging</i><br />
            Clean Clothes B.V. neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat uw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact met ons op via info@dobbi.com.
          </p>
          <p>
            <i>Autoriteit persoonsgegevens</i><br />
            Naast de mogelijkheid om bij ons een klacht in te dienen, heeft u het recht om een klacht in te dienen bij de relevante autoriteit voor de bescherming van persoonsgegevens. U kan hierover contact opnemen met de betreffende autoriteit.
          </p>
          <p>
            <i>Aanpassingen aan ons Cookie & Privacy statement</i><br />
            Wij houden ons het recht voor om aanpassingen te doen aan ons Cookie & Privacy statement. Wij sturen hierover geen bericht maar adviseren u om zelf zo nu en dan dit statement door te nemen. Voor vragen en suggesties kunt uw altijd contact met ons opnemen.
          </p>
        </p>
        <p>
          <p>
            <i>Contact:</i><br />
            Mocht u na het lezen van deze Algemene Voorwaarden vragen, klachten
            of opmerkingen hebben, neem dan gerust schriftelijk of per e-mail
            contact met ons op.
          </p>
          <p>
            <p>
              Clean Clothes B.V. - dobbi <br />
              Joop van den Endeplein 1 <br />
              1217 WJ Hilversum <br />
              Nederland
            </p>
            <p>
              KvK-nummer: 70098778 <br />
              BTW-nummer: 858140901B01
            </p>
            <p>
              Klantenservice <br />
              Telefoon: <a href="tel:+31850187540">+31 85 01 875 40</a>{" "}
              <br />
              Email: <a href="mailto:info@dobbi.com ">info@dobbi.com</a> <br />
            </p>


          </p>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = ({webView}) => ({wv: webView})

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Cookie)
