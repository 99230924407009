import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import { translation } from "../../utils/dataFiles/jobs-loco";

import heroImg from "../../images/home-banner.jpg";
import heroImg2x from "../../images/home-banner@2x.jpg";
import Bubbles from "../../images/faq-bubbles.svg";

import "../../css/pages/Jobs/index.css";
import Footer from '../../components/Footer'
import { Header } from '../../components/Header'
import { TabsBlock } from '../../components/JobTabs'
import { MobileTabs } from '../../components/MobileTabs'

const { terms_00, terms_01, terms_02 } = translation;

const Jobs = props => {
  const lang = localStorage.getItem("lang");
  return (
    <div className="job-page-wrapper">
      <Header wv={props.wv} heroImg={heroImg} heroImg2x={heroImg2x} />
      <section className="faq-block__wrapper">
        <div className="faq-block">
          <div className="faq-header_wrapper">
            <h1>{terms_00[lang]}</h1>
            <p>
              {terms_01[lang]} <br />
              <a href="mailto:info@dobbi.com">info@dobbi.com</a> <br />
              {terms_02[lang]} <a href="tel:+31850187540">+31 85 01 875 40</a>
            </p>
          </div>
          <TabsBlock />
          <MobileTabs />
          <img src={Bubbles} alt="" />
        </div>
      </section>
      <Footer wv={props.wv} />
    </div>
  );
};

const mapStateToProps = ({ webView }) => ({ wv: webView });

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Jobs);
