export default {
    withinReach: {
        'en': 'Yes, our service is available at your location!',
        'nl': 'Yes, onze service is op dit adres beschikbaar!'
    },
    accountExists: {
        'en': 'A user with this e-mail address already exists. Please, use the login function.',
        'nl': 'Een gebruiker met dit e-mailadres bestaat al. Gebruik a.u.b. de inlogfunctie.'
    },
    fillEmail: {
        'en': 'Create an account right away so we can keep you informed about your order.',
        'nl': 'Maak direct een account aan zodat we je op de hoogte kunnen houden van je bestelling.'
    },
    emailPlaceHolder: {
        'en': 'Enter your email here',
        'nl': 'Vul hier je emailadres in'
    },
    passwordPlaceHolder: {
        'en': 'Type password',
        'nl': 'Verzin een wachtwoord'
    },
    iAccept: {
        'en': 'I accept the',
        'nl': 'Ik accepteer de'
    },
    termsAndConditions: {
        'en': 'Terms & conditions',
        'nl': 'Algemene Voorwaarden'
    },
    privacyStatement: {
        'en': 'Privacy Statement',
        'nl': 'Privacyverklaring'
    },
    andThe: {
        'en': 'and the',
        'nl': 'en de'
    },
    next: {
        'en': 'Next',
        'nl': 'Volgende'
    }
}