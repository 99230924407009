import React from "react";
import "../css/components/HamButton.css";

const menuSwitch = selector => {
  let menu = document.querySelector(selector);
  let button = document.querySelector(".hamburger-button");
  let body = document.body;
  button.classList.toggle("active");
  menu.classList.toggle("active");
  body.classList.toggle("menu-opened")
};

const HamButton = props => {
  return (
    <div className="hamburger-button" onClick={menuSwitch.bind(null, ".hero-image")}>
      <i />
      <i />
      <i />
    </div>
  );
};

export default HamButton;
