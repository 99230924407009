import React, { forwardRef, useRef } from 'react'
import PropTypes from 'prop-types';
import { KeyPad } from './elements';
import StaticWrapper from './StaticWrapper';

const positiveValidation = value => {
  // FIX -0 to be considered positive
  if (value === 0 && Object.is(value, -0)) {
    return false;
  }
  return parseFloat(value) >= 0;
};

const integerValidation = value => parseFloat(value) % 1 === 0;

const numberValidator = (decimal, negative) => value => {
  if (value === '-' && negative) {
    return true;
  }
  if (Number.isNaN(Number(value))) {
    return false;
  }

  const floatValue = parseFloat(value);

  if (!decimal && !integerValidation(floatValue)) {
    return false;
  }

  if (typeof decimal === 'number' && decimal > 0) {
    if ((floatValue.toString().split('.')[1] || '').length > decimal) {
      return false;
    }
  }

  if (!negative && !positiveValidation(floatValue)) {
    return false;
  }
  return true;
};

const NumberInput = ({ inline, children, keyValidator, decimal, negative, show, loading, leadingZeros, enter, ...props }) => {
  const validation = value => numberValidator(decimal, negative)(value);
  const numberRef = useRef()

  let validator = keyValidator;
  if (!validator) {
    validator = value => numberValidator(decimal, negative)(value);
  }

  const keyValid = isValid => (value = '', key) => {
    if (key === '-') {
      return value.charAt(0) === '-' || isValid(key + value);
    }
    return isValid(key === '.' ? value + key + 1 : value + key);
  };

  const displayRule = leadingZeros ? value => value : value => value.replace(/^(-)?0+(0\.|\d)/, '$1$2')

  return (
    <StaticWrapper {...props} ref={numberRef} displayRule={displayRule}>
      <KeyPad
        validation={validation}
        keyValid={keyValid(validator)}
        displayRule={displayRule}
        show={show}
        enter={enter}
        loading={loading}
        {...props}
      />
    </StaticWrapper>
  )
};

NumberInput.propTypes = {
  inline: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  keyValidator: PropTypes.func,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  decimal: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  negative: PropTypes.bool,
  leadingZeros: PropTypes.bool,
};

NumberInput.defaultProps = {
  inline: false,
  children: undefined,
  keyValidator: undefined,
  decimal: true,
  negative: true,
  show: true,
  loading: false,
  leadingZeros: false,
};

export default forwardRef(NumberInput);
export { numberValidator, positiveValidation, integerValidation };
