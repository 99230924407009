import React from 'react';
import { compose } from 'redux';
import translation from '../../../translations/Auth'
import { Button } from "../../../components/Button";
import { Time } from "../../../components/Time";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class RecoverExpired extends React.Component {
  constructor(props) {
    super(props);

    this.lang = localStorage.getItem("lang");
  }

  onButtonClick = () => {
    this.props.history.push('/' + this.lang + '/recover');
  };

  render() {
    return (
      <div className="user-recover-expired">
        <div style={{ minHeight: 90 }}>
          <h1>{ translation.expiredBlockTitle[this.lang] }</h1>
          <span>{ translation.expiredBlockText1[this.lang] }</span>
          <Time className="cd-wrapper" seconds={ this.props.ttl } hideEmpty/>
          <span>{ translation.expiredBlockText2[this.lang] }</span>
        </div>
        <div>
          <Button
            id="recover-button-expired"
            color="green"
            onClick={ this.onButtonClick }
            className="checked"
          >
            { translation.expiredBlockButton[this.lang] }
          </Button>
        </div>
      </div>
    )
  }
}

export default compose(
  withRouter,
  connect()
)(RecoverExpired);
