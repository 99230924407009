import React from "react";

import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import {
  AccordionBlockFirst,
  AccordionBlockSecond,
} from "./AccordionJobs";

import { translation } from "../utils/dataFiles/jobs-loco";

import "react-web-tabs/dist/react-web-tabs.css";
import "../css/components/Tabs.css";

const { tab_01, tab_02 } = translation;

export const TabsBlock = props => {
  const lang = localStorage.getItem("lang");

  return (
    <Tabs
      className="faq-tabs__block tabs-section"
      defaultTab="vertical-tab-one"
      vertical
    >
      <TabList>
        <Tab className="custom-tab custom-tab-one" tabFor="vertical-tab-one">
          {tab_01[lang]}
        </Tab>
        <Tab className="custom-tab custom-tab-two" tabFor="vertical-tab-two">
          {tab_02[lang]}
        </Tab>
      </TabList>
      <TabPanel className="tabs-inner" tabId="vertical-tab-one">
        <AccordionBlockFirst />
      </TabPanel>
      <TabPanel className="tabs-inner" tabId="vertical-tab-two">
        <AccordionBlockSecond />
      </TabPanel>
    </Tabs>
  );
};
