import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import Header from "../components/Header";
import Footer from "../components/Footer";
import DownloadApp from "../components/DownloadApp";

import heroImg from "../images/home-banner.jpg";
import heroImg2x from "../images/home-banner@2x.jpg";

import "../css/pages/Terms.css";

const Terms = props => {
  return (
    <div className="terms-page-wrapper">
      {props.wv && (
        <Header wv={props.wv} heroImg={heroImg} heroImg2x={heroImg2x} />
      )}
      <TermsBlock />
      <main>{props.wv && <DownloadApp />}</main>
      <Footer wv={props.wv} />
    </div>
  );
};
const TermsBlock = props => {
  return (
    <div className="terms_block-wrapper">
      <div className="terms_block">
        <h1>Algemene voorwaarden</h1>
        <p>
          <h2>1. Inleiding</h2>
          <p>
            Hieronder vindt u onze Algemene Voorwaarden. Deze zijn steeds van
            toepassing als u gebruik maakt van onze Diensten via de App, de
            Website of via de Klantenservice en bevatten belangrijke informatie
            voor u als Klant. Lees de Algemene Voorwaarden daarom goed door. Wij
            raden u verder aan deze voorwaarden op te slaan, zodat u ze op een
            later moment nog eens kunt teruglezen.
          </p>
        </p>
        <p>
          <h2>2. Definities</h2>
          <p>
            Dobbi: de besloten vennootschap met beperkte aansprakelijkheid Clean
            Clothes B.V. gevestigd te Hilversum en ingeschreven bij de Kamer van
            Koophandel onder registratienummer 70098778 onder andere handelend
            onder de naam dobbi.
          </p>
          <p>
            App: de mobiele app van dobbi, te downloaden via de Apple Store of
            via Google Play.
          </p>
          <p>
            Klant: de natuurlijke persoon of rechtspersoon al dan niet handelend
            in uitoefening van beroep of bedrijf die een Overeenkomst aangaat
            met dobbi en/of zich geregistreerd heeft via de App, de Website, of
            via de Klantenservice.
          </p>
          <p>
            Overeenkomst: iedere schriftelijke afspraak of overeenkomst tussen
            dobbi en Klant, van welke overeenkomst deze Algemene Voorwaarden
            integraal onderdeel uitmaken.
          </p>
          <p>
            Diensten: de textielreinigings - en stomerijdiensten in de breedste
            zin van het woord, die dobbi aanbiedt aan bedrijven en aan
            consumenten.
          </p>
          <p>
            Items: de zaken waarop de Diensten van toepassing zijn, zoals te
            behandelen kleding en overige textielvormen.
          </p>
          <p>Algemene Voorwaarden: de onderhavige algemene voorwaarden.</p>
          <p>
            Klantenservice: de klantenservice van dobbi die bereikbaar is tussen
            8:30-21:00 (ma - vrij) en op zaterdag 9:00-17:00 via het e-mail
            adres: <a href="mailto:info@dobbi.com">info@dobbi.com </a>en via
            telefoonnummer <a href="tel:+31850187540"> +31 85-01 875 40</a>{" "}
            Website: de website van dobbi met de domeinnaam{" "}
            <a href="www.dobbi.com">www.dobbi.com</a>.
          </p>
        </p>
        <p>
          <h2>3. Toepasselijkheid Algemene Voorwaarden</h2>
          <p>
            1 Op alle online en offline aanbiedingen, Overeenkomsten en Diensten
            van dobbi zijn enkel de Algemene Voorwaarden van toepassing. Andere
            algemene voorwaarden van de Klant zijn niet van toepassing op een
            Overeenkomst.
          </p>
        </p>
        <p>
          <h2>4. Prijzen en Betaling</h2>
          <p>
            1 Alle op de App, Website en in andere van dobbi afkomstige
            materialen, zoals flyers, vermelde prijzen voor Diensten zijn
            inclusief 21% BTW.
          </p>
          <p>
            2 Prijzen hangen af van de hoeveelheid door de Klant verzonden Items
            waarop de Diensten van toepassing zijn. Zodra duidelijk is hoeveel
            Items of wasgewicht er valt onder de te verrichten Diensten, laat
            dobbi via de App, de Website of de Klantenservice aan Klant weten
            wat de hoogte is van de totale kosten inclusief BTW en
            vervoerskosten (indien van toepassing). De Klant kan voor het
            sluiten van de Overeenkomst zelf zien wat de specifieke prijzen zijn
            van de Diensten per Item en bijbehorende verzendkosten.
          </p>
          <p>
            3. Verzendkosten bedragen EUR €9,95 per order wanneer de totale
            orderwaarde (zonder eventuele kortingen) minder bedraagt dan EUR 30.
          </p>
          <p>
            3 De inhoud van de App en Website is met de grootste zorgvuldigheid
            samengesteld. Dobbi kan echter niet garanderen dat alle informatie
            op de App en Website te allen tijde juist en volledig is. Alle
            prijzen en overige informatie op de App, Website en in andere van
            dobbi afkomstige materialen zijn dan ook onder voorbehoud van
            programmeer- en evidente typefouten.
          </p>
          <p>
            4 Klant dient betalingen aan dobbi volgens de op de App of Website
            aangegeven betaalmethoden te voldoen. dobbi is vrij in de keuze van
            het aanbieden van betaalmethoden en deze kunnen ook van tijd tot
            tijd wijzigen. Betaling geschiedt altijd voor terug bezorging van de
            Items.
          </p>
          <p>
            5 Indien de Klant niet tijdig aan zijn betalingsverplichting(en)
            voldoet, is deze, nadat hij door dobbi is gewezen op de te late
            betaling en dobbi de Klant een termijn van 14 dagen heeft gegund om
            alsnog aan zijn betalingsverplichtingen te voldoen, na het
            uitblijven van betaling binnen deze 14-dagen-termijn, over het nog
            verschuldigde bedrag de wettelijke rente verschuldigd en is dobbi
            gerechtigd de door hem gemaakte buitengerechtelijke incassokosten in
            rekening te brengen.
          </p>
        </p>
        <p>
          <h2>5. Totstandkoming Overeenkomst</h2>
          <p>
            1 De Overeenkomst komt tot stand op het moment van aanvaarding door
            de Klant van het aanbod van de Diensten van dobbi via de App,
            Website of Klantenservice en het voldoen aan de daarbij door dobbi
            gestelde voorwaarden.
          </p>
          <p>
            2 Klant aanvaardt het aanbod van dobbi via de App, Website of
            Klantenservice door een order tot Diensten te bevestigen.
          </p>
          <p>
            3 Het wijzigen of ontbinden van de Overeenkomst door Klant kan
            alleen indien dit uiterlijk 8 uur voor het ophalen van de Items aan
            de Klantenservice is medegedeeld tijdens kantooruren. Indien een
            dergelijke melding te laat (dus minder dan 8 uren voor het ophalen
            van de Items) wordt gedaan door Klant bij de Klantenservice, zal
            Klant een bedrag ad EUR 10,- moeten betalen aan dobbi.
          </p>
          <p>
            4 Indien blijkt dat voor of tijdens het sluiten van de Overeenkomst
            door Klant onjuiste (adres)gegevens zijn verstrekt, heeft dobbi het
            recht om pas aan haar verplichting te voldoen nadat de juiste
            gegevens zijn ontvangen. De Klant heeft de verplichting om bij
            verkeerd ingevoerde gegevens per direct, maar uiterlijk 8 uur voor
            het ophalen van de Items, de Klantenservice in te lichten over deze
            fouten.
          </p>
          <p>
            5 Indien blijkt dat dobbi een Overeenkomst om welke reden dan ook
            niet kan of wil uitvoeren dan zal zij Klant hiervan zo snel mogelijk
            op de hoogte brengen. De Overeenkomst wordt dan ontbonden en
            eventuele aanbetalingen worden teruggestort naar de Klant.{" "}
          </p>
        </p>
        <p>
          <h2>6. Registratie via de App, Website of Klantenservice</h2>
          <p>
            1 Om optimaal gebruik te maken van de Diensten, dient de Klant zich
            eerst te registreren via de App, Website of Klantenservice door
            middel van het verifiëren van zijn telefoonnummer en/of e-mailadres.
          </p>
          <p>
            2 Nadat de Overeenkomst tot stand is gekomen, heeft Klant de optie
            om een gebruikersnaam en wachtwoord aan te maken, waarmee hij kan
            inloggen op de App of op de Website. Klant is zelf verantwoordelijk
            voor het kiezen van een voldoende betrouwbaar wachtwoord.
          </p>
          <p>
            3 Klant dient zijn inloggegevens, zijn gebruikersnaam en wachtwoord
            strikt geheim te houden. Dobbi is niet aansprakelijk voor misbruik
            van de inloggegevens en mag er steeds vanuit gaan dat een Klant die
            een bestelling plaatst ook daadwerkelijk die Klant is. Al hetgeen
            gebeurt via het account van Klant, valt onder de
            verantwoordelijkheid en het risico van Klant.
          </p>
          <p>
            4 Indien Klant weet of vermoedt dat zijn inloggegevens in handen van
            onbevoegden zijn gekomen, dient hij zo spoedig mogelijk zijn
            wachtwoord te wijzigen en/of dobbi daarvan in kennis te stellen,
            zodat dobbi gepaste maatregelen kan nemen
          </p>
        </p>
        <p>
          <h2>7. Uitvoering Overeenkomst</h2>
          <p>
            1 Zodra de opdracht tot het uitvoeren van Diensten door dobbi is
            ontvangen, treedt het logistieke proces ten aanzien van de Diensten
            in werking. Dat wil zeggen: dobbi stuurt voor de eerste bestelling
            per post een waszak naar de Klant die de Items (ontdaan van
            eventuele losse onderdelen, zoals pennen, spelden etcetera) in deze
            waszak dient te plaatsen. Vervolgens haalt dobbi de waszak met de
            Items op bij de Klant en verricht de Diensten binnen de
            overeengekomen termijn. Ten slotte levert dobbi de Items samen met
            deze waszak weer terug aan de Klant.{" "}
          </p>
          <p>
            2 Dobbi is gerechtigd derden in te schakelen bij het uitvoeren van
            de verplichtingen die voortvloeien uit de Overeenkomst.
          </p>
          <p>
            3 Dobbi voert de Diensten met de hoogste mate van zorg uit en zal
            altijd de Diensten conform het waslabel uitvoeren met uitzondering
            van wassen & vouwen orders (zie artikel 10.12). Dobbi heeft een
            expliciete inspanningsverplichting om de Diensten zo goed mogelijk
            uit te voeren zoals van haar mag worden verwacht op basis van de
            Overeenkomst. Dobbi gaat echter geen resultaatsverplichting aan op
            grond van de Diensten.{" "}
          </p>
          <p>
            4 Dobbi maakt gebruik van het taggen van Items voordat de Diensten
            worden verricht. Het achteraf door de Klant zelf verwijderen van
            deze tags komt uitsluitend voor risico van de Klant.{" "}
          </p>
          <p>
            5 Indien dobbi de Diensten om welke reden dan ook niet binnen de
            overeengekomen termijn kan uitvoeren, stelt zij Klant daarvan zo
            snel mogelijk in kennis. Dobbi zal samen met de Klant een nieuwe
            leverdatum afspraken.
          </p>
          <p>
            6 Klant dient de teruggezonden Items na het verrichten van de
            Diensten te inspecteren en de daarbij gebleken gebreken binnen
            uiterlijk 48 uur na ontvangst, per e-mail aan de Klantenservice, te
            melden. Zie nader het Artikel omtrent garantie en conformiteit.
          </p>
          <p>
            7 Zodra de Items op het opgegeven afleveradres van de Klant terug
            zijn afgeleverd, gaat het risico, waar het deze Items betreft, terug
            over op Klant.
          </p>
          <p>
            8 Klant is gerechtigd om via de App, Website of Klantenservice een
            ander afhaal of ontvangst adres op te geven dan het feitelijke adres
            van de Klant. Alle risico’s die hieraan verbonden zijn, komen enkel
            voor rekening van de Klant.
          </p>
          <p>
            9 Indien Klant of de aangewezen derde niet aanwezig is op het moment
            van de geplande terugzending van de Items, zal Klantenservice
            contact opnemen met Klant. Er zal vervolgens een tweede afspraak
            worden gemaakt voor het terugzenden van de Items. Indien Klant dan
            weer niet aanwezig is, zal een bedrag ad EUR 10,- in rekening worden
            gebracht en zal een derde afspraak worden gemaakt.{" "}
          </p>
          <p>
            10 Indien Klant niet kan regelen dat een Item binnen 90 dagen na de
            geplande leverdatum is teruggezonden, heeft dobbi het recht het
            betreffende Item te vernietigen of te doneren aan een goed doel.{" "}
          </p>
        </p>
        <p>
          <h2>8. Garantie en conformiteit</h2>
          <p>
            1 Dobbi staat er voor in dat de Diensten voldoen aan de
            Overeenkomst, de in het aanbod vermelde specificaties voor de
            Diensten, aan de eisen zoals gesteld door de branchevereniging NETEX
            (zie www.netex.nl) aan de redelijke eisen van deugdelijkheid van de
            Diensten en de op de datum van de totstandkoming van de Overeenkomst
            bestaande wettelijke bepalingen en/of overheidsvoorschriften.
          </p>
          <p>
            2 Indien de Diensten niet aan de Overeenkomst beantwoorden, dan
            dient Klant binnen 48 uur na ontvangst van de Items dobbi daarvan
            via de Klantenservice schriftelijk en met toevoeging van foto’s van
            de betreffende Items in kennis te stellen.
          </p>
          <p>
            3 Indien dobbi de klacht gegrond acht, worden na overleg met Klant
            de Diensten opnieuw kosteloos uitgevoerd of de relevante Items
            hersteld, vervangen of vergoed. De maximale vergoeding is, met
            inachtneming van het Artikel aangaande aansprakelijkheid gelijk aan
            10x de standaard reinigingskosten per Item waar schade aan is
            toegebracht.{" "}
          </p>
        </p>
        <p>
          <h2>9. Klachtenprocedure</h2>
          <p>
            1 Indien Klant een klacht heeft over de Diensten (conform Artikel
            aangaande garantie en conformiteit), dan kan hij bij dobbi
            telefonisch, per e-mail of per post een klacht indienen. Zie de
            contactgegevens onder aan de Algemene Voorwaarden.
          </p>
          <p>
            2 Dobbi geeft Klant zo spoedig mogelijk, maar in ieder geval binnen
            2 werkdagen na ontvangst van de klacht, een reactie op zijn klacht.
            Indien het nog niet mogelijk is een inhoudelijke of definitieve
            reactie te geven, dan zal dobbi binnen 2 dagen na de ontvangst van
            de klacht, de klacht bevestigen en een indicatie geven van de
            termijn waarbinnen zij verwacht een inhoudelijke of definitieve
            reactie te geven op de klacht van Klant.
          </p>
        </p>
        <p>
          <h2>10. Aansprakelijkheid</h2>
          <p>
            1 De totale aansprakelijkheid van dobbi jegens Klant wegens een
            eventuele toerekenbare tekortkoming in de nakoming van de
            Overeenkomst is beperkt tot vergoeding van maximaal tienmaal het
            standaardbedrag (inclusief btw) per Item waar de Diensten op van
            toepassing zijn.
          </p>
          <p>
            2 Aansprakelijkheid van dobbi jegens Klant voor indirecte schade,
            daaronder in ieder geval –maar uitdrukkelijk niet uitsluitend –
            begrepen gevolgschade, gederfde winst, gemiste besparingen, verlies
            van gegevens en schade door bedrijfsstagnatie, is te allen tijde
            uitgesloten.
          </p>
          <p>
            3 De in dit Artikel genoemde beperkingen komen echter te vervallen
            indien en voor zover schade het gevolg is van opzet of grove schuld
            van dobbi
          </p>
          <p>
            4 De aansprakelijkheid van dobbi jegens Klant wegens toerekenbare
            tekortkoming in de nakoming van een Overeenkomst ontstaat slechts
            indien Klant dobbi onverwijld en deugdelijk schriftelijk (en
            aangetekend) in gebreke stelt, stellende daarbij een redelijke
            termijn ter zuivering van de tekortkoming, en dobbi ook na die
            termijn in de nakoming van haar verplichtingen tekort blijft
            schieten en in verzuim raakt. De ingebrekestelling dient een zo
            gedetailleerd mogelijke omschrijving van de tekortkoming te
            bevatten, zodat dobbi in staat is adequaat te reageren.
          </p>
          <p>
            5 Voorwaarde voor het ontstaan van enig recht op schadevergoeding is
            steeds dat de Klant de schade zo spoedig mogelijk, doch uiterlijk
            binnen 48 uur, na het ontstaan daarvan schriftelijk bij de
            Klantenservice van dobbi meldt.
          </p>
          <p>
            6 In geval van overmacht is dobbi niet gehouden tot vergoeding van
            enige daardoor bij Klant ontstane schade.
          </p>
          <p>
            7 Dobbi is nimmer aansprakelijk voor eventuele schade als gevolg van
            onvolledige of onjuiste mededelingen van de Klant.
          </p>
          <p>
            8 Dobbi is nimmer aansprakelijk voor verlies van zaken (waaronder
            maar niet beperkt tot geldbedragen, juwelen en andere waardevolle
            zaken) die zich in de Items bevonden voordat deze werden verzonden
            naar dobbi.{" "}
          </p>
          <p>
            9 Dobbi is nimmer aansprakelijk voor kleurverlies of uitzetten of
            krimpen van Items.
          </p>
          <p>
            10 Dobbi is nimmer aansprakelijk voor schade die is geleden bij
            Items die niet zijn voorzien van een deugdelijk waslabel.
          </p>
          <p>
            11 Dobbi is nimmer aansprakelijk voor eventuele beschadigingen van
            en door knopen, ritsen, decoraties, etcetera van Items.
          </p>
          <p>
            12. Dobbi is nimmer aansprakelijk voor schade aan en/of krimpen van
            alle type gordijnen (5-7% krimp kan ontstaan). Door de inwerking van
            zonlicht, slijtage en dagelijks gebruik kunnen gordijnen schade
            oplopen die bij de Diensten pas goed aan het licht komen.
          </p>
          <p>13 Wassen & Vouwen (Wash & Fold) orders:</p>
          <p>
            13.1 Wassen & Vouwen Items worden niet individueel getagged, maar
            per order waardoor dobbi nimmer aansprakelijk is voor vermissingen
            van individuele items.
          </p>
          <p>
            13.2 Alle Items worden gewassen op 30 graden en gedroogd op medium
            temperatuur. Door het hoge volume van orders is het niet mogelijk om
            rekening te houden met persoonlijke voorkeuren of instructies,
            tenzij uitdrukkelijk anders is overeengekomen.
          </p>
          <p>
            13.3 Dobbi is nimmer aansprakelijk voor eventuele schade door het
            wassen en/of drogen van Items, omdat orders per lading worden
            gewassen en de individuele waslabels niet worden gecontroleerd.
          </p>
          <p>
            13.4 De Wassen & Vouwen order zal geen overhemden, blouses of Items
            die gestoomd moeten worden bevatten. Indien deze wel aanwezig zijn
            worden deze Items individueel toegevoegd en conform de individuele
            prijs berekend.{" "}
          </p>
        </p>
        <p>
          <h2>11. Persoonsgegevens</h2>
          <p>
            1 Dobbi verwerkt de persoonsgegevens van Klant conform de op de App
            en website gepubliceerde privacy statement.
          </p>
        </p>
        <p>
          <h2>12. Slotbepalingen</h2>
          <p>
            1 Op deze Algemene Voorwaarden en de Overeenkomst is uitsluitend
            Nederlands recht van toepassing.
          </p>
          <p>
            2 Voor zover door regels van dwingend recht niet anders wordt
            voorgeschreven, zullen alle geschillen die mochten ontstaan naar
            aanleiding van de Overeenkomst worden voorgelegd aan de bevoegde
            Nederlandse rechter in het arrondissement waar Dobbi gevestigd is.
          </p>
          <p>
            3 Indien een bepaling in deze Algemene Voorwaarden nietig blijkt te
            zijn, tast dit niet de geldigheid van de gehele Algemene Voorwaarden
            aan. Partijen zullen in dat geval ter vervanging (een) nieuwe
            bepaling(en) vaststellen, waarmee zoveel als rechtens mogelijk is
            aan de bedoeling van de oorspronkelijke bepaling gestalte wordt
            gegeven.
          </p>
          <p>
            4 Onder “schriftelijk” wordt in deze Algemene Voorwaarden tevens
            communicatie per e-mail of via de App verstaan, mits de identiteit
            van de afzender en de integriteit van de e-mail en de registratie
            via de App voldoende vaststaat.
          </p>
          <p>
            5. Deze Algemene Voorwaarden kunnen van tijd tot tijd gewijzigd
            worden door Dobbi. Bij een wijziging van de Algemene Voorwaarden
            ontvangt u altijd een notificatie van ons via de App.{" "}
          </p>
        </p>
        <p>
          <h2>13. Contactgegevens</h2>
          <p>
            Mocht u na het lezen van deze Algemene Voorwaarden vragen, klachten
            of opmerkingen hebben, neem dan gerust schriftelijk of per e-mail
            contact met ons op.
          </p>
          <p>
            Clean Clothes B.V. h.o.d.n. dobbi <br />
            Joop van den Endeplein 1 <br />
            1217 WJ Hilversum <br />
            telefoonnummer: <a href="tel:+31850187541">+31 85 0187541</a>{" "}
            <br />
            e-mail: <a href="mailto:info@dobbi.com ">info@dobbi.com</a> <br />
            KvK-nummer: 70098778 <br />
            BTW-nummer: 858140901B01
          </p>
        </p>
      </div>
    </div>
  );
};


const mapStateToProps = ({webView}) => ({wv: webView})

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Terms)
