import './ReturnLabel.scss'
import postnlLogo from '../../../images/postnl-logo.jpg'
import logo from '../../../images/logo.png'

const ReturnLabel = ({ data }) => {
    return (
        <div className="wrapper">
            <div className="headerWrapper">
            <div className="infoWrapper">
                <div className="infoTitle">
                Instructie klant:
                </div>
                <span className="infoSubtitle">
                Plak dit retourlabel over de bezorgbon
                </span>
            </div>
            <div className="logoWrapper">
                <img
                src={logo}
                width="100%"
                className="mb-2"
                />
            </div>
            </div>
            <div className="contentWrapper">
            <p className="addressOrderName">Retourlabel CWS</p>
            <div className="internalCodeWrapper">
                <div className="barCodeInternalWrapper">
                <span className="barCodeInternal">*{ data.servicePoint.bls }*</span>
                <span className="barCodeInternalInfoText">{ data.servicePoint.bls }</span>
                <p class="barCodeInternalName">
                    {data.servicePoint.serviceCompany.display}
                </p>
                </div>
            </div>
            <div className="blockWrapper">
                <p className="blockTitle">Station</p>
                <div className="stationWrapper">
                    {data.servicePoint.postNLHub ?  
                    <p className="blockValue stationText">{ data.servicePoint.postNLHub.name }</p> :
                    <p className="blockValue stationText">{ data.servicePoint.hub.name }</p>
                    }
                </div>
            </div>
            </div>
            <div className="printDivider"></div>
            <div className="barCodeWrapper">
            <div className="barCodeTitleWrapper">
                <p className="barCodeTitle">Barcode voor ophalen</p>
                <img src={postnlLogo} width="25"></img>
            </div>
            <span className="barCode">*{ data.returnLabel.label }*</span>
            <div className="barCodeInfoWrapper">
                <span className="barCodeInfoText">{ data.returnLabel.label }</span>
            </div> 
            </div>
            <div className="footerWrapper">
            <p className="footerText">Indien onbestelbaar retour naar:</p>
            <p className="footerText">Clean Clothes B.V. Antwoordnummer 11326, 1230 VB Loosdrecht</p>
            </div>
        </div>
    )
}

export default ReturnLabel