import React from 'react';
import { compose } from 'redux';
import translation from '../../../translations/Auth'
import { Button } from "../../../components/Button";
import Countdown from "../../../components/Countdown";

export class RecoverEmailSent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: 0 === this.props.cooldown
    };

    this.countdown = React.createRef();
    this.lang = localStorage.getItem("lang")
  }

  componentDidMount = () => {
    this.countdown.current.startTimer();
  };

  setValid = () => {
    this.setState({
      valid: true
    });
  };

  startTimer = () => {
    this.countdown.current.startTimer();
  };

  onButtonClick = () => {
    this.props.setCooldown(0);
  };

  render() {
    let content =
      <div style={{minHeight: 90}}>
        <h1>{ translation.emailSentBlockTitle[this.lang] }</h1>
        <span dangerouslySetInnerHTML={{ __html: translation.emailSentBlockText1[this.lang] }}/>
        <Countdown seconds={this.props.cooldown} ref={ this.countdown } onFinish={ this.setValid }/>
        <span dangerouslySetInnerHTML={{ __html: translation.emailSentBlockText2[this.lang] }}/>
      </div>
    ;

    if (this.state.valid) {
      content =
        <div style={{minHeight: 90}}>
          <p dangerouslySetInnerHTML={{ __html: translation.emailSentBlockText3[this.lang] }}/>
        </div>
    }

    return (
      <div className="user-recover-email-sent">
        { content }
        <Button
          id="recover-button-re-send-email"
          color="green"
          className={ this.state.valid ? 'checked' : '' }
          onClick={ this.onButtonClick }
          disabled={ !this.state.valid }
        >
          { translation.emailSentBlockButton[this.lang] }
        </Button>
      </div>
    )
  }
}

export default compose() (RecoverEmailSent);
