import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { urlQuery } from '../../../utils/helpers'

import DropdownList from "../components/DropdownList";
import PickupOrderHours from "../components/PickupOrderHours";
import SelectDateModal from "../components/SelectDateModal";
import MobileSelectButton from "../components/MobileSelectButton";
import { setAvaiblePickupData, setApiToken, pickupDataLoading } from "../../../redux/actions";
import translation from '../../../translations/SecondsStep'
import Api from "../../../utils/api/Api";


class SecondStep extends React.Component {
  state = {
    pickupModal: false,
    deliveryModal: false
  };

  async componentDidMount() {
    this.props.history.push(urlQuery({ page: 'select-dates' }));

    const { setAvaiblePickupData, userLocation, hasBag, pickupDataLoading, avaliablePickupDate } = this.props;
    
    try { window.dataLayer.push({ event: 'select-time' }) } catch (err) { console.log("Error", err) }

    if (avaliablePickupDate[0].default) {
      try {
        pickupDataLoading(true);
        const pickupResponse = await Api.getPickupTime(userLocation, hasBag);
        const pickup = pickupResponse.data;
        setAvaiblePickupData(pickup);
        pickupDataLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
  }

  handlePickupModalToggle = () =>
    this.setState({ pickupModal: !this.state.pickupModal });

  render() {
    const lang = localStorage.getItem("lang");
    const { pickupModal } = this.state;
    return (
      <div className="second-option-block">
        <h2>{translation.timeRecover[lang]}</h2>
        <p>{translation.timeSub[lang]}</p>
        <div className="second-option-desktop">
          <div className="dropdown-blocs">
            <DropdownList pickup />
          </div>
          <div className="avaliable-times">
            <PickupOrderHours pickup />
          </div>
        </div>
        <div className="second-option-mobile">
          <MobileSelectButton
            pickup
            title={translation.pickUp[lang]}
            onClick={this.handlePickupModalToggle}
          />
          <SelectDateModal
            pickup
            isOpened={pickupModal}
            handleToggleModal={this.handlePickupModalToggle}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  selectedPickupDate,
  selectedDeliveryDate,
  avaliableDeliveryDate,
  avaliablePickupDate,
  userLocation,
  hasBag,
  apiToken,
  pickupDataLoading
}) => ({
  selectedPickupDate,
  selectedDeliveryDate,
  avaliableDeliveryDate,
  avaliablePickupDate,
  userLocation,
  hasBag,
  apiToken,
  pickupDataLoading
});

const mapDispatchToProps = dispatch => {
  return {
    setAvaiblePickupData: data => dispatch(setAvaiblePickupData(data)),
    setApiToken: data => dispatch(setApiToken(data)),
    pickupDataLoading: data => dispatch(pickupDataLoading(data))
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SecondStep);
