export default {
  email: {
    'en' : 'E-mail',
    'nl' : 'E-mailadres'
  },
  password: {
    'en' : 'Password',
    'nl' : 'Wachtwoord'
  },
  button: {
    'en' : 'Log in',
    'nl' : 'Inloggen'
  },
  error: {
    'en': 'Invalid email or password',
    'nl': 'Ongeldig e-mailadres of wachtwoord'
  },
  recover: {
    'en': 'Forgot password?',
    'nl': 'Wachtwoord vergeten?'
  }
}