export default {
	enterNewCode: {
		'en': 'enter a new code',
		'nl': 'voer een nieuwe code in'
	},
	orEnterNewCode: {
		'en': 'or enter a new code',
		'nl': 'of voer een nieuwe code in'
	},
	fillLocationCode: {
		'en': 'Where can we pick up your order?',
		'nl': 'VOER UW LOCATIECODE IN'
	},
	locationCode: {
		'en': 'Location Code',
		'nl': 'LOCATIECODE'
	},
	locationNotFound: {
		'en': 'Unfortunately the location was not found by provided location code',
		'nl': 'Helaas is de locatie niet gevonden door de opgegeven locatiecode'
	},
	orderPlacingError: {
		'en': 'Oops! Something went wrong at our side. We have been notified and will immediately start fixing it.',
		'nl': 'Oeps! Er ging iets mis aan onze kant. We hebben een melding gekregen en zullen het zsm fixen.'
	},
	placeOrder: {
		'en': 'Place order',
		'nl': 'Plaats order'
	},
	orderPlaced: {
		'en': 'THANK YOU FOR YOUR ORDER!',
		'nl': 'BEDANKT VOOR JE ORDER!',
	},
	pickupDate: {
		'en': 'Pickup date',
		'nl': 'Ophaal datum',
	},
	pickupTime: {
		'en': 'Pickup time',
		'nl': 'Ophaal tijd',
	},
	between: {
		'en': 'Between',
		'nl': 'Tussen',
	},
	orderContact: {
		'en': '<span>If you have any questions about this order, let us know via <a href="mailto:info@dobbi.com">info@dobbi.com</a></span> <span>or call us on <a href="tel:+31 85 01 875 40">+31 85 01 875 40</a></span>',
		'nl': '<span>Heb je vragen over deze order laat het ons weten via <a href="mailto:info@dobbi.com">info@dobbi.com</a></span> <span>of bel ons op <a href="tel:+31 85 01 875 40">+31 85 01 875 40</a></span>'
	},
	fillAddress: {
		'en': 'Is this your first order?',
		'nl': 'Bestel je voor het eerst?'
	},
	fillAddressCompany: {
		'en': 'Where can we pick up your order?',
		'nl': 'Waar kunnen wij je order ophalen?'
	},
	fillAddressSub: {
		'en': 'We can pickup and deliver both at home or at the office. Check the availability for your address.',
		'nl': 'Wij kunnen je order thuis of op kantoor ophalen. Check de beschikbaarheid op jouw adres.'
	},
	enterZipcode: {
		'en': 'Zip Code',
		'nl': 'Postcode'
	},
	houseNumber: {
		'en': 'House number',
		'nl': 'Huisnummer'
	},
	addition: {
		'en': 'Addition/floor',
		'nl': 'Toevoeging'
	},
	eMail: {
		'en': 'E-mail',
		'nl': 'E-mail'
	},
	password: {
		'en': 'Login Password',
		'nl': 'Inlog Wachtwoord'
	},
	passwordNotReceived: {
		'en': 'Password not received?',
		'nl': 'Wachtwoord niet ontvangen?'
	},
	next: {
		'en': 'Check availability',
		'nl': 'Check beschikbaarheid'
	},
	existingAccount: {
		'en': 'Did your order before?',
		'nl': 'Heb je al eerder besteld?'
	},
	companySelect: {
		'en': 'Where do you work?',
		'nl': 'Voor welk bedrijf ben je werkzaam?',
	},
	companySelectPlaceholder: {
		'en': 'Select a company',
		'nl': 'Selecteer het bedrijf',
	},
	existingAccountDesc: {
		'en': 'Enter the e-mail address which you used for your previous order to log in.',
		'nl': 'Log dan in met je e-mail adres en wachtwoord waarmee je eerder bestelt hebt.'
	},
	resetPassword: {
		'en': 'Reset password',
		'nl': 'Wachtwoord opvragen'
	},
	sendLogInPassword: {
		'en': 'Log in',
		'nl': 'Inloggen'
	},
	passwordSent: {
		'en': 'Password sent',
		'nl': 'Inlog wachtwoord verzonden'
	},
	login: {
		'en': 'Log in',
		'nl': 'Inloggen'
	},
	orderCollectionInProcess(collection) {
		return {
			'en': `You have placed ${collection.items.length}/${collection.itemsLimit} of the order. After ${collection.itemsLimit} requests we will proceed the order.`,
			'nl': `Je hebt ${collection.items.length}/${collection.itemsLimit} van de bestelling geplaatst. Na ${collection.itemsLimit} verzoeken gaan we verder met de bestelling.`
		}
	},
	passwordSentDesc(email) {
		return {
			'en': `We’ve send your password to ${email}. Paste your login password below.'`,
			'nl': `We hebben uw inlog wachtwoord verzonden naar ${email}. Vul het wachtwoord hieronder in'`
		}
	},
	bannerText(appText) {
		return {
			'en': `If you experience problems during the orderproces, please let us know on <a href="mailto:info@dobbi.com">info@dobbi.com</a>${appText}.`,
			'nl': `Loopt het bestelproces niet vlekkeloos? Laat het ons weten via <a href="mailto:info@dobbi.com">info@dobbi.com</a>${appText}.`
		}
	},
	bannerTextApp(appLink) {
		return {
			'en': ` or <a target="_blank" href="${appLink}">download the dobbi app</a>`,
			'nl': ` of <a target="_blank" href="${appLink}">download de dobbi app</a>`
		}
	}
}
