import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import Header from "../components/Header";
import Footer from "../components/Footer";
import DownloadApp from "../components/DownloadApp";
import HowItWorks from "../components/HowItWorks";
import AboutDobbi from "../components/AboutDobbi";

import { langHelper } from "../utils/helpers";

import { ModalCorrect } from "../modals/ModalCorrect";
import { ModalWrongZip } from "../modals/ModalWrongZip";
import { ModalThank } from "../modals/ModalThank";

import heroImg from "../images/home-banner.jpg";
import heroImg2x from "../images/home-banner@2x.jpg";

import "../css/pages/Home.css";
import App from "../App";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 430,
    borderRadius: "10px",
    boxShadow: "0 5px 42px 0 rgba(187,201,192,0.3)"
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,.3)",
    zIndex: 10
  }
};

export const translation = {
  check01: {
    en: "Do we deliver to you?",
    nl: "Bezorgen we al bij jou?"
  },
  check02: {
    en: "Check",
    nl: "Check"
  },
  blockOnImage_01: {
    en: "Skip your Laundry & Dry Cleaning",
    nl: "Skip het Wassen & Stomen"
  },
  blockOnImage_02: {
    en: "We pick up, clean and deliver within 48 hours.",
    nl: "Wij halen op, reinigen en bezorgen binnen 48 uur."
  },
  blockOnImage_03: {
    en: "Download our app and experience a bit of dobbi magic!",
    nl: "Download onze app en ervaar het gemak van dobbi!"
  },
  subscribe_01: {
    en:
      "Subscribe and we will inform you when our service is available in your area",
    nl:
      "Schrijf je in en wij laten je direct weten wanneer onze service voor jou beschikbaar is!"
  },
  subscribe_02: {
    en: "your email address",
    nl: "Email"
  },
  subscribe_03: {
    en: "Zip code",
    nl: "Postcode"
  },
  subscribe_04: {
    en:
      "I agree to receive an email when dobbi is available in my zip code area",
    nl:
      "Ik ontvang graag een e-mail wanneer dobbi beschikbaar is in mijn postcode gebied"
  },
  subscribe_05: {
    en: "Check",
    nl: "Inschrijven"
  },
  popup_01: {
    en:
      "<h2>Thank you!</h2> <p>You will be informed when our service is available in your area!</p>",
    nl:
      "<h2>Bedankt!</h2> <p>Zodra onze service bij jou in de buurt beschikbaar is laten wij het je weten!</p>"
  },
  popup_02: {
    en:
      "<p>You are already subscribed, when the service is available you will be informed.</p>",
    nl:
      "<p>Je staat al ingeschreven, zodra onze service bij jou beschikbaar is laten wij het je weten!</p>"
  }
};

const { check01, check02 } = translation;

const Home = props => {
  return (
    <div className="home-page-wrapper">
      <Header wv={props.wv} heroImg={heroImg} heroImg2x={heroImg2x} home />
      <CheckServiceArea {...props} />
      <main>
        <AboutDobbi />
        <HowItWorks />
        {props.wv && <PriceListButton />}
        {props.wv && <DownloadApp />}
      </main>
      <Footer wv={props.wv} />
    </div>
  );
};

const PriceListButton = props => {
  const lang = localStorage.getItem("lang");
  const link = lang === "nl" ? "/nl/pricelist" : "/en/pricelist";
  const text = lang === "nl" ? "Onze prijzen" : "See our pricelist";
  return (
    <div className="button-wrapper">
      <Link to={link} className="pricelist-button checked">
        {text}
      </Link>
    </div>
  );
};
class CheckServiceArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validZip: false,
      modalCorrect: false,
      modalWrong: false,
      modalThank: false,
      postalValue: 0
    };
  }

  validateZip = e => {
    e.target.value = e.target.value.replace(/ /g, "");

    let zip = document.getElementsByName("postal-code")[0].value;
    this.setState({ validZip: !!zip.match(/\d{4}/) });
  };

  submitHandle = e => {
    e.preventDefault();
    const zip = document.getElementsByName("postal-code")[0].value;
    if (!this.state.validZip) return;
    App.inServiceArea(zip)
      .then(response => response.data.isFound)
      .then(bool => {
        bool
          ? this.setState({ modalCorrect: true })
          : this.setState({ modalWrong: true });
        this.setState({
          postalValue: zip,
          validZip: false
        });
        this.refs.postal.value = "";
      })
      .catch(error => console.log(error));
  };

  closeModal = () => {
    this.setState({
      validZip: false,
      modalCorrect: false,
      modalWrong: false,
      modalThank: false,
      postalValue: 0
    });
  };

  handleOpenThankModal = e => {
    e && e.preventDefault();
    this.setState({
      modalWrong: false,
      modalThank: true
    });
  };

  render() {
    const lang = langHelper(this.props);

    const buttonClassName = this.state.validZip ? "checked" : "";
    return (
      <div className="chech-area-wrapper">
        <div className="check-area-inner">
          <p className="check-area-text">{check01[lang]}</p>
          <form className="check-area-inputs" onSubmit={this.submitHandle}>
            <input
              type="text"
              className="postal-input"
              required
              ref="postal"
              onChange={this.validateZip}
              name="postal-code"
              placeholder="1234AB"
              autoComplete="off"
            />
            <button className={`check-form-submit ${buttonClassName}`}>
              {check02[lang]}
            </button>
          </form>
        </div>
        <ModalCorrect
          isOpen={this.state.modalCorrect}
          lang={lang}
          closeModal={this.closeModal}
        />
        <ModalWrongZip
          isOpen={this.state.modalWrong}
          lang={lang}
          closeModal={this.closeModal}
          zip={this.state.postalValue}
          handleOpenThankModal={this.handleOpenThankModal}
        />
        <ModalThank
          isOpen={this.state.modalThank}
          lang={lang}
          closeModal={this.closeModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ webView }) => ({ wv: webView });

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Home);
