import React from 'react';
import { connect } from 'react-redux';
import {addOrderData, setPlaceOrderData, setHasBag, setFormattedAddress, setUserLocation, setCleaningServices, setCleaningServicesConfig} from '../../../redux/actions';
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import dryIcon from '../../../images/order/suit.svg'
import ironedIcon from '../../../images/order/t-shirts.svg'
import shirtIcon from '../../../images/order/shirt.svg'
import shoesIcon from '../../../images/order/shoes.svg'
import bagIcon from '../../../images/order/bag.svg'
import checkMark from '../../../images/order/check-mark.svg'
import mailImg from '../../../images/mail_letter.jpg'

import { urlQuery } from '../../../utils/helpers'

import translation from '../../../translations/FirstStep'
import Api from "../../../utils/api/Api";

class FirstStep extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showHasBagBlock: false,
    };
  }

  componentWillMount = () => {
    Api.getCleaningServices().then(({ data }) => {
      if (data) {
        const WF = data.find(item => item.type === 'WF')
        const LS = data.find(item => item.type === 'LS')
        if (WF && WF.prices) {
          const washTumbleDryAndFoldWF = WF.prices.find(item => item.alias === 'wash-tumble-dry-and-fold')
          const perExtraKgWF = WF.prices.find(item => item.alias === 'per-extra-kg')
          if (washTumbleDryAndFoldWF && perExtraKgWF) {
            this.setState({ washTumbleDryAndFoldWF, perExtraKgWF });
          }
        }

        if (LS && LS.prices) {
          const perItemLS = LS.prices.find(item => item.alias === 'price-per-item')
          if (perItemLS) {
            this.setState({ perItemLS });
          }
        }
      }
    })
    Api.getCleaningServicesConfig().then(({ data }) => {
      if (data && data.prices) {
        this.props.setCleaningServicesConfig(data.prices)
        const starterDeposit = data.prices.find(item => item.alias === 'PRICE_STARTER_KIT_DEPOSIT')
        const minimalOrder = data.prices.find(item => item.alias === 'PRICE_MINIMAL_ORDER_VALUE')
        if (starterDeposit && minimalOrder) {
          this.setState({ starterDeposit, minimalOrder });
        }
      }
    })
    if (null === this.props.hasBag) {
      this.setState({
        showHasBagBlock: true
      });
    }
  };

  componentDidMount = async () => {
    if (false === this.props.userLocation.loaded) {
      Api.getMyLocations()
        .then(({ data }) => {
          if (0 < data.length) {
            this.props.setUserLocation(data[0]);
            this.props.setFormattedAddress(data[0].address + ' ' + data[0].postalCode + ' ' + data[0].city);
          }
        })
      ;
    }

    this.props.history.push(urlQuery({ page: 'select-service' }));
    try { window.dataLayer.push({ event: 'service-select' }) } catch (err) { console.log("Error", err) }
  };

  selectService = (val) => {
    const { orderData, addOrderData } = this.props;
    const data = { ...orderData, ...{[`${val}`]: !orderData[val]} };

    return addOrderData(data);
  };

  render() {
    const lang = localStorage.getItem("lang");
    const { orderData } = this.props;
    const { starterDeposit, minimalOrder, washTumbleDryAndFoldWF, perExtraKgWF, perItemLS } = this.state;
    
    let descriptionWF = translation.washAndFoldSub[lang]
    let descriptionLS = translation.shirtAndServiceSub[lang]
    if (washTumbleDryAndFoldWF && perExtraKgWF) {
      descriptionWF += ' ' + washTumbleDryAndFoldWF.nameShort + ' € ' + washTumbleDryAndFoldWF.amount + ' ' + perExtraKgWF.name + ' € ' + perExtraKgWF.amount
    }

    if (perItemLS) {
      descriptionLS += ' ' + perItemLS.name + ' € ' + perItemLS.amount
    }

    return (
      <div className="first-option-block">
        <HasBagBlock
          {...this.props}
          title={translation.hasBagTitle[lang]}
          firstPoint={translation.hasBagFirstPoint[lang]}
          secondPoint={translation.hasBagSecondPoint[lang]}
          hasBagText={translation.hasBagButtonText[lang]}
          show={ this.state.showHasBagBlock }
        />
        <h2>Service</h2>
        <p>{translation.selectService[lang]}</p>
        { Boolean(minimalOrder) && <p>{minimalOrder.name} &euro;{minimalOrder.amount}</p>}
        {
          !this.props.hasBag && Boolean(starterDeposit) &&
          <p>{starterDeposit.name} &euro;{starterDeposit.amount}</p>
        }
        <OrderOption
          id="step-3-services-checkbox-dc"
          checked={orderData.DC}
          onClick={() => this.selectService('DC')}
          title={translation.dryCleaning[lang]}
          descr={translation.dryCleaningSub[lang]}
          img={dryIcon}
        />
        <OrderOption
          id="step-3-services-checkbox-wf"
          checked={orderData.WF}
          onClick={() => this.selectService('WF')}
          title={translation.washAndFold[lang] }
          descr={descriptionWF}
          img={ironedIcon}
        />
        <OrderOption
          id="step-3-services-checkbox-ls"
          checked={orderData.LS}
          onClick={() => this.selectService('LS')}
          title={translation.shirtAndService[lang]}
          descr={descriptionLS}
          img={shirtIcon}
        />
        <OrderOption
          id="step-3-services-checkbox-io"
          checked={orderData.IO}
          onClick={() => this.selectService('IO')}
          title={translation.expertOrder[lang]}
          descr={translation.expertOrderSub[lang]}
          img={bagIcon}
        />
        <OrderNote {...this.props} />
      </div>
    )
  };
}

const HasBagBlock = props => {
  const { setHasBag, hasBag, title, firstPoint, secondPoint, hasBagText } = props;

  if(props.show === true) {
    return (
      <div className="has-bag-block">
        <div className="has-bag-header">
          {title}
        </div>
        <div className="has-bag-info">
          <div>
            <div>
              <ul>
                <li>{ firstPoint }</li>
                <li>{ secondPoint }</li>
              </ul>
            </div>
            <div
              id="step-3-services-checkbox-has-bag"
              className="has-bag-select"
              onClick={() => setHasBag(!hasBag)}
            >

              <span className={`${hasBag && 'hidden'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"/></svg>
              </span>

              <span className={`selected ${!hasBag && 'hidden'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"/></svg>
              </span>
              <span>{ hasBagText }</span>
            </div>
          </div>
          <div className="has-bag-image">
            <img src={ mailImg } alt="mail_letter" />
          </div>
        </div>
      </div>
    );
  } else {
    return ''
  }
};

const OrderOption = props => {
  const { img, title, descr, checked } = props;
  return (
    <div {...props} className={`order-option ${checked && 'checked'}`}>
      <div className="left-option-block">
        <img src={img} alt="" />
      </div>
      <div className="right-option-block">
        <span className="option-title">{title}</span>
        <span className="option-descr">{descr}</span>
      </div>
      {checked && <img className="order-check-mark" src={checkMark} alt="" />}
    </div>
  );
};

const OrderNote = props => {
  const { setPlaceOrderData } = props;
  const textarea = React.createRef();
  const handleNoteFocus = () => textarea.current.focus();
  const lang = localStorage.getItem("lang");

  const onChangeTextarea = notes => {
    const data = { notes };
    setPlaceOrderData(data);
  };

  return (
    <div className="order-option order-note" onClick={() => handleNoteFocus()}>
      <p className="order-note-title">{translation.addNotes[lang]}</p>
      <div className="order-textarea-wrapper">
        <textarea
          id="step-3-services-textarea-note"
          ref={textarea}
          placeholder={translation.addNotesDesc[lang]}
          onChange={e => onChangeTextarea(e.target.value)}
        />
      </div>
      <span>{translation.optional[lang]}</span>
    </div>
  );
};

const mapStateToProps = ({
  currentOrderScreen,
  orderData,
  hasBag,
  currentOrderStep,
  userData,
  formattedAddress,
  userLocation,
  webView,
  cleaningServices,
  cleaningServicesConfig
}) => ({
  currentOrderScreen,
  currentOrderStep,
  orderData,
  hasBag,
  userData,
  formattedAddress,
  userLocation,
  webView,
  cleaningServices,
  cleaningServicesConfig
});

const mapDispatchToProps = dispatch => {
  return {
    addOrderData: data => dispatch(addOrderData(data)),
    setPlaceOrderData: data => dispatch(setPlaceOrderData(data)),
    setHasBag: data => dispatch(setHasBag(data)),
    setFormattedAddress: data => dispatch(setFormattedAddress(data)),
    setUserLocation: data => dispatch(setUserLocation(data)),
    setCleaningServices: data => dispatch(setCleaningServices(data)),
    setCleaningServicesConfig: data => dispatch(setCleaningServicesConfig(data))
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FirstStep);
