export default {
    time: {
        'en': 'Please select your pick-up & delivery time',
        'nl': 'Selecteer je ophaal- & bezorgmoment',
    },
    timeRecover: {
        'en': 'Please select your pick-up time',
        'nl': 'Selecteer je ophaal',
    },
    timeSub: {
        'en': 'We pickup & deliver in 2 hour timeslots between 8 AM & 8 PM on Monday to Friday.',
        'nl': 'Wij halen op en bezorgen binnen een tweeuurs-tijdvak, tussen 08:00 & 20:00, van maandag t/m zaterdag.',
    },
    pickUp: {
        'en': 'Pick-up',
        'nl': 'Ophaaldatum',
    },
    delivery: {
        'en': 'Delivery',
        'nl': 'Bezorgmoment',
    }
}