import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"

import Notification from './components/Notification.jsx'
import HomeBanner from "./components/HomeBanner"

import {
  setUserLocation,
  addUserData,
  setOrderScreen,
  setFormattedAddress,
  setNotificationData,
  setUserData,
  setSubmitting
} from '../../redux/actions'

import translation from '../../translations/FirstOrderScreen'
import Api from '../../utils/api/Api'
import Grid from '@material-ui/core/Grid'
import ReturnLabel from './components/ReturnLabel'

const FirstOrderScreen = props => {
  const { notification } = props;
  // const
  return (
    <main className="order-block-body order-block-body-grid">
      {notification.status && <Notification />}
      <CheckLocationInputs {...props} />
    </main>
  )
}

const ShowError = ({error}) => {
  return (
    <div className="service-point-info service-point-error">
      { error }
    </div>
  )
}

const ShowReturnLabel = ({ returnLabel }) => {
  return (
    <div className="printPage">
      <ReturnLabel data={returnLabel}/>
    </div>
  )
}

const CheckLocationInputs = ({setSubmitting}) => {
  const lang = localStorage.getItem('lang')
  const [state, setState] = useState({
    showNumpad: true,
    code: null,
    error: null,
    variation: 1,
    loading: false,
    submitting: false,
  })

  const onSubmit = (value) => {
    updateState({showNumpad: false})
    loadShipment(value)
  }

  const loadShipment = (value) => {
    updateState({loading: true})

    Api.placeReturnOrder(value).then(({ data }) => {
      updateState({
        returnLabel: data,
        showNumpad: false,
        error: null,
        code: value,
        loading: false,
      })
    }).catch(async () => {
      updateState({
        returnLabel: null,
        showNumpad: false,
        error: translation.locationNotFound[lang],
        code: null,
        loading: false,
      })
    })
  }

  const clear = (e) => {
    e.preventDefault()

    updateState({
      showNumpad: true,
      returnLabel: null,
      error: null,
      code: null,
      variation: state.variation + 1,
      loading: false,
      submitting: false,
    })
  }

  const updateState = (newState) => {
    const oldState = {...state}

    setState({...oldState, ...newState})
  }

  const onChangeCode = (e) => {
    const code = e.target.value
    updateState({ code })
  }

  useEffect(() => {
    if (state.code && state.code.length === 5) {
      loadShipment(state.code)
    }
  }, [state.code])

  return (
    <form key={state.variation} className="first-screen-inputs" onSubmit={e => {e.preventDefault();}}>
      <Grid container spacing={3} justify="center" alignItems="flex-start">
        <Grid item md={7} sm={11} xs={12} className="inputs-wrapper">
          <Grid container spacing={3} justify="flex-start" alignItems="center">
            <Grid item xs={12}>
              <h1 className="inputs-wrapper-title">{state.returnLabel ? translation.orderPlaced[lang] : translation.fillLocationCode[lang]}</h1>
            </Grid>
          </Grid>
          {/* <Number
            onChange={() => {}}
            label={translation.locationCode[lang]}
            placeholder={'Click to enter the code'}
            decimal={false}
            negative={false}
            position="top"
            sync={true}
            inline={true}
            show={state.showNumpad && !state.loading}
            enter={onSubmit}
            loading={state.loading}
            leadingZeros={true}
          /> */}
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <b>BLS code:</b>
            <input value={state.code} onChange={onChangeCode} />
          </div>
          { (state.error && !state.loading) &&
          <Grid container spacing={3} justify="flex-start" alignItems="center">
            <Grid item xs={12}>
              <ShowError error={state.error} />
            </Grid>
          </Grid>
          }

          { (state.returnLabel && !state.loading) &&
          <Grid container spacing={3} justify="flex-start" alignItems="center">
            <Grid item xs={12}>
              <ShowReturnLabel returnLabel={state.returnLabel} />
            </Grid>
          </Grid>
          }

          {(!state.showNumpad && !state.loading && !state.submitting) &&
            <Grid container spacing={3} justify="flex-start" alignItems="center">
              <Grid item xs={12}>
                <a href="#" className="service-point-clear service-point-info" onClick={clear}>{state.code ? translation.orEnterNewCode[lang] : translation.enterNewCode[lang]}</a>
              </Grid>
            </Grid>
          }
        </Grid>
        <Grid item md={5} sm={11} xs={12}>
          <HomeBanner version={ 1 } />
        </Grid>
      </Grid>
    </form>
  )
}

const mapStateToProps = ({ webView, userLocation, userData, notification }) => ({
  wv: webView,
  userLocation,
  userData,
  notification
});

const mapDispatchToProps = dispatch => {
  return {
    setOrderScreen: data => dispatch(setOrderScreen(data)),
    setFormattedAddress: data => dispatch(setFormattedAddress(data)),
    setUserLocation: data => dispatch(setUserLocation(data)),
    addUserData: data => dispatch(addUserData(data)),
    setNotificationData: data => dispatch(setNotificationData(data)),
    setUserData: data => dispatch(setUserData(data)),
    setSubmitting: data => dispatch(setSubmitting(data)),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FirstOrderScreen);
