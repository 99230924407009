import React from 'react';

import { NavLink } from 'react-router-dom';
import '../css/components/ExploreBlock.css';
import { translation } from '../utils/dataFiles/exploreLoco';

import ExploreImg1 from "../images/explore-1.jpg";
import ExploreImg2 from "../images/explore-2.png";
import ExploreImg3 from "../images/explore-3.jpg";

const { first_01, first_02, first_03, first_04, first_05, first_06, first_07, firstText_01, firstText_02, firstText_03, firstText_04, firstText_05, firstText_06, second_01, second_01_0, second_02, secondText_01, secondText_02, secondText_03, secondText_04, secondText_05, secondText_06, secondText_07, secondText_08, secondText_09, secondText_10, secondText_11, secondText_12, third_01, third_02, third_03, third_04, third_05, third_06 } = translation;

const ContactInfo = props => {
  const lang = localStorage.getItem("lang");

  return (
    <div className="explore-block_wrapper">
      <div className="explore-block">
        <div className="explore-col">
          <div className="explore-item">
            <img src={ExploreImg1} alt="" />
            <div className="explore-item_wrapper">
              <p className="explore-item__title">{first_01[lang]}</p>
              <p className="explore-item__descr">
                {firstText_01[lang]}
              </p>
              <p className="explore-item__title">{first_02[lang]}</p>
              <p className="explore-item__descr">
                {firstText_02[lang]}
              </p>
              <p className="explore-item__title">{first_03[lang]}</p>
              <p className="explore-item__descr">
                {firstText_03[lang]}
              </p>
              <p className="explore-item__title">{first_04[lang]}</p>
              <p className="explore-item__descr">
                {firstText_04[lang]}
              </p>
              <p className="explore-item__title">{first_05[lang]}</p>
              <p className="explore-item__descr">
                {firstText_05[lang]}
              </p>
              <p className="explore-item__title">{first_06[lang]}</p>
              <p className="explore-item__descr">
                {firstText_06[lang]}
                <br />
                {first_07[lang]}
              </p>
            </div>
          </div>
        </div>
        <div className="explore-col">
          <div className="explore-item">
          <img src={ExploreImg2} alt="" />
            <div className="explore-item_wrapper">
              <p className="explore-item__title">
                {second_01[lang]}
              </p>
              <p className="explore-item__descr">
                {secondText_01[lang]}
                <br />
                <br />
                {second_01_0[lang]}
              </p>
              <p className="explore-item__title">{second_02[lang]}</p>
              <p className="explore-item__descr">
                <ol>
                  <li>{secondText_02[lang]}</li>
                  <li>
                    {secondText_03[lang]}
                  </li>
                  <li>
                    {secondText_04[lang]}
                  </li>
                  <li>
                    {secondText_05[lang]}
                  </li>
                  <li>
                    {secondText_06[lang]}
                  </li>
                  <li>
                    {secondText_07[lang]}
                  </li>
                  <li>
                    {secondText_08[lang]}
                  </li>
                  <li>{secondText_09[lang]}</li>
                </ol>
                {secondText_10[lang]} <br />
                {secondText_11[lang]} <br />
                {secondText_12[lang]} <br />
              </p>
            </div>
          </div>
        </div>
        <div className="explore-col">
          <div className="explore-item">
          <img src={ExploreImg3} alt="" />
            <div className="explore-item_wrapper">
              <p className="explore-item__title">ABOUT dobbi</p>
              <p className="explore-item__descr">
                They say climbing Everest, or winning the Nobel prize is a great
                feat. We say breaking old routines is just as big of a victory.
                For we believe life shackled to the old ways is life without
                imagination and wonder.
                <br />
                <br />
                We applaud the unsung heroes who break out of their autopilot
                and discover a bit of magic. Like the girl who introduced
                wonders of toasted marshmallows, When the world knew eating it
                only as it is.
                <br />
                <br />
                We’re here to bring wonder to your mind-numbing, soul-draining
                duties like laundry. We offer a level of care to the standard of
                Persil, picked up and delivered on demand by PostNL.
                <br />
                <br />
                Oh we know there’s nothing wrong with doing your laundry the way
                it is, But in just a few swipes, you’ll start experiencing a
                life filled with small wonders.
                <br />
                <br />
                Skip your laundry Dobbi
              </p>
            </div>
          </div>
          <div className="explore-item">
            <div className="explore-item_wrapper">
              <p className="explore-item__title">{third_01[lang]}</p>
              <p className="explore-item__descr">
                <ul>
                  <li>{third_02[lang]}</li>
                  <li>{third_03[lang]}</li>
                  <li>{third_04[lang]}</li>
                </ul>
                <br />
                {third_05[lang]}
              </p>
              <div className="button_wrapper">
                <NavLink to={`/${lang}/faq`}> {third_06[lang]} </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
