import React from "react";
import {Link} from 'react-router-dom';

import "../css/components/ContactInfo.css";

const translation = {
  contact_01: {
    en: "Get in touch",
    nl: "Neem contact met ons op"
  },
  contact_02: {
    en: "With dobbi, you don’t have to worry about doing your laundry or trip to the dry cleaners anymore.",
    nl: "Met dobbi hoef jij je geen zorgen meer te maken over de was of het bezoek aan de stomerij"
  },
  contact_03: {
    en: "You just place an order and we’ll do it for you. It’s like you can just skip doing your laundry and dry cleaning. We pick up, clean & deliver your order when it suits you.",
    nl: "Plaats een order en wij regelen de rest. Wij halen het op, maken alles betoverend shoon en bezorgen wanneer het jou uitkomt."
  },
  contact_04: {
    en: "Download the app and start skipping!",
    nl: "Download de app en skip de rest!"
  },
  contact_05: {
    en: "dobbi Powered by Persil",
    nl: "dobbi Powered by Persil"
  },
  contact_06: {
    en: "Contact us:",
    nl: "Neem contact op:"
  },
  contact_07: {
    en: "Monday to Friday: 08:30 – 21:00",
    nl: "Maandag t/m vrijdag: 08:30 - 21:00"
  },
  contact_08: {
    en: "Saturday: 09:00 – 17:00",
    nl: "Zaterdag: 09:00 - 17:00"
  },
  contact_09: {
    en: "Check our FAQ",
    nl: "Bekijk onze FAQ"
  },
}

const { /*contact_01, contact_02, contact_03, contact_04, contact_05,*/ contact_06, contact_07, contact_08, contact_09 } = translation;

const ContactInfo = props => {
  const lang = localStorage.getItem("lang");

  return (
    <section className="contact_info__wrapper">
      <div className="contact-info">
        <div>

          <p>
            <span>{contact_06[lang]}</span>
          </p>
          <p className="schedule-descr">
            {contact_07[lang]}<br />
            {contact_08[lang]}
          </p>
          <p>
            {!props.wv
              ? (<Link to={`/${lang}/faq?webview=true`}>{contact_09[lang]}</Link>)
              : (<Link to={`/${lang}/faq`}>{contact_09[lang]}</Link>)}
          </p>
        </div>
        <div>
          <p className="email-container">
            Email: <br />
            <a href="mailto:info@dobbi.com">info@dobbi.com</a>
          </p>
          <p className="call-container">
            Call free: <br />
            <a href="tel:+31 85 01 875 40">+31 85 01 875 40</a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
