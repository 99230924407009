export default {
    title: {
        'en': `Congratulations, we can't wait to pickup your order!`,
        'nl': 'Gefeliciteerd, wij kunnen niet wachten om je order op te halen!',
    },
    yourOrderSent: {
        'en': 'Your order has been processed succesfully, we will keep you updated.',
        'nl': 'Je order is geplaatst, we houden je op de hoogte.',
    },
    faq: {
        'en': 'Any lingering questions? Click here for the Frequently Asked Questions',
        'nl': 'Vragen? Klik hier om naar onze FAQ te gaan ',
    },
    downloadApp: {
        'en': 'Download your app',
        'nl': 'Download je app',
    },
    downloadAppDesc: {
        'en': 'We can make it even simpler, by downloading our dobbi app you can place an order with just a few swipes.',
        'nl': 'Wij hebben ook een app, download deze om nog makkelijker een order te plaatsen.',
    },
}