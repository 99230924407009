import 'babel-polyfill';
import 'element-closest';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import App from './App';
import { unregister } from './registerServiceWorker';
import './css/index.css';
import 'normalize.css';

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
unregister();
