export function storageGetUserSession() {
  let token = localStorage.getItem('sessionToken');
  let refreshToken = localStorage.getItem('sessionRefreshToken');
  let sessionType = localStorage.getItem('sessionType');

  return {
    type: sessionType || 'anon',
    token: token || '',
    refreshToken: refreshToken || ''
  };
}

export async function storageSetUserSession(session) {
  await localStorage.setItem('sessionToken', session.token);
  await localStorage.setItem('sessionRefreshToken', session.refreshToken);
  await localStorage.setItem('sessionType', session.type);
}