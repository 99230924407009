import moment from 'moment'
import 'moment/locale/nl'

export const formatDst = (datetime, format = 'MMMM DD YYYY HH:mm', tz = 'Europe/Amsterdam') => {
  if (!datetime) {
    return null
  }

  // const janOffset = moment({ M: 0, d: 1 }).utcOffset()
  // const julOffset = moment({ M: 6, d: 1 }).utcOffset()
  // const stdOffset = Math.min(janOffset, julOffset)

  return moment.tz(datetime, tz).locale(locale()).format(format)
}

const locale = () => {
  return localStorage.getItem('lang') || 'en'
}