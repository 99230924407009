import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ThemeProvider } from '@material-ui/core/styles'

import OrderBlockHeader from './components/OrderBlockHeader'
import OrderWrapper from './components/OrderWrapper'
import FirstOrderScreen from './FirstOrderScreen'
import './Order.scss'
import { theme } from '../../theme'

const CollectionOrder = props => {
  const orderScreens = () => {
    return {
      1: <FirstOrderScreen />,
    };
  };

  const orderScreensList = orderScreens();
  const { currentOrderScreen } = props;
  const conditionToHide = currentOrderScreen === 4;

  return (
    <ThemeProvider theme={theme}>
      <OrderWrapper {...props}>
        {!conditionToHide && (
          <OrderBlockHeader checkout={currentOrderScreen !== 1} />
        )}
        {orderScreensList[currentOrderScreen]}
      </OrderWrapper>
    </ThemeProvider>
  );
};

const mapStateToProps = ({ webView, currentOrderScreen }) => ({
  wv: webView,
  currentOrderScreen,
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(CollectionOrder);
