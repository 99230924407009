import React from 'react';
import { connect } from 'react-redux';
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { urlQuery } from '../../../utils/helpers'
import { addUserData, setPlaceOrderData, checkLastScreen } from '../../../redux/actions';
import { dateFormatter, showFromToTime } from '../../../utils/helpers';
import { ModalRecoverySent } from '../../../modals/ModalRecoverySent.jsx'

import Notification from '../components/Notification';
import translation from '../../../translations/ThirdStep'


const ThirdStep = props => {
  const lang = localStorage.getItem("lang");
  const {
    selectedPickupTime,
    selectedDeliveryTime,
    selectedPickupDate,
    selectedDeliveryDate,
    placeOrderData,
    setPlaceOrderData,
    notification
  } = props;

  const setUserNote = e => {
    const notes = e.target.value;
    const data = { ...placeOrderData, notes };
    setPlaceOrderData(data);
  };

  return (
    <div className="third-option-block">
      {notification.status && <Notification />}

      <h2 className="third-block-header">{translation.title[lang]}</h2>

      <OverviewSection>
        <div className="selected-services">
          <div className="overview-service">
            <span className="service-section-title">{translation.pickUp[lang]}</span>
            <span>{dateFormatter(selectedPickupDate.date)}</span>
            <span>{showFromToTime(selectedPickupTime)}</span>
          </div>
          <div className="overview-service">
            <span className="service-section-title">{translation.delivery[lang]}</span>
            <span>{dateFormatter(selectedDeliveryDate.date)}</span>
            <span>{showFromToTime(selectedDeliveryTime)}</span>
          </div>
        </div>
      </OverviewSection>
      <OverviewSection>
        <p className="overview-section-title">{translation.note[lang]}</p>
        <textarea
          id="step-5-order-textarea-note"
          className="note-textarea"
          placeholder={translation.noteSub[lang]}
          onChange={setUserNote}
          value={placeOrderData.notes ? placeOrderData.notes : ''}
        />
      </OverviewSection>

      <ContactDetails {...props} />
    </div>
  );
};

const OverviewSection = props => {
  const { children, className } = props;
  return (
    <section {...props} className={`${className} overview-section`}>
      {children}
    </section>
  );
}

class ContactDetails extends React.Component {
  state = {
    modalRecoverySent: false,
    userEmail: ''
  }

  setUserData = e => {
    const { userData, addUserData } = this.props;
    switch (e.target.name) {
      case 'contactPhone': {
        const contactPhone = e.target.value;
        const data = { ...userData, contactPhone };
        addUserData(data);
        break;
      }
      case 'contactName': {
        const contactName = e.target.value;
        const data = { ...userData, contactName };
        addUserData(data);
        break;
      }
      case 'contactEmail': {
        const contactEmail = e.target.value;
        const data = { ...userData, contactEmail };
        addUserData(data);
        break;
      }
      default:
        break;
    }
  };

  handleCheckboxToggle = e => {
    const { checkLastScreen } = this.props;
    checkLastScreen(e.target.checked);
  };


  componentDidMount() {
    this.props.history.push(urlQuery({ page: 'confirm-order' }));
    try { window.dataLayer.push({ event: 'order-overview' }) } catch (err) { console.log("Error", err) }
  }

  render() {
    const lang = localStorage.getItem("lang");

    return (
      <OverviewSection className="contact-section">
        <p>{translation.contactDetailsSub[lang]}</p>
        <div className="contact-section-inputs">
          <input
            id="step-5-user-input-name"
            placeholder={translation.name[lang]}
            name="contactName"
            type="text"
            onChange={this.setUserData}
          />
          <input
            id="step-5-user-input-phone"
            placeholder={translation.phoneNumber[lang]}
            name="contactPhone"
            onChange={this.setUserData}
            type="text"
          />
          <input
            id="step-5-user-input-email"
            placeholder={translation.email[lang]}
            name="contactEmail"
            onChange={this.setUserData}
            type="text"
          />
          <div className="checkbox-input">
            <input
              id="step-5-user-checkbox-order-updates"
              type="checkbox"
              defaultChecked={false}
              onChange={this.handleCheckboxToggle}
            />
            <label
              id="step-5-user-checkbox-accept-terms"
              htmlFor="step-5-user-checkbox-order-updates"
              dangerouslySetInnerHTML={{__html: translation.keepInformedCompany[lang]}}
            />
          </div>
        </div>
        <ModalRecoverySent
          isOpen={this.state.modalRecoverySent}
          email={this.state.userEmail}
          lang={lang}
          closeModal={this.closeModal}
        />
      </OverviewSection>
    );
  }
}

const mapStateToProps = ({
  selectedPickupTime,
  selectedDeliveryTime,
  selectedPickupDate,
  selectedDeliveryDate,
  placeOrderData,
  userData,
  orderData,
  apiToken,
  notification,
  checkedLastScreen
}) => ({
  selectedPickupTime,
  selectedDeliveryTime,
  selectedPickupDate: selectedPickupDate,
  selectedDeliveryDate: selectedDeliveryDate,
  placeOrderData,
  userData,
  orderData,
  token: apiToken,
  notification,
  checkedLastScreen
});

const mapDispatchToProps = dispatch => {
  return {
    addUserData: data => dispatch(addUserData(data)),
    checkLastScreen: data => dispatch(checkLastScreen(data)),
    setPlaceOrderData: data => dispatch(setPlaceOrderData(data)),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ThirdStep);
