export const translation = {
  first_01:{
    en: "YOUR FIRST ORDER",
    nl: "JE EERSTE ORDER"
  },
  firstText_01:{
    en:"If you have just skipped your Laundry&Dry Cleaning for the first time or if you are thinking about skipping it then here is some extra info for you:",
    nl:"Heb je zojuist je eerste order geplaatst of sta je op het punt dit te doen? Dan vind je hieronder meer informatie over het proces."
  },
  first_02:{
    en: "STARTERKIT:",
    nl: "STARTPAKKET"
  },
  firstText_02:{
    en:"After you have placed your first order we will send you a personalized laundrybag the next working day. This bag fits about 12kg of laundry&drycleaning.",
    nl:"Na het plaatsen van je eerste order ontvang je de volgende werkdag een gepersonaliseerde waszak. Er past ongeveer 12kg aan kleding in deze zak."
  },
  first_03:{
    en: "CHECK:",
    nl: "CONTROLEER"
  },
  firstText_03:{
    en:"Make sure all the pockets of your clothes are empty. Remove all pins, jewelry, coins, pens, etc. Please don’t put any hangers or garment covers in the laundry bag.",
    nl:"Zorg ervoor dat alle zakken van je kleding leeg zijn. Verwijder broches, sieraden, muntstukken, pennen etc. Plaats geen hangers en andere kledinghoezen in de waszak."
  },
  first_04:{
    en: "LAUNDRY BAG:",
    nl: "WASZAK"
  },
  firstText_04:{
    en:`Place all the items in the bag. \n
    If you have chosen both Wash&Fold and Dry Cleaning&Ironing it’s best to put the Wash&Fold items on the bottom and the Dry Cleaning&Ironing on top.
    Pull the cord and tighten the seal.
    Make sure there is a card with barcode attached to the bag.`,
    nl:`Plaats alle artikelen in de waszak. \n
    Als je hebt gekozen voor zowel Stomen&Strijken en Wassen&Vouwen kan je het beste de Wassen&Vouwen artikelen op de bodem van de waszak plaatsen en de Stomen&Strijken artikelen erbovenop.
    Trek aan het koord en doe de sluiting dicht.
    Controleer of er een pasje met barcode aan de tas is bevestigd.`
  },
  first_05:{
    en: "PICK-UP:",
    nl: "OPHALEN:"
  },
  firstText_05:{
    en:"The PostNL driver will pick up the bag within your chosen 2-hour time slot.",
    nl:"De PostNL-chauffeur haalt de waszak op in het door jou gekozen 2 uur’s tijdvak."
  },
  first_06:{
    en: "NEXT STEPS:",
    nl: "VOLGENDE STAPPEN"
  },
  firstText_06:{
    en:"If you want to know more about the next steps go to “STEP-BY-STEP TO CLEAN CLOTHES”.",
    nl:"Als je meer wilt weten over de volgende stappen ga dan naar \"STAP-VOOR-STAP NAAR SCHONE KLEDING\""
  },
  first_07:{
    en: "If you have any questions please contact us!",
    nl: "Neem bij vragen contact met ons op!"
  },
  second_01:{
    en: "STEP-BY-STEP TO CLEAN CLOTHES",
    nl: "STAP VOOR STAP NAAR SCHONE KLEDING"
  },
  secondText_01:{
    en: "Yes, skipping your laundry is super easy with dobbi! You order, we pick-up, do our magic and deliver in 48 hours. But of course, there are some small steps in between that you might be interested in.",
    nl: "Met dobbi is het skippen van het wassen&stomen supergemakkelijk! Je plaatst een order, wij halen het op, reinigen en bezorgen binnen 48 uur. Natuurlijk zitten er nog een aantal tussenstappen in dit proces die we graag met je delen:"
  },
  second_01_0:{
    en: "Here is the full step-by-step process to clean clothes:",
    nl: "Het stap-voor-stap proces naar schone kleding:"
  },
  second_02:{
    en:"Skip your laundry",
    nl:"Skip your laundry"
  },
  secondText_02:{
    en:"You place an order.",
    nl:"Plaats een order. "
  },
  secondText_03:{
    en:"You receive the starter kit with your personalized laundrybag the next working day.",
    nl:"Ontvang het startpakket."
  },
  secondText_04:{
    en:"The PostNL driver will pick up your clothes in your chosen 2-hour time slot.",
    nl:"De PostNL-chauffeur haalt de order op in het door jou gekozen 2 uur’s tijdvak."
  },
  secondText_05:{
    en:"We process your order on the next working day. You will receive an overview of your items and pricing by email. A payment link is included as well.",
    nl:"Wij verwerken je order de volgende werkdag. Je ontvangt een overzicht van jouw artikelen en de prijs per mail. In deze email staat ook een betaallink."
  },
  secondText_06:{
    en:"Complete the payment of your order by using the payment link.",
    nl:"Betaal je order via de betaallink."
  },
  secondText_07:{
    en:"The PostNL driver will deliver your cleaned clothes on your chosen day, again within a 2-hour time slot.",
    nl:"De PostNL-chauffeur bezorgt je bestelling op de door jou gekozen dag, uiteraard binnen de 2 uur die jij hebt aangegeven."
  },
  secondText_08:{
    en:"Your order is delivered in your personal Dry Cleaning bag and/or Wash&Fold bag.",
    nl:"Je kleding wordt terug geleverd in je persoonlijke Stomerijhoes en/of Wassen & Vouwen-tas."
  },
  secondText_09:{
    en:"Please use these bags with your next orders.",
    nl:"Gebruik deze tassen ook voor je volgende orders."
  },
  secondText_10:{
    en:"If you have any questions please contact us!",
    nl:"Neem bij vragen contact met ons op!"
  },
  secondText_11:{
    en:"Phone: +31 85 01 875 40",
    nl:"Telefoon: +31 85 01 875 40"
  },
  secondText_12:{
    en:"Email: info@dobbi.com",
    nl:"Email: info@dobbi.com"
  },
  third_01:{
    en: "PLEASE CONTACT US IF:",
    nl: "Neem direct contact met ons op wanneer:"
  },
  third_02:{
    en: "Your items don’t fit in one bag.",
    nl: "Je items niet in een waszak passen."
  },
  third_03:{
    en: "There is a problem with your pick up or delivery.",
    nl: "Er is een probleem met het ophalen of bezorgen"
  },
  third_04:{
    en: "You have any other questions!",
    nl: "Je hebt een andere vraag!"
  },
  third_05:{
    en: "Or check out our frequently asked questions!",
    nl: "Of check onze veel gestelde vragen!"
  },
  third_06:{
    en: "Frequently asked questions!",
    nl: "Veel gestelde vragen!"
  },

}
  