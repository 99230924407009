
import React from 'react'
import Modal from 'react-modal';
import { customStyles } from '../pages/Home'
import '../css/modals/Modal.css'

const translations = {
    thank01: {
        en: "Thank you!",
        nl: "Dankjewel!"
    },
    thank02: {
        en: "If the supplied email address is known to us, you will receive an email with a temporary password. If you do not get an email within the next 2 minutes, please contact our customer support.",
        nl: "Als je email bij ons bekend is, zal een tijdelijk wachtwoord naar je opgestuurd worden. Ontvang je niets de komende 2 minuten, neem dan contact op met onze klantenservice."
    }
}

const { thank01, thank02 } = translations;


export const ModalRecoverySent = (props) => {
    const el = document.querySelector("#root");
    const lang = props.lang;

    return (
        props.isOpen && <div className="modal-wrapper">
            <Modal
                isOpen={props.isOpen}
                onRequestClose={props.closeModal}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                appElement={el}
                style={customStyles}
            >
                <button onClick={props.closeModal} className="close-button">&#10005;</button>
                <div className="modal-wrapper">
                    <div className="modal-inner modal-full">
                        <h2>{thank01[lang]}</h2>
                        <p>{thank02[lang]}</p>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
