import React from 'react';
import { connect } from 'react-redux';

import { setOrderScreen } from '../../../redux/actions';
import location from '../../../images/order/location.svg';
import mobileBullet from '../../../images/order/mobile-order-bullet.svg';
import mobileBulletActive from '../../../images/order/mobile-order-bullet-active.svg';

import translation from '../../../translations/OrderBlockHeader'
import {userIs} from "../../../utils/helpers";

const OrderBlockHeader = props => {
  const lang = localStorage.getItem("lang");

  const OrderHeader = props => {
    return (
      <header className="order-header">
        <div className="logo-block">
          <img src={location} alt="" />
        </div>
        <div className="text-block">
          <p>{translation.titleReCover[lang]}</p>
          <p className="bold-text">
          </p>
        </div>
      </header>
    );
  };
  const CheckoutHeader = props => {
    const { setOrderScreen, currentOrderStep, formattedAddress } = props
    const page = (new URLSearchParams(window.location.search)).get('page')

    if (
      !formattedAddress
      && localStorage.getItem('entryPoint') && ['re-use', 're-cover'].includes(localStorage.getItem('entryPoint'))
      && page
      && 'order-placed' !== page
    ) {
      window.location = '/logout'

      return null
    }

    return (
      <div className="checkout-header-wrapper">
        <header className="checkout-header">
          <div className="left-part">
            <div className="logo-block">
              <img src={location} alt="" />
            </div>
            <div className="text-block">
              <p className="text-block-title desctop">{translation.locationRecover[lang]}</p>
              <p className="text-block-title mobile"></p>
              <p className="bold-text">{ formattedAddress }</p>
            </div>
          </div>
          {
            false === userIs('user') &&
            <div className="right-part">
              <span
                className="right-mobile-edit"
                onClick={() => setOrderScreen(1)}
              >
                {translation.edit[lang]}
              </span>
                <span
                  id="clear-button-change-address"
                  className="right-edit"
                  onClick={() => setOrderScreen(1)}
                >
                {translation.editAddress[lang]}
              </span>
            </div>
          }

        </header>
        <div className="mobile-order-steps">
          <img src={mobileBulletActive} alt="" />
          <img
            src={
              currentOrderStep === 2 || currentOrderStep === 3
                ? mobileBulletActive
                : mobileBullet
            }
            alt=""
          />
          <img
            src={currentOrderStep === 3 ? mobileBulletActive : mobileBullet}
            alt=""
          />
        </div>
      </div>
    );
  };
  return props.checkout ? (
    <CheckoutHeader {...props} />
  ) : (
      <OrderHeader {...props} />
    );
};

const mapStateToProps = ({ currentOrderStep, formattedAddress, session }) => ({
  currentOrderStep,
  formattedAddress,
  session
});

const mapDispatchToProps = dispatch => {
  return {
    setOrderScreen: data => dispatch(setOrderScreen(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderBlockHeader);
