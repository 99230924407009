import React from 'react';
import { connect } from 'react-redux';
import { resetStorage } from '../redux/actions';

const NavLinks = props => {
  return (
    <nav>
      {props.children}
    </nav>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    resetStorage: () => dispatch(resetStorage()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(NavLinks);
