import React from 'react';

import info from '../../images/order/info-blue-icon.svg'

import translation from '../../translations/FourthOrderScreen'

const FourthOrderScreen = props => {
  const lang = localStorage.getItem('lang')

  // window.history.replaceState(null, null, "?page=order-complete")
  // try { window.dataLayer.push({ event: 'order-complete' }) } catch (err) { console.log("Error", err) }

  return (
    <div className="fourth-screen-wrapper">
      <div className="congratulation-block">
        <h2>{translation.title[lang]}</h2>
        <p>{translation.yourOrderSent[lang]}</p>
        <a href={`https://dobbi.com/${lang}/faq`} className="fourth-screen-faq-button">
          <img src={info} alt="" />
          <span>{translation.faq[lang]}</span>
        </a>
      </div>
    </div>
  );
};

export default FourthOrderScreen;
