export const pricesEn = [
  {
    name: "Shirt",
    price: "€ 3.50"
  },
  {
    name: "Blouse",
    price: "€ 6.95"
  },
  {
    name: "Blouse silk",
    price: "€ 9.95"
  },
  {
    name: "Trousers",
    price: "€ 8.95"
  },
  {
    name: "Blazer",
    price: "€ 10.95"
  },
  {
    name: "Waistcoat",
    price: "€ 6.95"
  },
  {
    name: "Tie",
    price: "€ 7.95"
  },
  {
    name: "Jumper",
    price: "€ 6.95"
  },
  {
    name: "T-shirt / Polo",
    price: "€ 4.45"
  },
  {
    name: "Jeans",
    price: "€ 5.95"
  },
  {
    name: "Skirt",
    price: "€ 8.95"
  },
  {
    name: "Dress",
    price: "€ 12.95"
  },
  {
    name: "Summer jacket",
    price: "€ 18.95"
  },
  {
    name: "Wintercoat",
    price: "€ 22.95"
  },
  {
    name: "Wintercoat down",
    price: "€ 27.95"
  },
  {
    name: "Cloak",
    price: "€ 27.95"
  },
  {
    name: "Sheers per m2",
    price: "€ 4.95"
  },
  {
    name: "Curtains per m2",
    price: "€ 5.95"
  },
  {
    name: "Curtains lined per m2",
    price: "€ 7.95"
  },
  {
    name: "Duvet 1p.",
    price: "€ 24.95"
  },
  {
    name: "Duvet 2p.",
    price: "€ 34.95"
  },
  {
    name: "Duvet 4 season",
    price: "€ 44.95"
  }
];
export const pricesNl = [
  {
    name: "Overhemd",
    price: "€ 3.50"
  },
  {
    name: "Blouse",
    price: "€ 6.95"
  },
  {
    name: "Blouse Zijde",
    price: "€ 9.95"
  },
  {
    name: "Broek / Pantalon",
    price: "€ 8.95"
  },
  {
    name: "Jasje / Colbert",
    price: "€ 10.95"
  },
  {
    name: "Gilet",
    price: "€ 6.95"
  },
  {
    name: "Stropdas",
    price: "€ 7.95"
  },
  {
    name: "Trui / Vest",
    price: "€ 6.95"
  },
  {
    name: "T-shirt / Polo",
    price: "€ 4.45"
  },
  {
    name: "Jeans",
    price: "€ 5.95"
  },
  {
    name: "Rok",
    price: "€ 8.95"
  },
  {
    name: "Jurk",
    price: "€ 12.95"
  },
  {
    name: "Zomerjas",
    price: "€ 18.95"
  },
  {
    name: "Winterjas",
    price: "€ 22.95"
  },
  {
    name: "Winterjas Dons",
    price: "€ 27.95"
  },
  {
    name: "Mantel",
    price: "€ 27.95"
  },
  {
    name: "Vitrage per m2",
    price: "€ 4.95"
  },
  {
    name: "Gordijnen per m2",
    price: "€ 5.95"
  },
  {
    name: "Gordijnen gevoerd per m2",
    price: "€ 7.95"
  },
  {
    name: "Dekbed 1p.",
    price: "€ 24.95"
  },
  {
    name: "Dekbed 2p..",
    price: "€ 34.95"
  },
  {
    name: "Dekbed 4 seizoen",
    price: "€ 44.95"
  }
];
