
import React from 'react'
import Modal from 'react-modal';
import { customStyles } from '../pages/Home'
import '../css/modals/Modal.css'

const translations = {
  thank01: {
    en: "Thank you!",
    nl: "Dankjewel!"
  },
  thank02: {
    en: "When dobbi comes to your city, you’ll be the first to know.",
    nl: "Je ontvangt een mail zodra dobbi bij jou in de buurt beschikbaar is."
  }
}

const { thank01, thank02 } = translations;


export const ModalThank = (props) => {
  const el = document.querySelector("#root");
  const lang = props.lang;

  return (
    props.isOpen && <div className="modal-wrapper">
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        appElement={el}
        style={customStyles}
      >
        <button onClick={props.closeModal} className="close-button">&#10005;</button>
        <div className="modal-wrapper">
          <div className="modal-inner modal-full">
            <h2>{thank01[lang]}</h2>
            <p>{thank02[lang]}</p>
          </div>
        </div>
      </Modal>
    </div>
  )
}
