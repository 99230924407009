import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import uuid from "uuid";
import find from "lodash.find";
import {
  setSelectedDeliveryData,
  setAvaiblePickupData,
  setAvaibleDeliveryData,
  setSelectedDeliveryTime
} from "../../../redux/actions";

class DeliveryOrderHours extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleItemClick = this.handleItemClick.bind();
  }

  filerCurrentDate = () => {
    const {
      avaliablePickupDate,
      avaliableDeliveryDate,
      pickup,
      selectedPickupDate,
      selectedDeliveryDate,
      selectedDeliveryTime
    } = this.props;

    const avaliableHours = pickup ? avaliablePickupDate : avaliableDeliveryDate;
    const selectedData = pickup
      ? selectedPickupDate.date
      : selectedDeliveryDate.date;

    let currentHours = find(avaliableHours, o => o.date === selectedData);
    currentHours = currentHours ? currentHours.hours : [];

    currentHours.forEach(function(part, index) {
      this[index]['selected'] = selectedDeliveryTime === part['from'];
    }, currentHours);

    return currentHours;
  };

  handleItemClick = e => {
    const { setSelectedDeliveryTime } = this.props;

    const target = e.target.closest(".hours-item");
    const targetFrom = target.dataset.from;
    const pickupItems = document.querySelectorAll(".delivery-items");
    setSelectedDeliveryTime(targetFrom);

    Array.prototype.forEach.call(pickupItems, node => {
      node.classList.remove("selected");
    });
    target.classList.add("selected");
  };

  render() {
    const { pickup, delivery, selected } = this.props;
    const avaliableHours = this.filerCurrentDate();

    return (
      <ul
        className="hours-block"
        id="step-4-delivery-list-time"
      >
        {avaliableHours.map(item => {
          const className = classNames({
            "hours-item": true,
            "pickup-items": pickup,
            "delivery-items": delivery,
            selected: selected || item.selected,
            avaliable: item.available
          });

          return (
            <li
              key={uuid()}
              className={className}
              onClick={item.available ? this.handleItemClick : null}
              data-from={item.from}
            >
              <span className="time-from">{item.from}</span>
              <span>{item.to}</span>
            </li>
          );
        })}
      </ul>
    );
  }
}
const mapStateToProps = ({
  avaliableDeliveryDate,
  avaliablePickupDate,
  apiToken,
  selectedPickupDate,
  selectedDeliveryDate,
  selectedDeliveryTime
}) => ({
  selectedPickupDate,
  selectedDeliveryDate,
  token: apiToken,
  avaliableDeliveryDate,
  avaliablePickupDate,
  selectedDeliveryTime
});

const mapDispatchToProps = dispatch => {
  return {
    setSelectedDeliveryTime: date => dispatch(setSelectedDeliveryTime(date)),
    setSelectedDeliveryData: date => dispatch(setSelectedDeliveryData(date)),
    setAvaiblePickupData: data => dispatch(setAvaiblePickupData(data)),
    setAvaibleDeliveryData: data => dispatch(setAvaibleDeliveryData(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryOrderHours);
